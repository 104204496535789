<template>
  <div id="workflowRequestList">
    <div v-if="activeView === 'GRID'" :class="dashboardView ? 'cards' : 'card'">
      <div class="row items-center q-pa-xs">
        <div
          v-if="initiate && !myInbox"
          class="row items-center q-mr-xs"
          style="cursor: pointer"
          @click="openProcess(true)"
        >
          <q-chip
            outline
            color="secondary"
            class="cursor-pointer secondary-hover"
          >
            <q-avatar
              icon="mdi-plus-thick"
              class="q-mr-sm"
              color="secondary"
              text-color="white"
            />
            Initiate
          </q-chip>
        </div>
        <ImportPopup
          v-if="importExcel && !myInbox"
          :chip-button="true"
          :data-imported="dataImported"
          :form-id="String(masterFormId)"
          class="row items-center q-mr-xs"
          @upload="$emit('upload')"
          @close="$emit('close')"
        />
        <div id="global-search" class="col ellipsis">
          <div class="row col">
            <SearchItems
              :columns="columns"
              :filter-search-column.sync="filterSearchColumn"
              :selected-filter-column.sync="selectedFilterColumn"
              class="col-auto actions addFilter"
              @update="updateFilterByColumns"
            />

            <span v-if="selectedFilterColumn" class="col-auto q-ml-xs q-mt-xs"
              >{{ selectedFilterColumn }} :</span
            >

            <input
              ref="searchQuery"
              v-model="search"
              type="text"
              class="col q-px-xs q-mt-xs"
              placeholder="Search Request..."
              @keyup="searchRow(search)"
              @keypress.enter="updateFilterByColumns"
            />

            <BaseActionButton
              v-tooltip.bottom="'Search'"
              icon="eva-search"
              no-border
              is-flat
              class="col-auto"
              @click="updateFilterByColumns"
            />

            <BaseActionButton
              v-if="selectedFilterColumn"
              v-tooltip.bottom="'Clear Filter'"
              icon="eva-close"
              no-border
              is-flat
              class="col-auto"
              @click="clearFilterByColumns(true)"
            />
          </div>
        </div>
        <div class="col-auto q-ml-xs">
          <BaseActionButton
            v-if="jiraConnect && !selectedFilterColumn && !search"
            v-tooltip.top="'JIRA - Create Issue'"
            icon="mdi-jira"
            color="gray"
            no-border
            is-flat
            size="18px"
            @click="checkJiraRequest"
          />
        </div>
        <div v-if="!selectedFilterColumn && !search" class="col-auto q-ml-xs">
          <BaseActionButton
            v-tooltip.top="'Refresh'"
            icon="eva-refresh"
            color="gray"
            no-border
            is-flat
            size="20px"
            @click="getRequest"
          />
        </div>
        <div v-if="!selectedFilterColumn && !search" class="col-auto">
          <SortItems
            :columns="workflow.id ? columns : []"
            :order.sync="sortBy.order"
            :criteria.sync="sortBy.criteria"
          />
        </div>
        <div v-if="!selectedFilterColumn && !search" class="col-auto">
          <FilterItems
            item="proces"
            :columns="workflow.id ? columns : []"
            :filter-by.sync="filterBy"
            module="form"
            :module-id="workflow.wFormId"
            @update="updateFilterBy"
          />
        </div>
        <div v-if="!selectedFilterColumn && !search" class="col-auto">
          <BaseActionButton
            v-if="!dashboardView"
            v-tooltip.top="'List View'"
            icon="mdi-format-list-checkbox"
            color="gray"
            no-border
            is-flat
            size="20px"
            @click="$emit('update:activeView', 'LIST')"
          />
        </div>

        <div v-if="!selectedFilterColumn && !search" class="col-auto q-ml-xs">
          <div v-if="!dashboardView">
            <BaseActionButton
              icon="mdi-pin"
              :color="dashboard ? 'secondary' : 'gray'"
              no-border
              is-flat
              size="20px"
              rotate="rotate-45"
              @click="dashboard ? $emit('isDelete') : $emit('issavesheet')"
            />
          </div>
        </div>
      </div>
      <template v-if="dashboardView">
        <ProgressBar
          v-if="loadingBarInbox"
          :container-height="'50vh'"
          :container-color="'white'"
          :progress-bars="progressBars"
        />
      </template>

      <template v-else>
        <BaseLoadingBarSheet v-if="loadingBarInbox" class="card1" />
      </template>

      <template v-if="selectedNode">
        <!-- <BaseLoadingBarSheet /> -->

        <BaseScrollbar
          :height="dashboardView ? 'calc(75vh - 285px)' : 'calc(100vh - 285px)'"
          class="q-pa-xs"
          @scroll="loadMore"
        >
          <template v-for="group in groupKey">
            <div v-if="group.name" :key="group.name" class="row">
              <template v-if="groupRequestListName(group.name)">
                <div class="group-by col-auto q-mb-sm">
                  <CheckboxField
                    v-if="group.checkboxRequired"
                    :value="group.checked"
                    class="col-auto check-group-by"
                    @input="updateSelectionGroup(group)"
                  />
                  <div class="col-auto">
                    <div class="groupLabel">
                      {{ group.name + " (" + group.totalCount + ")" }}
                    </div>
                  </div>
                  <BaseIcon
                    :name="group.expand ? 'mdi-chevron-down' : 'mdi-chevron-up'"
                    class="cursor-pointer q-ml-sm"
                    @click="group.expand = !group.expand"
                  />
                </div>
                <div class="q-ml-sm">
                  <BaseActionButton
                    v-if="groupSelected(group.name)"
                    v-tooltip.top="'Actions'"
                    icon="eva-more-horizontal-outline"
                    size="16px"
                    color="primary-5"
                  >
                    <q-menu
                      transition-show="scale"
                      transition-hide="scale"
                      anchor="bottom end"
                      self="top right"
                    >
                      <ListItem
                        v-for="action in group.actions"
                        :key="action.id"
                        :label="action.name"
                        :icon="action.icon"
                        :color="action.color"
                        @click="groupRequestAction(action, group)"
                      />
                    </q-menu>
                  </BaseActionButton>
                </div>
              </template>
            </div>
            <template v-for="request in groupRequestList(group.name)">
              <div :key="group.name + '-' + request.processId">
                <div
                  @click="
                    request.expandSubWorkflow = !request.expandSubWorkflow
                  "
                >
                  <div
                    v-if="request.visible && group.expand"
                    class="list q-mb-xs q-pa-sm"
                    :class="
                      request.processId === hasSelectedProcess &&
                      request.id === hasSelectedWorkflow
                        ? 'active-list'
                        : ''
                    "
                    :style="{ background: actionColor(request.lastAction) }"
                    @click="showRequestPreview(request.processId, request.id)"
                  >
                    <div class="row">
                      <CheckboxField
                        v-if="
                          (selectedNode === 'inbox' ||
                            selectedNode === 'common' ||
                            selectedNode === 'payment') &&
                          request.checkboxRequired &&
                          request.key
                        "
                        :value="request.checked"
                        class="col-auto"
                        @input="updateSelection(request)"
                      />
                      <q-icon
                        :name="
                          request.starred ? 'mdi-star' : 'mdi-star-outline'
                        "
                        :color="darkTheme ? 'secondary' : 'primary'"
                        class="q-mr-sm col-auto"
                        size="18px"
                        @click.stop="updateStarred(request)"
                      />

                      <span class="col">{{ request.requestNo }}</span>
                      <template v-if="request.subworkflowTransaction">
                        <BaseIcon
                          v-if="request.subworkflowTransaction.length"
                          :name="
                            request.expandSubWorkflow
                              ? 'mdi-menu-down rotate-180'
                              : 'mdi-menu-down'
                          "
                          color="secondary"
                        />
                      </template>
                    </div>
                    <div class="row">
                      <BaseIcon name="mdi-circle-small" color="secondary" />
                      <span class="col ellipsis">{{ request.stage }}</span>
                      <BaseIcon name="mdi-circle-small" color="secondary" />
                      <span class="col-auto">{{
                        lastActionDuration(request)
                      }}</span>
                    </div>
                    <div
                      v-if="
                        (selectedNode === 'inbox' ||
                          selectedNode === 'common' ||
                          selectedNode === 'payment') &&
                        request.mlPrediction
                      "
                      class="row q-pt-sm"
                    >
                      <!-- <div v-if="!request.mlCondition" class="col-auto q-mr-sm">
                    <q-chip outline color="pink" class="chips pink-hover">
                      <q-avatar
                        icon="mdi-robot"
                        class="q-mr-sm"
                        color="pink"
                        text-color="white"
                      />
                      {{ request.mlPrediction }}
                    </q-chip>
                  </div> -->
                      <div v-if="request.mlCondition" class="col-auto">
                        <template
                          v-for="cond in request.mlCondition.split(',')"
                        >
                          <q-chip
                            :key="cond"
                            outline
                            color="gray"
                            class="chips gray-hover"
                          >
                            <q-avatar
                              icon="mdi-information-variant"
                              class="q-mr-sm"
                              color="gray"
                              text-color="white"
                            />
                            {{ cond }}
                          </q-chip>
                        </template>
                      </div>
                    </div>
                    <div
                      v-if="!request.mlPrediction && request.mlCondition"
                      class="row q-pt-sm"
                    >
                      <template v-if="request.mlCondition.indexOf('[') > -1">
                        <div
                          v-for="row in JSON.parse(request.mlCondition)"
                          :key="request.processId + '' + row.fieldName"
                          @click.stop="$emit('validation', request.processId)"
                        >
                          <q-chip
                            outline
                            :color="
                              row.status === 'Matched'
                                ? 'green'
                                : row.status === 'Not Matched'
                                ? 'red'
                                : 'orange'
                            "
                            class="chips gray-hover"
                            :class="
                              row.status === 'Matched'
                                ? 'green-hover'
                                : row.status === 'Not Matched'
                                ? 'red-hover'
                                : 'orange-hover'
                            "
                          >
                            <q-avatar
                              icon="mdi-robot"
                              class="q-mr-sm"
                              :color="
                                row.status === 'Matched'
                                  ? 'green'
                                  : row.status === 'Not Matched'
                                  ? 'red'
                                  : 'orange'
                              "
                              text-color="white"
                            />
                            {{ row.fieldValue }}
                          </q-chip>
                        </div>
                      </template>
                      <template v-else>
                        <template
                          v-for="cond in request.mlCondition.split(',')"
                        >
                          <q-chip
                            :key="cond"
                            outline
                            color="pink"
                            class="chips pink-hover"
                          >
                            <q-avatar
                              icon="mdi-information-variant"
                              class="q-mr-sm"
                              color="pink"
                              text-color="white"
                            />
                            {{ cond }}
                          </q-chip>
                        </template>
                      </template>
                    </div>
                    <div
                      v-if="request.jiraIssueInfo && request.jiraIssueInfo.id"
                      class="row q-mt-sm"
                    >
                      <a
                        v-tooltip.top="'JIRA Issue Link'"
                        :href="getJIRALink(request.jiraIssueInfo)"
                        target="_blank"
                        class="col-auto"
                        style="text-decoration: none"
                      >
                        <BaseActionButton
                          icon="mdi-jira"
                          color="primary"
                          class="q-mr-xs small"
                          is-flat
                          no-border
                          size="16px"
                        />
                      </a>
                      <span class="col-auto"
                        >REF# ID: {{ request.jiraIssueInfo.id }}</span
                      >
                      <BaseIcon name="mdi-circle-small" color="secondary" />
                      <span class="col-auto"
                        >KEY: {{ request.jiraIssueInfo.key }}</span
                      >
                      <template v-if="request.jiraIssueInfo.assignee">
                        <BaseIcon name="mdi-circle-small" color="secondary" />
                        <span class="col-auto text-primary text-bold"
                          >ASSIGNEE: {{ request.jiraIssueInfo.assignee }}</span
                        >
                      </template>
                      <template v-if="request.jiraIssueInfo.issueStatus">
                        <BaseIcon name="mdi-circle-small" color="secondary" />
                        <span class="col-auto text-primary text-bold"
                          >STATUS: {{ request.jiraIssueInfo.issueStatus }}</span
                        >
                      </template>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    request.subworkflowTransaction && request.expandSubWorkflow
                  "
                >
                  <template v-for="item in request.subworkflowTransaction">
                    <div
                      :key="item.requestNo"
                      class="sub-workflow-details"
                      @click="showSubRequestPreview(item)"
                    >
                      <BaseIcon
                        v-tooltip.top="
                          item.flowStatus == 1 ? 'Completed' : 'In-progress'
                        "
                        :name="
                          item.flowStatus == 1
                            ? 'mdi-check-circle-outline'
                            : 'mdi-progress-clock'
                        "
                        class="q-mr-sm col-auto"
                        size="18px"
                        :color="darkTheme ? 'secondary' : 'primary'"
                      />
                      <span class="col">{{ item.requestNo }}</span>

                      <div class="row">
                        <BaseIcon name="mdi-circle-small" color="secondary" />
                        <span class="col ellipsis">{{ item.stageName }}</span>
                        <BaseIcon name="mdi-circle-small" color="secondary" />
                        <span class="col-auto">{{
                          sublastActionDuration(item)
                        }}</span>
                      </div>
                      <div
                        v-if="
                          (selectedNode === 'inbox' ||
                            selectedNode === 'common' ||
                            selectedNode === 'payment') &&
                          request.mlPrediction
                        "
                        class="row q-pt-sm"
                      >
                        <div v-if="request.mlCondition" class="col-auto">
                          <template
                            v-for="cond in request.mlCondition.split(',')"
                          >
                            <q-chip
                              :key="cond"
                              outline
                              color="gray"
                              class="chips gray-hover"
                            >
                              <q-avatar
                                icon="mdi-information-variant"
                                class="q-mr-sm"
                                color="gray"
                                text-color="white"
                              />
                              {{ cond }}
                            </q-chip>
                          </template>
                        </div>
                      </div>
                      <div
                        v-if="!request.mlPrediction && request.mlCondition"
                        class="row q-pt-sm"
                      >
                        <template v-if="request.mlCondition.indexOf('[') > -1">
                          <div
                            v-for="row in JSON.parse(request.mlCondition)"
                            :key="request.processId + '' + row.fieldName"
                            @click.stop="$emit('validation', request.processId)"
                          >
                            <q-chip
                              outline
                              :color="
                                row.status === 'Matched'
                                  ? 'green'
                                  : row.status === 'Not Matched'
                                  ? 'red'
                                  : 'orange'
                              "
                              class="chips gray-hover"
                              :class="
                                row.status === 'Matched'
                                  ? 'green-hover'
                                  : row.status === 'Not Matched'
                                  ? 'red-hover'
                                  : 'orange-hover'
                              "
                            >
                              <q-avatar
                                icon="mdi-robot"
                                class="q-mr-sm"
                                :color="
                                  row.status === 'Matched'
                                    ? 'green'
                                    : row.status === 'Not Matched'
                                    ? 'red'
                                    : 'orange'
                                "
                                text-color="white"
                              />
                              {{ row.fieldValue }}
                            </q-chip>
                          </div>
                        </template>
                        <template v-else>
                          <template
                            v-for="cond in request.mlCondition.split(',')"
                          >
                            <q-chip
                              :key="cond"
                              outline
                              color="pink"
                              class="chips pink-hover"
                            >
                              <q-avatar
                                icon="mdi-information-variant"
                                class="q-mr-sm"
                                color="pink"
                                text-color="white"
                              />
                              {{ cond }}
                            </q-chip>
                          </template>
                        </template>
                      </div>
                      <div
                        v-if="request.jiraIssueInfo && request.jiraIssueInfo.id"
                        class="row q-mt-sm"
                      >
                        <a
                          v-tooltip.top="'JIRA Issue Link'"
                          :href="getJIRALink(request.jiraIssueInfo)"
                          target="_blank"
                          class="col-auto"
                          style="text-decoration: none"
                        >
                          <BaseActionButton
                            icon="mdi-jira"
                            color="primary"
                            class="q-mr-xs small"
                            is-flat
                            no-border
                            size="16px"
                          />
                        </a>
                        <span class="col-auto"
                          >REF# ID: {{ request.jiraIssueInfo.id }}</span
                        >
                        <BaseIcon name="mdi-circle-small" color="secondary" />
                        <span class="col-auto"
                          >KEY: {{ request.jiraIssueInfo.key }}</span
                        >
                        <template v-if="request.jiraIssueInfo.assignee">
                          <BaseIcon name="mdi-circle-small" color="secondary" />
                          <span class="col-auto text-primary text-bold"
                            >ASSIGNEE:
                            {{ request.jiraIssueInfo.assignee }}</span
                          >
                        </template>
                        <template v-if="request.jiraIssueInfo.issueStatus">
                          <BaseIcon name="mdi-circle-small" color="secondary" />
                          <span class="col-auto text-primary text-bold"
                            >STATUS:
                            {{ request.jiraIssueInfo.issueStatus }}</span
                          >
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </template>
        </BaseScrollbar>
        <Pagination
          v-if="totalItems && false"
          :total-items="totalItems"
          :current-page.sync="currentPage"
          :items-per-page.sync="itemsPerPage"
        />
        <div
          v-if="totalItems"
          class="row items-center q-pa-xs custom-pagination"
        >
          <div class="q-mr-sm q-ml-sm">
            Showing
            <span class="font-semibold">{{
              workflowRequestList.length >= totalItems
                ? totalItems
                : workflowRequestList.length
            }}</span>
            out of <span class="font-semibold">{{ totalItems }}</span> requests
          </div>

          <!-- <BaseActionButton
            v-if="totalItems > itemsPerPage"
            v-tooltip:secondary.top="
              'Click here to load next ' + itemsPerPage + ' records'
            "
            is-flat
            icon="eva-download-outline"
            color="secondary"
            @click="$emit('update:currentPage', currentPage + 1)"
          /> -->
        </div>
      </template>
    </div>
    <div v-if="activeView === 'LIST'">
      <Portal to="action-bar">
        <ItemActions
          v-if="!isLoading"
          item="process"
          :columns.sync="columns"
          :toggle-id="workflow.id"
          group-by=""
          order=""
          :filter-by.sync="filterBy"
          :active-view.sync="activeView"
          :criteria.sync="sortBy.criteria"
          :hide-actions="hideActions"
          :workflow-initiate="initiate && !myInbox"
          :initiate-access="true"
          :workflow-import="importExcel && !myInbox"
          :data-imported="dataImported"
          :form-id="String(masterFormId)"
          :jira-connect="jiraConnect"
          module="form"
          :module-id="workflow.wFormId"
          :custom-process-form="isCustomizedWorkflow"
          :process-forward="groupItemSelected"
          @refresh="getRequest"
          @search="searchRow"
          @update="updateFilterBy"
          @initiate="openProcess(true)"
          @upload="$emit('upload')"
          @close="$emit('close')"
          @jira="checkJiraRequest"
          @export="exportRequestData"
          @custom="showCustomProcessForm = true"
          @forward="groupRequestAction('Assign', [])"
        />
      </Portal>
      <div v-if="!isLoading" class="content card">
        <template v-if="dashboardView">
          <ProgressBar
            v-if="loadingBarInbox"
            :container-height="'50vh'"
            :container-color="'white'"
            :progress-bars="progressBars"
          />
        </template>

        <template v-else>
          <BaseLoadingBarSheet v-if="loadingBarInbox" class="card1" />
        </template>

        <BaseScrollbar height="calc(100vh - 250px)" @scroll="loadMore">
          <ProcessList
            v-if="activeView === 'LIST'"
            :mode="selectedNode"
            :items="items"
            :columns="columns"
            :group-key="groupKey"
            :group-item-selected.sync="groupItemSelected"
            @show="showRequest"
            @starred="updateStarred"
            @selected="updateSelection"
            @group="updateSelectionGroup"
            @action="groupRequestAction"
            @history="showHistory"
            @SubHistory="showSubHistory"
            @attachments="openAttachments"
            @subAttachments="openSubAttachments"
            @comments="openComments"
            @subComments="openSubComments"
            @validation="$emit('validation')"
            @openSubWorkflow="updateSelectedSubWorkflow"
          />
        </BaseScrollbar>
        <Pagination
          v-if="totalItems && false"
          :total-items="totalItems"
          :current-page.sync="currentPage"
          :items-per-page.sync="itemsPerPage"
        />
        <div
          v-if="totalItems"
          class="row items-center q-pa-xs custom-pagination"
        >
          <div class="q-mr-sm q-ml-sm">
            Showing
            <span class="font-semibold">{{
              workflowRequestList.length >= totalItems
                ? totalItems
                : workflowRequestList.length
            }}</span>
            out of <span class="font-semibold">{{ totalItems }}</span> requests
          </div>

          <!-- <BaseActionButton
            v-if="totalItems > itemsPerPage"
            v-tooltip:secondary.top="
              'Click here to load next ' + itemsPerPage + ' records'
            "
            is-flat
            icon="eva-download-outline"
            color="secondary"
            @click="$emit('update:currentPage', currentPage + 1)"
          /> -->
        </div>
      </div>
    </div>
    <Sheet
      v-model="processSheet"
      :width="formWidth"
      :has-footer="!isCustomizedWorkflow || !initiateProcess"
      no-padding
      @input="closeProcessSheet(true)"
    >
      <!-- title -->

      <template #title> {{ headerTitle }} </template>

      <!-- ... -->

      <!-- actions -->

      <template #actions>
        <template v-if="isCustomizedWorkflow">
          <div v-if="isRequestLocked" class="cursor-pointer q-mr-sm">
            <q-chip outline color="orange" class="cursor-pointer orange-hover">
              View Only
            </q-chip>
          </div>
          <div
            v-if="selectedProcess.ticketLockedBy === $store.state.session.id"
            class="cursor-pointer q-mr-sm"
            @click="requestReleaseModal = true"
          >
            <q-chip
              outline
              color="secondary"
              class="cursor-pointer secondary-hover"
            >
              Release
            </q-chip>
          </div>
        </template>
        <template
          v-if="!isCustomizedWorkflow && workflow.initiatedBy !== 'DOCUMENT'"
        >
          <template v-if="mandatory.filledValue || mandatory.nonFilledValue">
            <q-circular-progress
              show-value
              font-size="12px"
              :value="mandatoryFilling"
              size="lg"
              :thickness="0.22"
              color="primary"
              track-color="grey-3"
              class="q-mr-sm"
            >
              {{ mandatoryFilling }}%
            </q-circular-progress>
          </template>
          <div v-if="mandatory.filledValue" class="chip-success q-mr-sm">
            <div class="">{{ mandatory.filledValue }} ready</div>
          </div>
          <div
            v-if="mandatory.nonFilledValue"
            class="chip-warning"
            @click="checkFormValidation"
          >
            <div class="">{{ mandatory.nonFilledValue }} need to work</div>
          </div>
        </template>
        <template v-if="mandatoryDocument.length">
          <div
            class="chip-warning cursor-pointer"
            @click="attachmentSheet = true"
          >
            <div class="">{{ mandatoryDocument.length }} document required</div>
          </div>
        </template>
        <template v-if="isCustomizedWorkflow">
          <BaseActionButton
            v-tooltip.top="'refresh'"
            icon="mdi-refresh"
            no-border
            is-flat
            size="20px"
            class="q-mr-sm"
            @click="refreshFormData"
          />
        </template>
        <BaseButton
          v-if="hasWflowInitiator"
          :label="subWorkflowName"
          class="q-mr-sm"
          color="secondary"
          @click="initiateSubWorkflow"
        />

        <BaseActionButton
          v-if="itemModel.itemId"
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="processDocumentAction(action.id)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
        <template
          v-if="
            !isCustomizedWorkflow &&
            Object.keys(selectedProcess).length &&
            actions.length === 0
          "
        >
          <BaseActionButton
            v-if="!formView"
            v-tooltip.top="'Form View'"
            is-flat
            :icon="'mdi-form-select'"
            class="q q-mr-sm"
            @click="formView = true"
          />

          <BaseActionButton
            v-else
            v-tooltip.top="'Details View'"
            is-flat
            :icon="'mdi-table-column'"
            class="q-mr-sm"
            @click="formView = false"
          />
        </template>
        <BaseActionButton
          v-if="!isCustomizedWorkflow"
          is-flat
          :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
          class="q-mr-sm"
          @click="toggleWidth"
        />
      </template>

      <!-- ... -->

      <!-- content -->

      <div id="content-wrapper" class="row">
        <template v-if="isCustomizedWorkflow && initiateProcess">
          <CustomForm
            :workflow-id="workflow.id"
            :workflow="workflow"
            :form-id="formId"
            :panels="panels"
            :secondary-panels="secondaryPanels"
            :save-action.sync="saveAction"
            :workflow-form-model.sync="workflowFormModel"
            :actions="actions"
            :request-prefix.sync="requestPrefix"
            :ml-prediction.sync="mlPrediction"
            :form-fields="formFields"
            :generate-p-d-f="generatePDF"
            @refresh="refreshCustomForm"
          />
        </template>

        <template v-else>
          <template v-if="isCustomizedWorkflow && !initiateProcess">
            <div v-if="formId" class="col">
              <CustomProcessForm
                :form-id="formId"
                :panels="panels"
                :secondary-panels="secondaryPanels"
                :form-model="formModel"
                :save-action.sync="saveAction"
                :workflow-form-model.sync="workflowFormModel"
                :form-fields="formFields"
                :data-validation-values.sync="dataValidationValues"
                :selected-process="selectedProcess"
                :is-readonly="isRequestLocked ? true : formEditAccess"
                :check-list.sync="checkList"
                :form-check-list.sync="formCheckList"
                :save-only.sync="saveOnly"
                :inbox="
                  (selectedNode === 'inbox' ||
                    selectedNode === 'common' ||
                    selectedNode === 'payment') &&
                  !isRequestLocked
                "
                :auto-save-model.sync="autoSaveModel"
                @updateDataValidationValues="updateDataValidationValues"
              />
            </div>
          </template>
          <template v-else>
            <div v-if="formId" class="col">
              <!-- form -->
              <BaseScrollbar height="calc(100vh - 115px)">
                <div v-if="initiateProcess || formView" id="form-view">
                  <RenderForm
                    v-if="formId"
                    :form-id="formId"
                    :panels="panels"
                    :secondary-panels="secondaryPanels"
                    :form-settings="formSettings"
                    :form-model="formModel"
                    :selected-process="selectedProcess"
                    :save-action.sync="saveAction"
                    :workflow-form-model.sync="workflowFormModel"
                    :workflow-model.sync="workflowModel"
                    :is-readonly="formEditAccess"
                    :form-edit-controls="formEditControls"
                    :readonly-controls="readonlyControls"
                    :form-visibility-access="formVisibilityAccess"
                    :form-secure-controls="formSecureControls"
                    :enable-controls="_enableControls"
                    :check-list.sync="checkList"
                    :form-fields="formFields"
                    :data-validation-values="dataValidationValues"
                    :form-check-list.sync="formCheckList"
                    :form-upload-files="formUploadFiles"
                    :has-check-form-validate.sync="hasCheckFormValidate"
                    :ocr-result="ocrResult"
                    @uploadFiles="getOCRResult"
                    @mandatoryControlsList="mandatoryControlsList"
                    @deletedFiles="deletedFiles"
                    @showQr="generateQrCode"
                    @uploadFilesInTable="getOCRResultInTable"
                    @deletedFilesInTable="deletedFilesInTable"
                    @viewFile="viewFile"
                  />

                  <div
                    v-if="signList.length"
                    id="approvalSignature"
                    class="q-pa-md"
                  >
                    <div class="title q-mb-sm">Approval Signature</div>

                    <BaseSeparator class="q-my-sm" />

                    <div class="row">
                      <template v-for="sign in signList">
                        <div :key="sign.id" class="col-3 q-mb-sm">
                          <img class="imgCenter col" :src="signLink(sign.id)" />
                          <div class="text row">
                            <span class="col-12 text-bold">{{
                              sign.stage
                            }}</span>
                            <span class="col-12">{{
                              sign.createdByEmail
                            }}</span>
                            <span class="col-12">{{
                              $day.format(sign.createdAt)
                            }}</span>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>

                  <SignatureField
                    v-if="userSignature || initUserSignature"
                    v-model="signImg"
                    label="Signature"
                    class="q-pa-md"
                  />
                </div>
                <div v-else class="q-pa-md">
                  <!-- <div class="col-12 q-mb-sm q-pt-md stepper">
                    <Stepper
                      :stage-level="stageLevel"
                      :current-step="currentStep"
                    />
                  </div> -->

                  <div id="requestDetails" class="col-12">
                    <div class="row justify-center">
                      <div class="col">
                        <div class="row items-center">
                          <table class="submittedData q-mb-sm">
                            <tbody>
                              <tr v-if="selectedProcess.createdByName">
                                <td colspan="4">
                                  Requested By:
                                  {{ selectedProcess.createdByName }}
                                </td>
                              </tr>
                              <tr>
                                <th v-if="selectedProcess.raisedBy">
                                  Requested User Email
                                </th>
                                <th>Requested Date</th>
                                <th>Last Acted By</th>
                                <th>Last Acted On</th>
                              </tr>
                              <tr>
                                <td v-if="selectedProcess.raisedBy">
                                  {{ selectedProcess.raisedBy }}
                                </td>
                                <td>
                                  {{ $day.format(selectedProcess.raisedAt) }}
                                </td>
                                <td>
                                  {{
                                    selectedProcess.transaction_createdByEmail
                                  }}
                                </td>
                                <td>
                                  {{
                                    $day.format(
                                      selectedProcess.transaction_createdAt
                                    )
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <BaseScrollbar height="calc(100vh - 275px)">
                            <template v-if="selectedProcess.formData.fields">
                              <table class="mainData col-12">
                                <template
                                  v-for="(value, key) in selectedProcess
                                    .formData.fields"
                                >
                                  <template v-if="value && value != '[]'">
                                    <template v-if="isVisible(key)">
                                      <template
                                        v-if="validateType(key) !== 'TABLE'"
                                      >
                                        <tr :key="`${key}-${value}`">
                                          <td class="tblHeader ellipsis small">
                                            {{ getLabel(key) }}
                                          </td>
                                          <td class="ellipsis">
                                            <template
                                              v-if="
                                                validateType(key) ===
                                                'FILE_UPLOAD'
                                              "
                                            >
                                              <div
                                                v-if="JSON.parse(value).length"
                                              >
                                                <div
                                                  v-for="file in JSON.parse(
                                                    value
                                                  )"
                                                  :key="file.fileName"
                                                  class="q-mb-xs"
                                                >
                                                  <div class="row items-center">
                                                    <div
                                                      class="col row items-center"
                                                      @click="
                                                        viewFile(file, value)
                                                      "
                                                    >
                                                      <FileIcon
                                                        :mime-type="
                                                          fileType(
                                                            file.fileName
                                                          )
                                                        "
                                                        class="mini-avatar q-mr-sm"
                                                      />
                                                      <span
                                                        class="cursor-pointer text-underline text-secondary"
                                                        >{{
                                                          file.fileName
                                                        }}</span
                                                      >
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </template>
                                            <template
                                              v-else-if="
                                                validateType(key) === 'DATE'
                                              "
                                            >
                                              {{ $day.format(value, false) }}
                                            </template>
                                            <template
                                              v-else-if="validateValue(value)"
                                            >
                                              {{ removeTags(value) || "-" }}
                                            </template>
                                            <template v-else>
                                              <a :href="value" target="_blank">
                                                {{ value || "-" }}
                                              </a>
                                            </template>
                                          </td>
                                        </tr>
                                      </template>
                                      <template
                                        v-else-if="JSON.parse(value).length"
                                      >
                                        <tr
                                          v-if="getLabel(key)"
                                          :key="'trlabel-' + key"
                                        >
                                          <td
                                            colspan="2"
                                            class="tblHeader ellipsis small"
                                          >
                                            <FormFieldLabel
                                              :key="'label-' + key"
                                              :label="getLabel(key)"
                                              class="q-mt-sm"
                                            />
                                          </td>
                                        </tr>
                                        <tr :key="'trtable1-' + key">
                                          <td colspan="2">
                                            <BaseScrollbar
                                              :key="'scroll-' + key"
                                              style="max-height: 200px"
                                            >
                                              <table
                                                :key="'table-' + key"
                                                class="sub-table q-mt-sm q-mb-sm"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th
                                                      v-for="column in tableColumns(
                                                        value,
                                                        key
                                                      )"
                                                      :key="column.id"
                                                    >
                                                      {{ column.label }}
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr
                                                    v-for="(
                                                      row, index
                                                    ) in tableValue(value)"
                                                    :key="index"
                                                  >
                                                    <td
                                                      v-for="column in tableColumns(
                                                        value,
                                                        key
                                                      )"
                                                      :key="column.id"
                                                    >
                                                      {{ row[column.id] }}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </BaseScrollbar>
                                          </td>
                                        </tr>
                                      </template>
                                    </template>
                                  </template>
                                </template>
                              </table>
                            </template>
                          </BaseScrollbar>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BaseScrollbar>
            </div>

            <div v-if="itemModel.itemId" class="col">
              <div class="fileFrame">
                <div class="file-list">
                  <iframe
                    id="file-frame"
                    ref="myProcessFiles"
                    :src="itemLink()"
                    frameborder="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </template>
          <div class="col-auto q-mr-sm leftBorder">
            <template v-for="tab in tabs">
              <BaseActionButton
                v-if="checkTabAccess(tab.value)"
                :key="tab.id"
                v-tooltip.left="tab.label"
                is-flat
                no-border
                :icon="tab.icon"
                color="primary"
                class="tabButtons"
                @click="showLinks(tab.value)"
              >
                <span v-if="tab.count" class="badge">{{ tab.count }} </span>
              </BaseActionButton>
            </template>
          </div>
          <!-- ... -->
        </template>
      </div>

      <!-- ... -->

      <!-- footer -->
      <template v-if="!documentSignProcess && !isRequestLocked" #footer>
        <template v-if="!isCustomizedWorkflow || !initiateProcess">
          <div v-if="actions.length" class="footer row">
            <BaseButton
              v-if="
                selectedNode === 'inbox' ||
                selectedNode === 'common' ||
                selectedNode === 'payment' ||
                initiateProcess
              "
              label="Save"
              class="q-ml-md"
              color="secondary"
              @click="validateRequest('Save')"
            />
            <template v-for="action in actions">
              <BaseButton
                v-if="
                  action === 'Approve' ||
                  action === 'Complete' ||
                  action === 'Rightsize'
                "
                :key="action"
                :label="action"
                color="positive"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
              <BaseButton
                v-else-if="
                  action === 'Reject' ||
                  action === 'Terminate' ||
                  action === 'Force Close' ||
                  action === 'Decline'
                "
                :key="action"
                :label="action"
                color="red"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
              <BaseButton
                v-else-if="
                  action === 'Forward' ||
                  action.indexOf('Reply') > -1 ||
                  action === 'Rollback' ||
                  action === 'Assign'
                "
                :key="action"
                :label="action"
                color="orange"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
              <BaseButton
                v-else-if="action === 'To Requester'"
                :key="action"
                :label="action"
                color="pink"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
              <BaseButton
                v-else
                :key="action"
                :label="action"
                class="q-ml-md"
                @click="validateRequest(action)"
              />
            </template>
          </div>
        </template>

        <template
          v-if="selectedProcess.activityId === 'psyCrYXtKfcs-EJmrYIhv1'"
        >
          <BaseButton
            v-if="selectedNode === 'inbox'"
            label="Verify"
            :disabled="customVerify"
            class="q-ml-md"
            color="primary"
            @click="validateRequest('CustomVerify', false, false)"
          />
        </template>
      </template>
      <!-- ... -->
    </Sheet>
    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="
        selectedProcess.processId && attachmentList.length ? true : false
      "
      :upload="
        selectedNode === 'inbox' ||
        selectedNode === 'common' ||
        selectedNode === 'payment' ||
        initiateProcess
      "
      no-padding
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 15 seconds
        </div>
        <BaseActionButton
          v-if="selectedProcess.processId"
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getAttachments()"
        />
      </template>

      <template
        v-if="
          selectedNode === 'inbox' ||
          selectedNode === 'common' ||
          selectedNode === 'payment' ||
          initiateProcess
        "
        #upload
      >
        <div
          class="hint"
          @click="
            {
              rowIndex = null;
              $refs.attachments.click();
            }
          "
        >
          <BaseIcon
            name="eva-cloud-upload-outline"
            size="32px"
            color="blue"
            class="icon"
          />

          <div class="title text-blue">UPLOAD FILES</div>
          <div class="description">Click here to choose files and upload</div>
        </div>
      </template>

      <template
        v-if="selectedProcess.processId && attachmentList.length"
        #subActions
      >
        <CheckboxField
          v-model="selectAll"
          class="col-auto q-pt-sm q-pb-sm"
          @input="fileSelectAll"
        />
        Select All

        <BaseActionButton
          v-tooltip.top="'mail share'"
          :is-disabled="!selectedFileCount"
          icon="mdi-email-send-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          class="q-ml-sm"
          @click="documentShare"
        />

        <BaseActionButton
          v-tooltip.top="'merge documents (only PDF)'"
          :is-disabled="selectedFileCount > 1 && isSelectedPDF ? false : true"
          icon="mdi-file-document-multiple-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          @click="documentMerge"
        />
      </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar
          :height="
            selectedNode === 'inbox' ||
            selectedNode === 'common' ||
            selectedNode === 'payment' ||
            initiateProcess
              ? 'calc(100vh - 200px)'
              : ''
          "
        >
          <input
            ref="attachments"
            type="file"
            class="hidden"
            @change="uploadWflowAttachments"
          />
          <template v-for="row in checkList">
            <template
              v-if="row.name && (!row.hasOwnProperty('visible') || row.show)"
            >
              <div v-if="!row.attach" :key="row.uid" class="checkList">
                <div class="row">
                  <div class="col-auto">
                    <BaseIcon name="mdi-file-check-outline" color="primary" />
                  </div>
                  <div class="col q-ml-sm">
                    <div class="row">
                      <div class="col-12">
                        {{ row.name }}
                      </div>
                      <div v-if="row.required" class="col-12 q-mt-sm">
                        <q-chip outline color="red" class="chips small">
                          Required
                        </q-chip>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="col-12" @click="attachTemplate(row.uid)">
                      <q-chip
                        outline
                        color="primary"
                        class="chips primary-hover medium"
                      >
                        <q-avatar
                          icon="eva-upload-outline"
                          class="q-mr-sm"
                          color="primary"
                          text-color="white"
                        />
                        Upload
                      </q-chip>
                    </div>
                    <div
                      v-if="row.template"
                      class="col-12"
                      @click="download(row.id)"
                    >
                      <q-chip
                        outline
                        color="secondary"
                        class="chips secondary-hover medium"
                      >
                        <q-avatar
                          icon="eva-download-outline"
                          class="q-mr-sm"
                          color="secondary"
                          text-color="white"
                        />
                        Download
                      </q-chip>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else :key="row.uid">
                <template v-for="file in attachmentFilterCheckList(row.name)">
                  <div :key="file.id" class="attachmentList">
                    <div class="title row">
                      <div v-if="selectedProcess.processId" class="col-auto">
                        <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                        <template v-if="file.loading">
                          <BaseIcon
                            name="mdi-loading"
                            color="secondary"
                            class="q-mr-sm loading"
                          />
                        </template>
                        <template v-else>
                          <BaseIcon
                            :name="
                              file.checked
                                ? 'eva-checkmark-circle-2'
                                : 'eva-radio-button-off-outline'
                            "
                            color="secondary"
                            class="q-mr-sm"
                            @click="file.checked = !file.checked"
                          />
                        </template>
                      </div>
                      <div v-else>
                        <template v-if="file.loading">
                          <BaseIcon
                            name="mdi-loading"
                            color="secondary"
                            class="q-mr-sm loading"
                          />
                        </template>
                      </div>
                      <div v-if="file.formJsonId" class="col-auto">
                        <BaseIcon
                          v-tooltip:green.top="getLabel(file.formJsonId)"
                          name="mdi-form-select"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        <FileIcon
                          :mime-type="fileType(file.name)"
                          class="mini-avatar q-mr-sm"
                        />
                      </div>
                      <div class="col ellipsis" @click="openFile(file)">
                        {{ file.name }}
                      </div>
                      <div v-if="!file.loading" class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Download'"
                          icon="eva-download-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Download')"
                        />
                      </div>
                      <div v-if="!file.loading" class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Print'"
                          icon="eva-printer-outline"
                          color="primary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="fileMenuAction(file, 'Print')"
                        />
                      </div>
                    </div>

                    <div class="meta row">
                      <div class="col row">
                        <!-- created at -->

                        <div>{{ $day.format(file.createdAt) }}</div>

                        <!-- ... -->

                        <!-- created by -->

                        <template v-if="file.createdBy">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ file.createdByEmail }}</div>
                        </template>

                        <!-- ... -->

                        <!-- size -->

                        <template v-if="file.size">
                          <BaseIcon name="mdi-circle-small" />
                          <div>{{ fileSize(file.size) }}</div>
                        </template>

                        <!-- ... -->
                      </div>
                      <div class="col-auto">
                        <BaseActionButton
                          v-tooltip.top="'Metadata'"
                          icon="eva-file-text-outline"
                          color="secondary"
                          no-border
                          is-flat
                          size="20px"
                          @click.stop="getFileData(file)"
                        />
                      </div>
                      <template v-if="false">
                        <template
                          v-if="
                            selectedNode === 'inbox' ||
                            selectedNode === 'common' ||
                            selectedNode === 'payment' ||
                            initiateProcess
                          "
                        >
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'delete'"
                              icon="eva-trash-2-outline"
                              color="red"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="attachmentDelete(file)"
                            />
                          </div>
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'Re-Upload'"
                              icon="mdi-file-upload"
                              color="primary"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="
                                {
                                  reuploadFile = file;
                                  reupload(file.name);
                                }
                              "
                            />
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            ((selectedNode === 'inbox' ||
                              selectedNode === 'common' ||
                              selectedNode === 'payment') &&
                              file.createdBy === $store.state.session.id) ||
                            initiateProcess
                          "
                        >
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'delete'"
                              icon="eva-trash-2-outline"
                              color="red"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="attachmentDelete(file)"
                            />
                          </div>
                          <div class="col-auto">
                            <BaseActionButton
                              v-tooltip.top="'Re-Upload'"
                              icon="mdi-file-upload"
                              color="primary"
                              no-border
                              is-flat
                              size="20px"
                              @click.stop="
                                {
                                  reuploadFile = file;
                                  reupload(file.name);
                                }
                              "
                            />
                          </div>
                        </template>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </template>
          <template v-for="file in attachmentList">
            <template v-if="isCheckList(file.name)">
              <div :key="file.id" class="attachmentList">
                <div class="title row">
                  <template v-if="file.loading">
                    <BaseIcon
                      name="mdi-loading"
                      color="secondary"
                      class="q-mr-sm loading"
                    />
                  </template>
                  <div v-if="selectedProcess.processId" class="col-auto">
                    <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->

                    <BaseIcon
                      :name="
                        file.checked
                          ? 'eva-checkmark-circle-2'
                          : 'eva-radio-button-off-outline'
                      "
                      color="secondary"
                      class="q-mr-sm"
                      @click="file.checked = !file.checked"
                    />
                  </div>
                  <div v-if="file.formJsonId" class="col-auto">
                    <BaseIcon
                      v-tooltip:green.top="getLabel(file.formJsonId)"
                      name="mdi-form-select"
                      color="primary"
                      class="q-mr-sm"
                    />
                  </div>
                  <div class="col-auto">
                    <FileIcon
                      :mime-type="fileType(file.name)"
                      class="mini-avatar q-mr-sm"
                    />
                  </div>
                  <div class="col ellipsis" @click="openFile(file)">
                    {{ file.name }}
                  </div>
                  <div v-if="!file.loading" class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Download'"
                      icon="eva-download-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Download')"
                    />
                  </div>
                  <div v-if="!file.loading" class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Print'"
                      icon="eva-printer-outline"
                      color="primary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="fileMenuAction(file, 'Print')"
                    />
                  </div>
                </div>

                <div class="meta row">
                  <div class="col row">
                    <!-- created at -->

                    <div>{{ $day.format(file.createdAt) }}</div>

                    <!-- ... -->

                    <!-- created by -->

                    <template v-if="file.createdBy">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ file.createdByEmail }}</div>
                    </template>

                    <!-- ... -->

                    <!-- size -->

                    <template v-if="file.size">
                      <BaseIcon name="mdi-circle-small" />
                      <div>{{ fileSize(file.size) }}</div>
                    </template>

                    <!-- ... -->
                  </div>
                  <div class="col-auto">
                    <BaseActionButton
                      v-tooltip.top="'Metadata'"
                      icon="eva-file-text-outline"
                      color="secondary"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="getFileData(file)"
                    />
                  </div>
                  <div
                    v-if="
                      ((selectedNode === 'inbox' ||
                        selectedNode === 'common' ||
                        selectedNode === 'payment') &&
                        file.createdBy === $store.state.session.id) ||
                      initiateProcess
                    "
                    class="col-auto"
                  >
                    <BaseActionButton
                      v-tooltip.top="'delete'"
                      icon="eva-trash-2-outline"
                      color="red"
                      no-border
                      is-flat
                      size="20px"
                      @click.stop="attachmentDelete(file)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>
    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      :custom-height="
        selectedNode === 'inbox' ||
        selectedNode === 'common' ||
        selectedNode === 'payment' ||
        initiateProcess
          ? '30'
          : '0'
      "
      no-padding
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <template #actions>
        <div
          v-if="selectedProcess.processId"
          class="q-ml-sm text-gray q-mr-sm ellipsis"
          style="font-size: 10px"
        >
          auto-refresh every 30 seconds
        </div>

        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getComments(0)"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar :height="commentsSheetHeight">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail || row.externalCommentsby
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-1'
              "
              class="q-ma-sm"
            >
              <template v-slot:avatar>
                <div
                  v-if="row.embedFileList && row.embedFileList.length"
                  class="file-icons"
                >
                  <FileIcon
                    :key="`Icon-${idx}`"
                    v-tooltip.top="row.embedFileList[0].fileName"
                    :mime-type="fileType(row.embedFileList[0].fileName)"
                    class="file-icon q-mr-sm"
                  />
                </div>
              </template>
            </q-chat-message>
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div
        v-if="
          selectedNode === 'inbox' ||
          selectedNode === 'common' ||
          selectedNode === 'payment' ||
          initiateProcess
        "
        class="col borderTop"
      >
        <div class="row">
          <template v-if="checkTenant()">
            <div class="col actions">
              <div
                v-if="attachmentList.length && fileNames.length"
                class="row select-action q-pa-sm action-container"
              >
                <CheckboxField
                  v-model="attachComment"
                  label="Add Comment to PDF"
                  class="q-pr-sm"
                  :class="attachComment ? 'col-auto' : 'col'"
                />

                <CheckboxField
                  v-if="
                    selectedProcess.processId &&
                    selectedProcess.stageType !== 'START' &&
                    !attachComment
                  "
                  v-model="notifyInitiator"
                  label="Notify Initiator/Requester"
                  class="col-auto notify-cmd"
                />

                <SelectField
                  v-if="attachComment"
                  v-model="selectedFileForAttach"
                  :is-searchable="true"
                  :options="fileNames"
                  class="col field comment-attach attach-cmd"
                />
              </div>

              <CheckboxField
                v-if="
                  selectedProcess.processId &&
                  selectedProcess.stageType !== 'START' &&
                  (attachComment ||
                    !(attachmentList.length && fileNames.length))
                "
                v-model="notifyInitiator"
                label="Notify Initiator/Requester"
                :class="notifyInitiatorClass"
                class="col-12 q-pl-sm q-pb-sm q-pt-sm"
              />
              <SingleChoiceField
                v-if="displayInternalPublic"
                v-model="commentAction"
                :options="commentActions"
                :show-options-wrapper="false"
                :options-per-line="2"
                class="action-container"
                :class="{
                  'internal-public': attachmentList.length > 0,
                  'col q-pa-sm': true,
                  'internal-public-margin': attachComment,
                }"
              />
            </div>
          </template>
          <template v-if="isTenantGOTOComments()">
            <SingleChoiceField
              v-model="jiraCommentAction"
              :options="jiraCommentActions"
              :options-per-line="2"
              class="col q-mb-sm actions"
            />
          </template>
        </div>
        <div class="row text-area-action q-ml-sm q-mr-sm">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto; margin-right: 8px">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm comment-actions"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm comment-actions"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>

    <Sheet
      v-model="mailTransactionSheet"
      width="30vw"
      no-padding
      @input="mailTransactionSheet = false"
    >
      <!-- title -->

      <template #title> Mail Transactions </template>

      <!-- ... -->

      <template #actions>
        <div class="q-ml-sm text-gray q-mr-sm ellipsis" style="font-size: 10px">
          auto-refresh every 30 seconds
        </div>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="getMailTransactions()"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <div class="q-pl-sm">
          <Tabs v-model="mailTab" :tabs="mailTabs" />
        </div>
        <template v-if="mailTab === 'OUTGOING'">
          <BaseScrollbar height="calc(100vh - 120px)">
            <template v-for="row in outgoingMails">
              <div :key="'outgoing' + row.id" class="mailTransactionList">
                <div class="row">
                  <div class="col">
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-email-outline"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ row.subject }}
                      </div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-person-outline"
                          color="secondary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">{{ row.email }}</div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-clock-outline"
                          color="orange"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ $day.format(row.createdAt, true) }}
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-auto">
                        <BaseIcon
                          name="mdi-file-link-outline"
                          color="red"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto text-underline">123456987.msg</div>
                    </div> -->
                  </div>
                  <div class="col-auto q-mr-sm">
                    <BaseActionButton
                      v-tooltip.top="'attachments'"
                      icon="eva-attach-2-outline"
                      color="primary"
                      is-flat
                      size="16px"
                      class="q-mb-sm"
                      @click.stop="showMailAttachments(row)"
                    >
                      <span v-if="row.attachmentCount" class="badge"
                        >{{ row.attachmentCount }}
                      </span>
                    </BaseActionButton>

                    <BaseActionButton
                      v-tooltip.top="'resend'"
                      icon="mdi-send"
                      color="secondary"
                      is-flat
                      size="16px"
                      @click.stop="resendMail(row.ezMailId)"
                    />
                  </div>
                </div>
              </div>
            </template>
          </BaseScrollbar>
        </template>
        <template v-if="mailTab === 'INCOMING'">
          <BaseScrollbar height="calc(100vh - 120px)">
            <template v-for="row in incomingMails">
              <div :key="'incoming' + row.id" class="mailTransactionList">
                <div class="row">
                  <div class="col">
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-email-outline"
                          color="primary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ row.subject }}
                      </div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-person-outline"
                          color="secondary"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">{{ row.email }}</div>
                    </div>
                    <div class="row q-mb-sm">
                      <div class="col-auto">
                        <BaseIcon
                          name="eva-clock-outline"
                          color="orange"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto">
                        {{ $day.format(row.createdAt, true) }}
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-auto">
                        <BaseIcon
                          name="mdi-file-link-outline"
                          color="red"
                          class="q-mr-sm"
                        />
                      </div>
                      <div class="col-auto text-underline">123456987.msg</div>
                    </div> -->
                  </div>
                  <div class="col-auto q-mr-sm">
                    <BaseActionButton
                      v-tooltip.top="'attachments'"
                      icon="eva-attach-2-outline"
                      color="primary"
                      is-flat
                      size="16px"
                      class="q-mb-sm"
                      @click.stop="showMailAttachments(row)"
                    >
                      <span v-if="row.attachmentCount" class="badge"
                        >{{ row.attachmentCount }}
                      </span>
                    </BaseActionButton>
                  </div>
                </div>
              </div>
            </template>
          </BaseScrollbar>
        </template>
      </div>

      <!-- ... -->
    </Sheet>
    <Sheet
      v-model="mailAttachmentSheet"
      width="30vw"
      no-padding
      @input="mailAttachmentSheet = false"
    >
      <!-- title -->

      <template #title> Mail Attachments </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 200px)">
          <template v-for="file in mailAttachmentList">
            <div :key="file.id" class="attachmentList">
              <div class="title row">
                <div class="col-auto">
                  <FileIcon
                    :mime-type="fileType(file.name)"
                    class="mini-avatar q-mr-sm"
                  />
                </div>
                <div class="col ellipsis" @click="openAttachmentFile(file)">
                  {{ file.name }}
                </div>
              </div>

              <div class="meta">
                <!-- created at -->

                <div>{{ $day.format(file.createdAt) }}</div>

                <!-- ... -->

                <!-- size -->

                <template v-if="file.size">
                  <BaseIcon name="mdi-circle-small" />
                  <div>{{ fileSize(file.size) }}</div>
                </template>

                <!-- ... -->
              </div>
            </div>
          </template>
        </BaseScrollbar>
      </div>
    </Sheet>
    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <template v-if="selectedFile.formJsonId">
            <BaseActionButton
              v-tooltip:green.bottom="getLabel(selectedFile.formJsonId)"
              is-flat
              icon="mdi-form-select"
              color="primary"
              class="q-mr-sm"
            />
          </template>
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>
                Archived By:
                {{ selectedFile.createdByEmail || selectedFile.createdBy }}
              </div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action.id)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myAttachmentFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>
    <Modal
      v-model="requestActionModal"
      width="70vw"
      has-footer
      @input="closeRequestActionModal"
    >
      <!-- title -->

      <template #title>Request Action</template>

      <!-- ... -->

      <template #default>
        <RenderForm
          :form-id="formId"
          :panels="panels"
          :secondary-panels="secondaryPanels"
          :form-settings="formSettings"
          :form-model="formModel"
          :workflow-form-model.sync="workflowFormModel"
          :save-action="saveAction"
        />

        <div
          v-if="
            internalForward.proceed && groupActionModalButton.name === 'Assign'
          "
        >
          <!-- user -->

          <MultiSelectField
            v-model="forwardUsers"
            label="users"
            is-mandatory
            :options="userList"
            class="field q-ml-md q-mr-md q-mb-md"
          />

          <TextAreaField
            v-model="commentText"
            placeholder="Type a comment..."
            class="field q-ml-md q-mr-md q-mb-md"
          />
        </div>
      </template>

      <!-- footer -->

      <template #footer>
        <BaseButton
          :label="groupActionModalButton.name"
          :color="groupActionModalButton.color"
          @click="submitGroupAction"
        />
      </template>

      <!-- ... -->
    </Modal>

    <Modal
      v-model="processForwardModal"
      width="40vw"
      height="60vh"
      has-footer
      @input="
        {
          saveAction = '';
          workflowFormModel = {};
          processForwardModal = false;
        }
      "
    >
      <!-- title -->

      <template #title>
        <span v-if="!processForwardGroupAction"
          >"{{ selectedProcess.requestNo }}"</span
        >
        <template v-if="isTenantGOTO() || isTenantArmgroup()">
          Process Assign
        </template>
        <template v-else> Process Forward </template>
      </template>

      <!-- ... -->

      <template #default>
        <div id="processForward">
          <template v-if="isTenantArmgroup()">
            <!-- user -->

            <SelectField
              v-model="armgroupForwardUser"
              label="users"
              :options="userList"
              class="field q-mb-md"
            />

            <!-- ... -->
          </template>
          <template v-else>
            <!-- user -->

            <MultiSelectField
              v-model="forwardUsers"
              label="users"
              :options="userList"
              class="field q-mb-md"
            />

            <!-- ... -->

            <!-- group -->

            <MultiSelectField
              v-model="forwardGroups"
              label="group"
              :options="groupList"
              class="field"
            />

            <!-- ... -->

            <TextAreaField
              v-if="processForwardGroupAction"
              v-model="commentText"
              placeholder="Type a comment..."
              class="field q-mt-md"
            />
          </template>
        </div>
      </template>

      <template #footer>
        <template v-if="isTenantGOTO() || isTenantArmgroup()">
          <BaseButton
            label="Assign"
            color="orange"
            @click="
              {
                processForwardGroupAction
                  ? groupProcessRequest()
                  : processRequest(false, false);
              }
            "
          />
        </template>
        <template v-else>
          <BaseButton
            label="Forward"
            color="orange"
            @click="
              {
                processForwardGroupAction
                  ? groupProcessRequest()
                  : processRequest(false, false);
              }
            "
          />
        </template>
      </template>
    </Modal>

    <Sheet
      v-model="jiraIssueSheet"
      width="40vw"
      has-footer
      @input="closeJiraIssueSheet"
    >
      <!-- title -->

      <template #title> JIRA - Create Issue </template>

      <!-- ... -->

      <template #default>
        <div id="content-wrapper" class="jiraIssue">
          <ValidationObserver ref="form">
            <div class="field-wrapper">
              <ValidationProvider
                v-slot="{ errors }"
                name="Issue Name"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="issueName"
                  label="Issue Name"
                  is-mandatory
                  :error="errors[0]"
                  class="field"
                />
              </ValidationProvider>

              <ValidationProvider
                v-if="false"
                v-slot="{ errors }"
                name="Project Key"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="projectKey"
                  label="Project Key"
                  is-mandatory
                  :error="errors[0]"
                  tooltip="Unique identifier key of your Project"
                  class="field"
                />
              </ValidationProvider>

              <ValidationProvider
                v-if="false"
                v-slot="{ errors }"
                name="Issue Type"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="issueType"
                  label="Issue Type"
                  is-mandatory
                  :error="errors[0]"
                  class="field"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Description"
                :rules="{ required: true }"
              >
                <TextAreaField
                  v-model="issueDescription"
                  label="Description"
                  is-mandatory
                  :error="errors[0]"
                  class="field"
                />
              </ValidationProvider>

              <!-- <ValidationProvider
                v-slot="{ errors }"
                name="Epic Value"
                :rules="{ required: true }"
              >
                <TextField
                  v-model="issueEpicValue"
                  label="Epic Value"
                  is-mandatory
                  :error="errors[0]"
                  class="field"
                />
              </ValidationProvider> -->

              <CheckboxField
                v-if="false"
                v-model="issueDescriptionTable"
                label="Include inbox request information as a table of issue description"
                class="field"
              />
              <!-- v-if="issueDescriptionTable" -->
              <MultiSelectField
                v-if="false"
                v-model="issueDescriptionFields"
                label="Request Fields"
                :is-searchable="true"
                :options="formFields"
                class="field"
              />

              <SelectField
                v-if="false"
                v-model="issueAssignee"
                label="Assignee"
                :is-searchable="true"
                :options="issueAssigneeUsers"
                class="field"
              />
            </div>
          </ValidationObserver>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Create Issue"
          color="primary"
          @click="createJiraIssue"
        />
      </template>
    </Sheet>

    <Modal
      v-model="jiraConfirmModal"
      width="40vw"
      height="70vh"
      has-footer
      @input="closeJiraConfirmModal"
    >
      <!-- title -->

      <template #title> JIRA - Issue Created Successful </template>

      <!-- ... -->

      <template #default>
        <div>
          <div class="field-wrapper">
            <div class="row">
              <div class="col-auto">
                <FormFieldLabel label="REF# ID" is-mandatory />
              </div>
              <div class="col-auto q-ml-sm">
                <BaseActionButton
                  v-tooltip.top="'Copy Reference Id'"
                  icon="mdi-content-copy"
                  color="secondary"
                  no-border
                  size="12px"
                  class="copyActions"
                  @click="copyText(issueRef.id)"
                ></BaseActionButton>
              </div>
            </div>

            <TextField v-model="issueRef.id" is-readonly class="q-mb-sm" />

            <div class="row">
              <div class="col-auto">
                <FormFieldLabel label="KEY" is-mandatory />
              </div>
              <div class="col-auto q-ml-sm">
                <BaseActionButton
                  v-tooltip.top="'Copy Reference Key'"
                  icon="mdi-content-copy"
                  color="secondary"
                  no-border
                  size="12px"
                  class="copyActions"
                  @click="copyText(issueRef.key)"
                ></BaseActionButton>
              </div>
            </div>
            <TextField v-model="issueRef.key" is-readonly class="q-mb-sm" />

            <div class="row">
              <div class="col-auto">
                <FormFieldLabel label="REF# URL" is-mandatory />
              </div>
              <div class="col-auto q-ml-sm">
                <BaseActionButton
                  v-tooltip.top="'Copy Reference URL'"
                  icon="mdi-content-copy"
                  color="secondary"
                  no-border
                  size="12px"
                  class="copyActions"
                  @click="copyText(issueRef.self)"
                ></BaseActionButton>
              </div>
            </div>
            <TextField v-model="issueRef.self" is-readonly class="q-mb-sm" />
          </div>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="primary"
          @click="closeJiraConfirmModal"
        />
      </template>
    </Modal>

    <Sheet v-model="taskSheet" width="30vw" @input="closeTaskSheet">
      <!-- title -->

      <template #title> Tasks </template>

      <!-- ... -->

      <template #actions>
        <!-- <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
        /> -->

        <BaseActionButton
          v-tooltip.top="'Add Task'"
          icon="mdi-plus"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="$emit('addTask')"
        />
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <template v-for="task in taskEntries">
          <div :key="task.id" class="taskEntries">
            <div class="col-12">
              <div class="row">
                <div
                  class="col-auto q-mr-md"
                  style="margin-top: 3px"
                  @click="$emit('viewTask', task.id)"
                >
                  {{ task.name }}
                </div>
                <q-chip
                  size="10px"
                  :color="getColor(task.priority)"
                  text-color="white"
                >
                  {{ task.priority }}
                </q-chip>
                <q-chip
                  size="10px"
                  :color="getColor(task.status)"
                  text-color="white"
                >
                  {{ task.status }}
                </q-chip>
              </div>
            </div>

            <div class="meta row">
              <div class="col-12">Assigned To: {{ task.assigned }}</div>
              <div class="col-12">
                Start Date: {{ $day.format(task.startDate, false) }}
                <BaseIcon name="mdi-circle-small" />
                End Date: {{ $day.format(task.endDate, false) }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </Sheet>

    <EmailSharing
      v-model="emailSharingSheet"
      :repository-id="workflow.repositoryId"
      :file-list.sync="selectedFileList"
    ></EmailSharing>
    <FileDetails v-model="fileDetailsSheet" :file-data="fileData"></FileDetails>

    <Confirm
      v-model="errorModal"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          errorModal = false;
          errorMessage = '';
        }
      "
    >
      <template #title>Error Message</template>

      <template #default>
        <div class="q-py-md">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="red"
          @click="
            {
              errorModal = false;
              errorMessage = '';
            }
          "
        />
      </template>
    </Confirm>

    <Confirm
      v-model="successModal"
      icon="eva-checkmark-circle-2-outline"
      icon-color="green"
      @input="successModal = false"
    >
      <template #title>Success</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Your request for Jira ticket creation has been submitted and the
            process has been initiated. You will receive notifications once the
            process is complete.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="primary"
          @click="successModal = false"
        />
      </template>
    </Confirm>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="
        {
          saveAction = '';
          workflowFormModel = {};
          advancedCommentsSheet = false;
        }
      "
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <template v-if="checkTenant()">
          <div class="row actions advance-cmd">
            <template v-if="attachmentList.length && fileNames.length">
              <CheckboxField
                v-model="attachComment"
                label="Add Comment to PDF"
                class="col-auto q-pr-sm"
                style="margin: auto"
              />

              <SelectField
                v-if="attachComment"
                v-model="selectedFileForAttach"
                :is-searchable="true"
                :options="fileNames"
                class="field comment-attach"
              />
            </template>
            <CheckboxField
              v-if="
                selectedProcess.processId &&
                selectedProcess.stageType !== 'START'
              "
              v-model="notifyInitiator"
              label="Notify Initiator/Requester"
              class="col-auto q-pl-sm adv-notify-cmd"
            />
          </div>
          <SingleChoiceField
            v-if="checkTenant() && displayInternalPublic"
            v-model="commentAction"
            :options="commentActions"
            :options-per-line="2"
            class="actions q-mr-sm noborder"
          />
        </template>
        <template v-if="isTenantGOTOComments()">
          <SingleChoiceField
            v-model="jiraCommentAction"
            :options="jiraCommentActions"
            :options-per-line="2"
            class="col q-mb-sm actions"
          />
        </template>
        <BaseButton
          label="Post Comments"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <Confirm
      v-model="alertModal"
      icon="mdi-file-alert"
      icon-color="red"
      @input="closeAlertModal"
    >
      <template #title>Documents Required!</template>

      <template #default>
        <div class="q-py-md text-md">
          <p>
            Are you sure you want to submit the request without attaching the
            required documents.
          </p>

          <!-- <p class="text-secondary">
            After submitting, you can upload the required documents from sent
            list once you have them.
          </p> -->
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          @click="closeAlertModal"
        />
        <BaseButton label="yes" color="primary" @click="proceedSubmission" />
      </template>
    </Confirm>

    <Confirm
      v-model="actionPasswordConfirm"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          saveAction = '';
          workflowFormModel = '';
          actionPasswordConfirm = false;
        }
      "
    >
      <template #title> Action Password</template>

      <template #default>
        <ValidationObserver ref="passwordForm">
          <!-- password -->

          <ValidationProvider
            v-slot="{ errors }"
            name="password"
            :rules="{ required: true, password: true }"
          >
            <PasswordField
              v-model="actionUserPassword"
              is-mandatory
              :is-readonly="isLoading"
              :error="errors[0]"
              :auto-focus="true"
              class="q-my-lg"
              @enter="validatePassword"
            />
          </ValidationProvider>

          <!-- ... -->
        </ValidationObserver>
      </template>

      <template #footer>
        <BaseButton
          label="validate"
          :is-loading="isLoading"
          @click="validatePassword"
        />
      </template>
    </Confirm>

    <Confirm
      v-model="actionConfirmAlert"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="actionConfirmAlert = false"
    >
      <template #title> Confirmation</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Are you sure you want to confirm the action as "
            <b>{{ saveAction }}</b
            >"?
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="primary"
          @click="
            {
              saveAction = '';
              workflowFormModel = {};
              actionConfirmAlert = false;
            }
          "
        />
        <BaseButton
          label="yes"
          color="primary"
          @click="
            {
              actionConfirmAlert = false;
              processRequest(false, false);
            }
          "
        />
      </template>
    </Confirm>

    <MetaDataEdit
      v-if="showMetaDataEditModal"
      :value="showMetaDataEditModal"
      :file="workflow"
      :repository-field="repositoryField"
      :file-data="fileData"
      :title="metaDataTitle || 'Index'"
      :columns="columns"
      :modal-value="workflowModel"
      :upload-filename="uploadFilename"
      :meta-data-fields="metaDataModal"
      @close="closeMetaDataEditModal"
      @save="saveMetaData"
    />

    <Sheet
      v-model="showCustomProcessForm"
      :width="formWidth"
      :has-footer="!isCustomizedWorkflow"
      no-padding
      @input="showCustomProcessForm = false"
    >
      <!-- title -->

      <template #title> Custom Form </template>

      <!-- ... -->

      <!-- content -->

      <div id="content-wrapper" class="row">
        <div class="col">
          <template v-if="isCustomizedWorkflow">
            <CustomProcessForm
              :panels="panels"
              :secondary-panels="secondaryPanels"
              :save-action.sync="saveAction"
              :workflow-form-model.sync="workflowFormModel"
              :actions="actions"
            />
          </template>
        </div>

        <div class="col-auto q-mr-sm leftBorder">
          <template v-for="tab in tabs">
            <BaseActionButton
              :key="tab.id"
              v-tooltip.left="tab.label"
              is-flat
              no-border
              :icon="tab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(tab.value)"
            >
              <span v-if="tab.count" class="badge">{{ tab.count }} </span>
            </BaseActionButton>
          </template>
        </div>
      </div>

      <!-- ... -->

      <!-- footer -->
      <template #footer> </template>
      <!-- ... -->
    </Sheet>

    <SubWorkflowProcess
      v-if="initiateSubWorkflowProcess || openSubProcessSheet"
      :workflow-id="subWorkflowId || subProcessData.id"
      :initiate-sub-workflow-process="initiateSubWorkflowProcess"
      :main-workflow-id="workflow.id"
      :main-process-id="selectedProcess.processId"
      :sub-process-link="subProcessLink"
      :selected-process="subProcessData"
      :open-sub-process="openSubProcessSheet"
      :main-form-fields="formFields"
      :main-form-model="checkFormModel"
      :sub-process-id.sync="subProcessId"
      @closeProcessSheet="closeSubProcess"
    />
    <!-- @commentsLength="getCommentsLength"
      @attachmentLength="getAttachmentLength" -->

    <Sheet v-model="subProcessSheet" width="30vw" @input="closeSubProcessSheet">
      <!-- title -->

      <template #title> Linked Request </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <template v-for="(process, index) in subProcessTickets">
          <div :key="index" class="subProcess">
            <div class="title row">
              <div class="col-auto">
                <BaseIcon
                  name="o_account_tree"
                  class="q-mr-sm"
                  color="primary-4"
                />
              </div>
              <div
                class="col ellipsis text-underline"
                @click="openSubProcess(process)"
              >
                {{ process.requestNo }}
              </div>
              <q-chip
                v-if="process.flowStatus === '1'"
                size="10px"
                color="green"
                text-color="white"
              >
                Completed
              </q-chip>
              <q-chip v-else size="10px" color="orange" text-color="white">
                Running
              </q-chip>
            </div>

            <div class="meta row">
              <div class="col row">
                <div>{{ process.name }}</div>
                <BaseIcon name="mdi-circle-small" />
                <div>{{ process.stage }}</div>
                <BaseIcon name="mdi-circle-small" />
                <div>{{ process.duration }} ago</div>
              </div>
            </div>
            <div v-if="false" class="col-auto q-mr-sm q-pt-md leftBorder">
              <template v-for="tab in subTabs">
                <BaseActionButton
                  :key="tab.id"
                  v-tooltip.left="tab.label"
                  is-flat
                  no-border
                  :icon="tab.icon"
                  color="primary"
                  class="linkActions"
                  size="16px"
                  @click="subProcessShowLinks(tab.value, process)"
                >
                  <span
                    v-if="
                      tab.value === 'ATTACHMENTS' && process.attachmentCount
                    "
                    class="badge"
                    >{{ process.attachmentCount }}
                  </span>

                  <span
                    v-if="tab.value === 'COMMENTS' && process.commentsCount"
                    class="badge"
                    >{{ process.commentsCount }}
                  </span>
                </BaseActionButton>
              </template>
            </div>
          </div>
        </template>
      </div>
    </Sheet>

    <Confirm
      v-model="requestReleaseModal"
      icon="eva-alert-circle-outline"
      icon-color="orange"
      @input="requestReleaseModal = false"
    >
      <template #title> Request release confirmation</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Please confirm the request "<b>{{ selectedProcess.requestNo }}</b
            >, has been released by you to be approved by others.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="cancel"
          class="q-mr-sm"
          color="orange"
          @click="requestReleaseModal = false"
        />
        <BaseButton
          label="Confirm"
          color="orange"
          @click="
            {
              requestReleaseModal = false;
              $emit('release', selectedProcess);
              closeProcessSheet(true);
            }
          "
        />
      </template>
    </Confirm>
    <GenerateQr
      :value="showQr"
      :repository-id="workflow.repositoryId"
      :field-id="selectedFieldId"
      :field-name="selectedFieldName"
      :is-file-uploaded="QRFileUploaded"
      @checkUploadId="checkMobileUpload"
      @expired="QRexpired"
      @input="closeQR"
    />
    <Attachments
      v-if="showAttachmentSheet"
      :value.sync="showAttachmentSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="selectedNode"
      :initiate-process="initiateProcess"
      :form-check-list="formCheckList"
      :check-list="checkList"
      :wform="wform"
      :repository-details="repositoryDetails"
      :workflow-model="workflowModel"
      :form-upload-files="formUploadFiles"
      :repository-field="repositoryField"
      :columns="columns"
      :attachments.sync="attachmentList"
      :workflow-id="workflow.id"
      @attachmentCount="attachmentCount"
    />

    <Comments
      v-if="showCommentsSheet"
      :value.sync="showCommentsSheet"
      :selected-process="selectedProcess"
      :workflow="workflow"
      :selected-node="selectedNode"
      :initiate-process="initiateProcess"
      :attachment-list="attachmentList"
      :workflow-id="workflow.id"
      :file-names="fileNames"
      :comments.sync="commentsList"
      :display-internal-public="displayInternalPublic"
      @commentsCount="commentsCount"
    />
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import {
  workflow,
  repository,
  uploadAndIndex,
  integration,
  auth,
  menu,
  report,
  transaction,
} from "@/api/factory.js";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import { Portal } from "portal-vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import DataType from "@/constants/data-type.js";
import ProcessList from "./ProcessList.vue";
import ListItem from "@/components/common/ListItem.vue";
import Modal from "@/components/common/popup/Modal.vue";
import FilterItems from "@/components/common/display/item-actions/components/FilterItems.vue";
import SortItems from "@/components/common/display/item-actions/components/SortItems.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ImportPopup from "@/components/common/display/item-actions/components/ImportPopup.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import formatBytes from "@/helpers/format-bytes.js";
import {
  pdfSupport,
  htmlSupport,
  fileSupport,
  otherFilePDFSupport,
} from "@/helpers/file-format.js";
import axios from "axios";
import SignatureField from "@/components/common/form/signature-field/SignatureField.vue";
import EmailSharing from "@/components/common/EmailSharing.vue";
import FileDetails from "./FileDetails";
import { copyToClipboard } from "quasar";
import Confirm from "@/components/common/popup/Confirm.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import CustomForm from "@/views/custom/CustomForm.vue";
import CustomProcessForm from "@/views/custom/CustomProcessForm.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";
import MetaDataEdit from "@/views/repositories/views/repository-browse-and-trash/components/components/MetaDataEdit";
import Tabs from "@/components/common/tabs/Tabs.vue";
import { exportData } from "@/helpers/export-excel-data";
import SearchItems from "@/components/common/SearchItems.vue";
import { mapState } from "vuex";
import SubWorkflowProcess from "./components/SubWorkflow";
import { fileToBase64 } from "@/helpers/file-to-base64.js";
import { decrypt } from "@/helpers/crypto";
import { lowerCase, isEmpty } from "lodash-es";
// import Stepper from "@/components/common/workflow/Stepper.vue";
import Attachments from "@/components/common/workflow/Attachments.vue";
import Comments from "@/components/common/workflow/Comments.vue";
import GenerateQr from "@/components/common/form/file-upload-field/GenerateQr.vue";
import ProgressBar from "@/views/dashboard/components/MobileLoadingBar.vue";

export default {
  name: "InboxList",

  components: {
    ValidationObserver,
    ValidationProvider,
    Sheet,
    RenderForm,
    FormFieldLabel,
    CheckboxField,
    TextAreaField,
    TextField,
    Portal,
    ItemActions,
    ProcessList,
    ListItem,
    Modal,
    FilterItems,
    SortItems,
    MultiSelectField,
    Pagination,
    ImportPopup,
    FileIcon,
    SignatureField,
    EmailSharing,
    FileDetails,
    Confirm,
    SingleChoiceField,
    SelectField,
    CustomForm,
    CustomProcessForm,
    TextBuilderField,
    PasswordField,
    MetaDataEdit,
    Tabs,
    SearchItems,
    SubWorkflowProcess,
    // Stepper,
    Attachments,
    Comments,
    GenerateQr,
    ProgressBar,
  },

  props: {
    selectedNode: {
      type: String,
      required: true,
      default: "My Inbox",
    },
    myInbox: {
      type: Boolean,
      required: true,
    },
    workflow: {
      type: Object,
      required: true,
    },
    workflowList: {
      type: Array,
      default: () => [],
    },
    workflowRequestList: {
      type: Array,
      default: () => [],
    },
    groupKey: {
      type: Array,
      default: () => [],
    },
    wform: {
      type: Object,
      default: () => {},
    },
    repository: {
      type: Object,
      default: () => {},
    },
    processActions: {
      type: Array,
      default: () => [],
    },
    processActionsAddOn: {
      type: Array,
      default: () => [],
    },
    selectedProcess: {
      type: Object,
      default: () => {},
    },
    fullView: {
      type: Boolean,
      default: false,
    },
    activeView: {
      type: String,
      default: "",
    },
    internalForward: {
      type: Object,
      default: () => {},
    },
    userList: {
      type: Array,
      default: () => [],
    },
    groupList: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    masterFormId: {
      type: Number,
      default: 0,
    },
    dataImported: {
      type: Boolean,
      default: false,
    },
    userSignature: {
      type: Boolean,
      default: false,
    },
    taskFormId: {
      type: Number,
      default: 0,
    },
    taskEntryId: {
      type: Array,
      default: () => [],
    },
    taskEntries: {
      type: Array,
      default: () => [],
    },
    checkList: {
      type: Array,
      default: () => [],
    },
    customReply: {
      type: Boolean,
      default: false,
    },
    userReply: {
      type: Boolean,
      default: false,
    },
    toRequester: {
      type: Boolean,
      default: false,
    },
    dataValidationValues: {
      type: Array,
      default: () => [],
    },
    customCloseProcessSheet: {
      type: Boolean,
      required: false,
    },
    selectedCompletedNode: {
      type: String,
      default: "",
    },
    hideColumns: {
      type: Array,
      default: () => [],
    },
    addTaskEnabled: {
      type: Boolean,
      default: false,
    },

    loadingBarInbox: {
      type: Boolean,
      default: false,
    },

    portalAction: {
      type: Boolean,
      default: false,
    },

    dashboard: {
      type: Boolean,
      default: false,
    },

    exporting: {
      type: Boolean,
      default: false,
    },

    dashboardView: {
      type: Boolean,
      default: false,
    },

    dashboardId: {
      type: Number,
      default: 0,
    },

    dashboardName: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      hideActions: ["sort", "group"],
      search: "",
      initiate: false,
      importExcel: false,
      processSheet: false,
      headerTitle: "",
      formModel: {},
      form: {},
      formId: "",
      actions: [],
      workflowFormModel: {},
      saveAction: "",
      generatePDF: 0,
      documentRequired: false,
      hasSelectedProcess: 0,
      hasSelectedWorkflow: 0,
      initiateProcess: false,
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "mail transaction",
          value: "MAIL",
          icon: "mdi-email-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "print",
          value: "PRINT",
          icon: "mdi-printer",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "task",
          value: "TASK",
          icon: "eva-cube-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Data Validation",
          value: "VALIDATION",
          icon: "mdi-file-compare",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Sub Process",
          value: "SUB_PROCESS",
          icon: "eva-link-2-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "Form Entry History",
          value: "FORM_ENTRY_HISTORY",
          icon: "mdi-file-document",
          count: 0,
        },
      ],
      mailTabs: [
        {
          id: this.$nano.id(),
          label: "outgoing",
          value: "OUTGOING",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "incoming",
          value: "INCOMING",
          count: 0,
        },
      ],
      mailTab: "OUTGOING",
      attachmentSheet: false,
      commentsSheet: false,
      mailTransactionSheet: false,
      commentText: "",
      commentsList: [],
      attachmentList: [],
      mailTransactionList: [],
      mailAttachmentSheet: false,
      mailAttachmentList: [],
      fileSheet: false,
      selectedFile: {},
      isLoading: false,
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupBy: "",
      columns: [],
      requestActionModal: false,
      groupActionModalButton: {},
      groupActionKey: "",
      groupActionFields: [],
      workflowRequestListData: [],
      armgroupForwardUser: "",
      processForwardModal: false,
      forwardUsers: [],
      forwardGroups: [],
      processForwardGroupAction: false,
      selectedFilter: [],
      filterBy: [],
      attachmentLoad: null,
      signImg: "",
      signList: [],
      selectAll: false,
      emailSharingSheet: false,
      selectedFileList: [],
      fileDetailsSheet: false,
      fileData: [],
      jiraConnect: false,
      jiraAccounts: [],
      jiraIssueSheet: false,
      issueName: "",
      projectKey: "",
      issueType: "",
      issueDescription: "",
      issueEpicValue: "",
      issueAssignee: "",
      issueAssigneeUsers: [],
      issueDescriptionTable: false,
      issueDescriptionFields: [],
      jiraConfirmModal: false,
      issueRef: {},
      taskSheet: false,
      itemModel: {},
      errorMessage: "",
      errorModal: false,
      successModal: false,
      formWidth: "100vw",
      commentActions: [
        {
          id: this.$nano.id(),
          label: "Internal",
          value: 2,
        },
        {
          id: this.$nano.id(),
          label: "Public",
          value: 1,
        },
      ],
      commentAction: 2,
      jiraCommentActions: [
        {
          id: this.$nano.id(),
          label: "This resource",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: "All resource",
          value: 2,
        },
      ],
      jiraCommentAction: 1,
      formFields: [],
      checkListRowIndex: null,
      formEditAccess: false,
      formEditControls: [],
      readonlyControls: [],
      formVisibilityAccess: true,
      formSecureControls: [],
      requestPrefix: "",
      mlPrediction: "",
      advancedCommentsSheet: false,
      advancedCommentText: "",
      docWarning: false,
      alertModal: false,
      actionRemarks: false,
      actionPassword: false,
      actionPasswordConfirm: false,
      actionUserPassword: "",
      actionComments: false,
      actionConfirm: false,
      actionConfirmAlert: false,
      repositoryDetails: [],
      repositoryField: [],
      showMetaDataEditModal: false,
      selectedUploadFile: {},
      workflowModel: {},
      customVerify: false,
      formCheckList: false,
      reuploadFile: {},
      documentActionList: [
        // {
        //   id: "Email_Sharing",
        //   key: "emailSharing",
        //   label: "Email Sharing",
        //   icon: "mdi-email-outline",
        // },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      scrollPosition: 0,
      commentsLoad: null,
      mailTransactionLoad: null,
      documentLoad: null,
      documentLoadList: [],
      uploadFilename: "",
      filterSearchColumn: {},
      selectedFilterColumn: "",
      showCustomProcessForm: false,
      watchViewerClose: null,
      saveOnly: false,
      hasWflowInitiator: false,
      subWorkflowId: 0,
      initiateSubWorkflowProcess: false,
      subProcessSheet: false,
      subProcessData: {},
      subTabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
      ],
      subProcessLink: "",
      openSubProcessSheet: false,
      subProcessTickets: [],
      documentSignProcess: false,
      formUploadFiles: [],
      attachComment: false,
      fileNames: [],
      selectedFileForAttach: 0,
      displayInternalPublic: false,
      mandatory: {},
      fileControlDeleteFile: [],
      hasCheckFormValidate: false,
      subWorkflowName: "",
      fileUploadForControl: false,
      fileUploadInput: {},
      metaDataModal: {},
      metaDataTitle: "",
      requestReleaseModal: false,
      notifyInitiator: false,
      initUserSignature: false,
      isRequestLocked: false,
      selectedSubWorkflow: {},
      groupItemSelected: false,
      ocrResult: [],
      formView: true,
      stageLevel: [],
      currentStep: 0,
      subProcessId: 0,
      btnAction: false,
      refreshAction: false,
      autoSaveModel: {},
      showAttachmentSheet: false,
      showCommentsSheet: false,
      showQr: false,
      selectedFieldId: "",
      selectedFieldName: "",
      QRFileUploaded: false,
      uploadInterval: null,
      pinned: false,
      issavesheet: false,
      progressBars: [
        { width: "20%" },
        { width: "30%" },
        { width: "50%" },
        { width: "30%" },
        { width: "50%" },
        { width: "70%" },
        { width: "20%" },
        { width: "30%" },
        { width: "50%" },
        { width: "30%" },
        { width: "50%" },
        { width: "70%" },
        { width: "20%" },
        { width: "70%" },
      ],
      mobileUploadFileJson: "",
    };
  },

  computed: {
    ...mapState(["LoadingBar", "darkTheme"]),

    formName() {
      if (!this.formId) {
        return "";
      }

      return this.form.settings.general.name;
    },

    formDescription() {
      if (!this.formId) {
        return "";
      }

      return this.form.settings.general.description;
    },

    panels() {
      if (!this.formId) {
        return [];
      }
      if (this.groupActionFields.length) {
        let panels = [];
        this.form.panels.map((panel) => {
          let panelFields = [];
          panel.fields.forEach((field) => {
            if (this.groupActionFields.indexOf(field.id) > -1) {
              panelFields.push(field);
            }
          });
          panels.push({
            id: panel.id,
            fields: panelFields,
            settings: panel.settings,
          });
        });
        return panels;
      }
      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.formId) {
        return [];
      }
      if (this.groupActionFields.length) {
        return [];
      }
      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.formId) {
        return {};
      }
      return this.form.settings;
    },

    items() {
      return this.workflowRequestList.map((process) => ({
        id: process.processId,
        key: process.key,
        subKey: process.subKey || "",
        itemLink: true,
        processId: process.processId,
        workflowId: process.id,
        requestNo: process.requestNo,
        stage: process.stage,
        raisedAt: this.$day.format(process.raisedAt),
        raisedBy: process.raisedBy,
        actionUser: process.activityUserEmail,
        lastAction: process.lastAction,
        lastActionBy: process.transaction_createdByEmail,
        lastActionOn: this.$day.format(process.transaction_createdAt),
        duration: this.lastActionDuration(process),
        checked: process.checked,
        starred: process.starred,
        mlPrediction: process.mlPrediction,
        mlCondition: process.mlCondition,
        jiraIssueInfo: process.jiraIssueInfo,
        visible: process.visible,
        attachmentCount: process.attachmentCount || 0,
        commentsCount: process.commentsCount || 0,
        transactionId: process.transactionId,
        expandSubworkflow: false,
        checkboxRequired: process.checkboxRequired,
        ...this.getProcessData(process),
        subWorkflow: process.subworkflowTransaction
          ? process.subworkflowTransaction.map((subItem) => ({
              activityId: subItem.activityId,
              userId: subItem.activityUserId,
              color: subItem.flowStatus == 1 ? "green" : "orange",
              iconTooltip:
                subItem.flowStatus == 1 ? "Completed" : "In-progress",
              icon:
                subItem.flowStatus == 1
                  ? "mdi-check-circle-outline"
                  : "mdi-progress-clock",
              processId: subItem.processId,
              raisedAt: this.$day.format(subItem.raisedAt),
              raisedBy: subItem.raisedBy,
              raisedByUser: subItem.raisedByUserId,
              requestNo: subItem.requestNo,
              review: subItem.review,
              stageName: subItem.stageName,
              id: subItem.subWorkFlowId,
              parentProcessID: process.processId,
              stageType: subItem.stageType,
              duration: this.sublastActionDuration(subItem),
              stage: subItem.stageName,
              subWorkFlowId: subItem.subWorkFlowId,
              subWorkflowName: subItem.subWorkflowName,
              transactionId: subItem.transactionId,
              inbox:
                String(subItem.activityUserId) ===
                String(this.$store.state.session.id),
              attachmentsCount: subItem.attachmentCount,
              commentsCount: subItem.commentsCount,
              displaySubTable: false,
              actionUser: subItem.activityUserEmail,
            }))
          : [],
      }));
    },

    selectedFileCount() {
      this.clearAttach();
      let count = this.attachmentList.filter((item) => item.checked).length;
      return count;
    },

    isSelectedPDF() {
      let filePDF = true;
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() !== "pdf") {
          filePDF = false;
        }
      });
      return filePDF;
    },

    isMaximized() {
      return this.formWidth === "100vw";
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      if (this.panels) {
        this.panels.forEach((panel) => {
          if (panel.fields.length) {
            let panelFields = panel.fields.filter(
              (field) =>
                field.type === "SINGLE_SELECT" ||
                field.type === "SINGLE_CHOICE" ||
                field.type === "MULTIPLE_CHOICE" ||
                field.type === "MULTI_SELECT"
            );
            if (panelFields.length) {
              panelFields.forEach((row) => {
                if (row.settings.validation.enableSettings) {
                  if (row.settings.validation.enableSettings.length) {
                    let controls = [];
                    row.settings.validation.enableSettings.forEach((tblrow) => {
                      if (tblrow.controls.length) {
                        controls.push({
                          value: tblrow.value,
                          childControls: tblrow.controls,
                        });
                        tblrow.controls.forEach((control) => {
                          if (controls.indexOf(control) < 0) {
                            listControls.push(control);
                          }
                        });
                      }
                    });
                    if (controls.length) {
                      enableControls.push({
                        id: row.id,
                        parentControls: controls,
                      });
                    }
                  }
                }
              });
            }
          }
        });
      }

      return {
        listControls: listControls,
        controls: enableControls,
      };
    },

    isCustomizedWorkflow() {
      let origin = location.origin;
      if (
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        if (this.workflow.id === 1014) {
          return true;
        }
      } else if (
        this.isTenantArmgroup() ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12)
      ) {
        if (this.workflow.id === 1) {
          return true;
        }
      }
      return false;
    },

    outgoingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Outgoing"
      );
    },

    incomingMails() {
      return this.mailTransactionList.filter(
        (row) => row.emailType === "Incoming"
      );
    },

    commentsSheetHeight() {
      if (
        this.selectedNode === "inbox" ||
        this.selectedNode === "payment" ||
        this.selectedNode === "common" ||
        this.initiateProcess
      ) {
        if (this.checkCommentsOption() && !this.isCustomizedWorkflow) {
          if (this.attachmentList.length && this.fileNames.length) {
            if (this.attachComment && this.displayInternalPublic) {
              return "calc(100vh - 270px)";
            } else if (this.attachComment && !this.displayInternalPublic) {
              return "calc(100vh - 230px)";
            } else if (!this.attachComment && this.displayInternalPublic) {
              return "calc(100vh - 225px)";
            } else {
              return "calc(100vh - 200px)";
            }
          } else if (this.displayInternalPublic) {
            return "calc(100vh - 220px)";
          } else if (this.selectedProcess.stageType !== "START") {
            return "calc(100vh - 180px)";
          } else {
            return "calc(100vh - 150px)";
          }
        } else {
          return "calc(100vh - 150px)";
        }
      }
      return "";
    },

    notifyInitiatorClass() {
      return !(this.attachmentList.length && this.fileNames.length)
        ? "action-container"
        : "";
    },

    mandatoryDocument() {
      return this.checkList.filter((row) => row.required && !row.attach);
    },

    mandatoryFilling() {
      if (this.mandatory.filledValue && this.mandatory.nonFilledValue) {
        return Math.floor(
          (this.mandatory.filledValue /
            (this.mandatory.filledValue + this.mandatory.nonFilledValue)) *
            100
        );
      } else if (this.mandatory.filledValue) {
        return 100;
      }
      return 0;
    },

    checkFormModel() {
      if (!isEmpty(this.formModel)) {
        return this.formModel;
      } else if (!isEmpty(this.workflowModel)) {
        return this.workflowModel;
      }
      return this.formModel;
    },
  },

  watch: {
    myInbox: {
      deep: true,
      immediate: true,
      handler() {
        if (this.myInbox) {
          this.hideActions.push("toggle");
        } else {
          this.hideActions = this.hideActions.filter(
            (item) => item != "toggle"
          );
        }
      },
    },

    workflowRequestList: {
      deep: true,
      immediate: true,
      handler() {
        this.getColumns();
        this.applyHideColumns();
        this.groupItemSelected = false;
        if (
          this.workflowRequestList.filter((process) => process.checked).length
        ) {
          this.groupItemSelected = true;
        }
      },
    },

    workflow: {
      deep: true,
      immediate: true,
      handler() {
        this.initiate = false;
        this.importExcel = false;
        if (this.workflow.id) {
          if (!this.myInbox) {
            this.clearFilterByColumns();
          }
          let wflowJson = JSON.parse(this.workflow.flowJson);
          let startBlock = wflowJson.blocks.find(
            (block) => block.type === "START"
          );
          if (startBlock) {
            if (
              startBlock.settings.initiateMode === "MANUAL" &&
              this.workflow.initiatedBy !== "DOCUMENT"
            ) {
              if (
                startBlock.settings.users.indexOf(
                  this.$store.state.session.id
                ) > -1 ||
                startBlock.settings.users.indexOf("0") > -1
              ) {
                this.initiate = true;
              }
              if (this.$store.state.session.groups) {
                let userGroupList = this.$store.state.session.groups;
                if (userGroupList.length) {
                  userGroupList.forEach((group) => {
                    if (startBlock.settings.groups.indexOf(group.id) > -1) {
                      this.initiate = true;
                    }
                  });
                }
              }
              if (startBlock.settings.initiateBy.indexOf("DOMAIN_USER") > -1) {
                let domain = this.$store.state.session.email.split("@").pop();
                if (startBlock.settings.userDomains.indexOf(domain) > -1) {
                  this.initiate = true;
                }
              }
            } else if (
              startBlock.settings.initiateMode === "MANUAL_AUTOMATIC"
            ) {
              if (
                startBlock.settings.users.indexOf(
                  this.$store.state.session.id
                ) > -1 ||
                startBlock.settings.users.indexOf("0") > -1
              ) {
                if (
                  startBlock.settings.initiateBy.indexOf("MASTER_FORM") > -1
                ) {
                  this.importExcel = true;
                  this.$emit(
                    "update:masterFormId",
                    startBlock.settings.masterFormId
                  );
                }
              }
            }
            this.generatePDF = startBlock.settings.generatePDF ? 1 : 0;
            this.documentRequired =
              startBlock.settings.documentRequired || false;
          }
          let origin = location.origin;
          if (
            origin === "https://edmsuat.sobhaapps.com" &&
            this.workflow.id === 55
          ) {
            this.initiate = false;
          }
        }
      },
    },

    wform: {
      deep: true,
      immediate: true,
      handler() {
        if (this.wform.formJson) {
          this.getColumns();
        }
      },
    },

    workflowFormModel: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowFormModel && this.saveAction) {
          if (this.initiateProcess) {
            this.checkRequiredDocument();
            //this.initiateRequest();
          } else if (this.groupActionFields.length) {
            this.groupProcessRequest();
          } else {
            this.checkRequiredDocument();
          }
        }
      },
    },

    // workflowRequestList: {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     //this.hasSelectedProcess = 0;
    //     this.workflowRequestListData = this.workflowRequestList;
    //   },
    // },

    fullView: {
      deep: true,
      immediate: true,
      handler() {
        if (this.fullView) {
          this.openProcess();
          this.getSubWorkflow();
        } else {
          this.closeProcessSheet();
        }
      },
    },

    selectedProcess: {
      immediate: true,
      handler() {
        if (this.selectedProcess.processId) {
          this.attachmentList = [];

          //this.loadProcessLinks();
        }
      },
    },

    activeView: {
      deep: true,
      immediate: true,
      handler() {
        if (this.activeView) {
          this.$emit("update:activeView", this.activeView);
        }
      },
    },

    currentPage: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("update:currentPage", this.currentPage);
        this.$emit("refresh", false);
      },
    },

    attachmentSheet: {
      immediate: true,
      handler() {
        if (this.attachmentSheet && this.selectedProcess.processId) {
          this.getAttachments();
          if (this.selectedProcess.flowStatus === "0") {
            if (this.attachmentList.length) {
              let index = this.attachmentList.length - 1;
              this.getFileData(this.attachmentList[index], "file");
            }
            this.attachmentLoad = setInterval(() => {
              this.getAttachments();
            }, 15000);
          }
        } else {
          clearInterval(this.attachmentLoad);
        }
      },
    },

    commentsSheet: {
      immediate: true,
      handler() {
        if (
          this.commentsSheet &&
          this.selectedProcess.processId &&
          this.selectedProcess.flowStatus === "0"
        ) {
          this.commentsLoad = setInterval(() => {
            this.getComments();
          }, 30000);
        } else {
          clearInterval(this.commentsLoad);
        }
      },
    },

    mailTransactionSheet: {
      immediate: true,
      handler() {
        if (this.mailTransactionSheet && this.selectedProcess.processId) {
          this.mailTransactionLoad = setInterval(() => {
            this.getMailTransactions();
          }, 30000);
        } else {
          clearInterval(this.mailTransactionLoad);
        }
      },
    },

    taskEntries: {
      immediate: true,
      handler() {
        this.tabs.find((tab) => {
          if (tab.value === "TASK") {
            tab.count = this.taskEntries.length;
          }
        });
      },
    },

    processSheet() {
      this.fileData = [];
      this.workflowModel = {};
      // if (this.selectedProcess.processId) {
      //   this.getAttachments();
      // }
    },

    dataValidationValues: {
      immediate: true,
      deep: true,
      handler() {
        this.customVerify = false;
        if (this.dataValidationValues.length) {
          let dataVerify = this.dataValidationValues.filter(
            (row) => row.status !== "Matched" && row.status !== "Verified"
          );
          if (dataVerify.length) {
            this.customVerify = true;
          }
        }
      },
    },

    customCloseProcessSheet: {
      deep: true,
      immediate: true,
      handler() {
        this.closeProcessSheet();
      },
    },

    sortBy: {
      deep: true,
      immediate: true,
      handler() {
        if (this.sortBy.criteria) {
          this.$emit("sortBy", this.sortBy);
        }
      },
    },

    subWorkflowId() {
      this.hasWflowInitiator = false;
      if (this.subWorkflowId) {
        let subWorkflow = this.workflowList.find(
          (workflow) => workflow.id == this.subWorkflowId
        );
        if (subWorkflow) {
          this.subWorkflowName = `Initiate - ${subWorkflow.name}`;
          this.hasWflowInitiator = false;
          let wflowJson = JSON.parse(subWorkflow.flowJson);
          let startBlock = wflowJson.blocks.find(
            (block) => block.type === "START"
          );

          if (startBlock) {
            if (
              startBlock.settings.initiateMode === "MANUAL" &&
              subWorkflow.initiatedBy !== "DOCUMENT"
            ) {
              if (
                startBlock.settings.users.indexOf(
                  this.$store.state.session.id
                ) > -1 ||
                startBlock.settings.users.indexOf("0") > -1
              ) {
                this.hasWflowInitiator = true;
              }

              if (startBlock.settings.initiateBy.indexOf("DOMAIN_USER") > -1) {
                let domain = this.$store.state.session.email.split("@").pop();

                if (startBlock.settings.userDomains.indexOf(domain) > -1) {
                  this.hasWflowInitiator = true;
                }
              }
            }
          }
        }
      }
    },

    // saveAction: {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     if (this.btnAction) {
    //       if (this.saveAction) {
    //         this.$store.commit("showLoadingBarSave");
    //       } else {
    //         this.$store.commit("hideLoadingBarSave");
    //       }
    //     }
    //   },
    // },
    exporting: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.exporting) {
          await this.exportRequestData();
        }
      },
    },
  },

  created() {
    localStorage.removeItem("viewerClose");
    localStorage.removeItem("signItemId");
    this.getAppConnection();
    let origin = location.origin;
    if (
      origin === "https://edmsuat.sobhaapps.com" ||
      origin === "https://edms.sobhaapps.com"
    ) {
      this.hideActions.push("activeView");
    }
  },

  methods: {
    closeQR(id) {
      this.showQr = false;
      this.QRexpired(id);
    },

    checkMobileUpload(id) {
      console.log(id);
      this.uploadInterval = setInterval(() => {
        this.watchMobileUpload(id);
      }, 5000);
    },

    async QRexpired(id, timeOut) {
      if (!timeOut) {
        this.showQr = false;
      }
      const { payload } = await repository.getQrFileUploadById(id);
      console.log(payload, "payload1");
      let jsonData = JSON.parse(payload.jsonData);
      if (!payload.status) {
        let parsedJsonData = jsonData[0];
        parsedJsonData = { ...parsedJsonData, expired: true };

        let input = {
          id: id,
          url: payload.url,
          jsonData: JSON.stringify([parsedJsonData]),
          status: 0,
        };
        const { error } = await repository.updateJson(id, input);

        if (error) {
          return;
        }
        clearInterval(this.uploadInterval);
        this.uploadInterval = null;
        if (this.processSheet) {
          this.$alert.info(`QR expired`);
        }
      }
    },

    async watchMobileUpload(id) {
      const { error, payload } = await repository.getQrFileUploadById(id);
      console.log(error, payload, "error, payload");

      let jsonData = JSON.parse(payload.jsonData);
      console.log(jsonData, "jsonData", "payload.status", payload.status);
      if (payload.status) {
        clearInterval(this.uploadInterval);
        this.uploadInterval = null; //
        let parsedJsonData = jsonData[0];
        this.mobileUploadFileJson = parsedJsonData;

        if (
          this.repositoryDetails.fieldsType === "STATIC" &&
          this.workflow.initiatedBy === "FORM"
        ) {
          let label = parsedJsonData.attachmentList[0].name;
          this.uploadFilename = label;

          //rename higher level name
          const highestLevelObject = this.repositoryField.reduce(
            (prev, curr) => (curr.level > prev.level ? curr : prev),
            this.repositoryField[0]
          );

          const highestLevelId = highestLevelObject.id;

          if (this.attachmentList.length) {
            let index = this.attachmentList.length - 1;
            await this.getFileData(this.attachmentList[index], "file");
          }

          const fileDataItem = this.fileData.find(
            (item) => highestLevelId == item.id
          );
          const newObjectToPush = {
            id: highestLevelObject.id,
            name: highestLevelObject.name,
            value: label,
            type: highestLevelObject.dataType,
          };
          if (!this.fileData.length) {
            this.fileData.push(newObjectToPush);
          } else {
            fileDataItem.value = label;
          }
          // console.log(this.attachmentList,"files, control, fieldId, aiSettings hasfiel")
          // console.log(label,"files, control, fieldId, aiSettings hasfiel")
          // console.log(this.fileData,"files, control, fieldId, aiSettings hasfiel")

          // check all mandatory filled
          const mandatoryItems = this.repositoryField
            .filter((item) => item.isMandatory === true)
            .map((item) => item.id);

          const allMandatoryFilled = mandatoryItems.every((mandatoryId) => {
            const fileItem = this.fileData.find(
              (item) => item.id === mandatoryId
            );
            return fileItem && fileItem.value !== "";
          });

          this.showMetaDataEditModal = !allMandatoryFilled;

          if (allMandatoryFilled) {
            const newObject = {};

            this.fileData.forEach((item) => {
              newObject[item.name] = item.value !== "" ? item.value : "";
            });

            this.fileUploadForControl = true;
            this.saveMetaData(newObject);
          } else {
            this.fileUploadForControl = true;
            this.showMetaDataEditModal = true;
          }
        } else {
          let updatedData = {
            name: parsedJsonData.attachmentList[0].name,
            size: parsedJsonData.attachmentList[0].size,
            file: "",
            fileId: parsedJsonData.attachmentList[0].id,
            id: parsedJsonData.attachmentList[0].id,
            fileControl: true,
            formAttach: true,
            createdBy: parsedJsonData.attachmentList[0].createdByEmail,
            createdAt: this.$day.format(
              parsedJsonData.attachmentList[0].createdAt
            ),
            workflowId: this.workflow.id,
            processId: this.selectedProcess.processId,
            fieldId: parsedJsonData.fieldId,
            fileDataValidate: "",
          };
          this.attachmentList.push(updatedData);
          // this.formUploadFiles.push(updatedData);

          let workflowModelValue = {
            name: parsedJsonData.attachmentList[0].name,
            size: parsedJsonData.attachmentList[0].size,
            file: "",
            fileId: parsedJsonData.attachmentList[0].id,
            uploadedPercentage: 100,
            createdBy: parsedJsonData.attachmentList[0].createdByEmail,
            createdAt: this.$day.format(
              parsedJsonData.attachmentList[0].createdAt
            ),
          };

          this.formFields.forEach((field) => {
            if (
              field.type === "FILE_UPLOAD" &&
              this.workflowModel[field.value] &&
              field.value == parsedJsonData.fieldId
            ) {
              this.workflowModel[field.value].push(workflowModelValue);
              // console.log( this.workflowModel[field.value],"this.workflowModel[field.value]")
            }
          });

          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
        }

        // this.addFileInAttachment();
        this.showQr = false;
        this.QRFileUploaded = true;

        // console.log(updatedData, "updatedData");
      } else {
        console.log("Waiting for uploaded");
        // console.log(this.processSheet,"processSheet")
        if (!this.processSheet) {
          clearInterval(this.uploadInterval);
          this.uploadInterval = null; //
        }
      }
      // this.repositoryId = jsonData[0].repositoryId;
      // this.url =  jsonData[0].url;
    },

    async addFileInAttachment() {
      let parsedJsonData = this.mobileUploadFileJson;
      let input = [
        {
          id: parsedJsonData.attachmentList[0].id,
          status: "NOT_INDEXED",
          OCRPages: [1],
          OCRType: "MANUAL",
          OCRTool: "",
          OCRPattern: false,
        },
      ];
      console.log(input, "input");
      console.log("metaDataModal", this.metaDataModal);

      let felds = [];

      Object.keys(this.metaDataModal).forEach((key) => {
        console.log(`Key: ${key}, Value: ${this.metaDataModal[key]}`);

        this.repositoryField.forEach((obj) => {
          Object.keys(obj).forEach((repoKey) => {
            // Check if repoKey is "name" and the value matches with metaDataModal[key]

            if (repoKey == "name" && obj[repoKey] == key) {
              console.log(`Key: ${repoKey}, Value: ${obj[repoKey]}`);

              // Create a new object with name, value, and type (dataType)
              let newObj = {
                name: key,
                value: this.metaDataModal[key],
                type: obj["dataType"], // Fixed accessing dataType
              };

              felds.push(newObj); // Push the new object to felds array
            }
          });
        });
      });

      console.log(felds);
      this.showMetaDataEditModal = true;
      const { error, payload } = await uploadAndIndex.uploadSetStatus(input);
      if (error) {
        console.error(error);
        return;
      } else {
        let value = parseInt(JSON.parse(payload)[0]);

        console.log(value, "payload");
        let exportId = value;
        console.log(exportId, "exportId");
        let fileInput = {
          repositoryId: this.workflow.repositoryId,
          itemId: 0,
          status: "INDEXED",
          fields: felds,
        };
        if (fileInput) {
          console.log(fileInput, "fileInput");
          const { error, payload } = await uploadAndIndex.updateFile(
            exportId,
            fileInput
          );
          if (error) {
            console.error(error);
            return;
          } else {
            console.log(payload);
            console.log(parsedJsonData, "file");
            let updatedData = {
              name: parsedJsonData.attachmentList[0].name,
              size: parsedJsonData.attachmentList[0].size,
              file: "",
              fileId: exportId,
              id: exportId,
              fileControl: true,
              formAttach: true,
              createdBy: parsedJsonData.attachmentList[0].createdByEmail,
              createdAt: this.$day.format(
                parsedJsonData.attachmentList[0].createdAt
              ),
              workflowId: this.workflow.id,
              processId: this.selectedProcess.processId,
              fieldId: parsedJsonData.fieldId,
              fileDataValidate: "",
            };
            this.attachmentList.push(updatedData);
            // this.formUploadFiles.push(updatedData);

            let workflowModelValue = {
              name: parsedJsonData.attachmentList[0].name,
              size: parsedJsonData.attachmentList[0].size,
              file: "",
              fileId: exportId,
              uploadedPercentage: 100,
              createdBy: parsedJsonData.attachmentList[0].createdByEmail,
              createdAt: this.$day.format(
                parsedJsonData.attachmentList[0].createdAt
              ),
            };

            this.formFields.forEach((field) => {
              if (
                field.type === "FILE_UPLOAD" &&
                this.workflowModel[field.value] &&
                field.value == parsedJsonData.fieldId
              ) {
                this.workflowModel[field.value].push(workflowModelValue);
                // console.log( this.workflowModel[field.value],"this.workflowModel[field.value]")
              }
            });

            this.tabs.find((tab) => {
              if (tab.value === "ATTACHMENTS") {
                tab.count = this.attachmentList.length;
              }
            });
          }
        }
      }
    },

    async generateQrCode(fieldId) {
      this.selectedFieldId = fieldId;
      let name = await this.getLabel(fieldId);
      this.selectedFieldName = name;
      this.showQr = true;
      this.QRFileUploaded = false;
    },
    checkTabAccess(value) {
      if (value === "TASK") {
        return this.addTaskEnabled;
      } else if (value === "VALIDATION") {
        if (this.isCustomizedWorkflow) {
          return true;
        } else {
          return false;
        }
      } else if (value === "FORM_ENTRY_HISTORY") {
        if (this.formId) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },

    clearAttach() {
      this.selectedFileForAttach = 0;
      this.attachComment = false;
      this.notifyInitiator = false;
    },

    customCheckUpload() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" &&
        (this.workflow.id === 55 || this.workflow.id === 2092)
      ) {
        if (this.attachmentList.length === 0) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },

    checkTenant() {
      if (this.isTenantArmgroup()) {
        this.commentAction = 2;
        return false;
      }
      return true;
    },

    isTenantGOTO() {
      let origin = location.origin;
      if (
        (origin === "https://app.ezofis.com1" ||
          origin === "https://appuat.ezofis.com") &&
        this.$store.state.session.tenantId === 2
      ) {
        return true;
      }
      return false;
    },

    isTenantArmgroup() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12) ||
        origin === "http://localhost:8080"
      ) {
        return true;
      }
      return false;
    },

    isTenantGOTOComments() {
      if (Object.keys(this.selectedProcess).length) {
        if (this.isTenantGOTO() && this.selectedProcess.jiraIssueInfo.id) {
          return true;
        }
      }

      return false;
    },

    checkCommentsOption() {
      if (this.isTenantArmgroup()) {
        return false;
      } else if (this.isTenantGOTO()) {
        if (Object.keys(this.selectedProcess).length) {
          if (this.selectedProcess.jiraIssueInfo.id) {
            return true;
          }
        }
        return false;
      }
      return true;
    },

    toggleWidth() {
      this.formWidth = this.formWidth === "100vw" ? "50vw" : "100vw";
    },

    closeProcessSheet(forceRefresh) {
      if (this.isCustomizedWorkflow) {
        if (
          this.selectedProcess.ticketLockedBy === this.$store.state.session.id
        ) {
          this.$emit("release", this.selectedProcess);
        }
      }
      this.processSheet = false;
      this.saveAction = "";
      this.workflowFormModel = {};
      this.subProcessData = {};
      this.subProcessTickets = [];
      this.$emit("update:fullView", false);
      if (this.isCustomizedWorkflow && forceRefresh) {
        this.$emit("refresh", this.workflow.id);
      } else if (this.refreshAction) {
        this.$emit("refresh", this.workflow.id);
      }
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },

    closeMetaDataEditModal() {
      this.showMetaDataEditModal = false;
      if (this.$refs.attachments) {
        this.$refs.attachments.value = null;
      }
    },

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    closeTaskSheet() {
      this.taskSheet = false;
    },

    closeJiraIssueSheet() {
      this.issueName = "";
      this.projectKey = "";
      this.issueType = "";
      this.issueDescription = "";
      this.issueEpicValue = "";
      this.issueAssignee = "";
      this.issueDescriptionTable = false;
      this.issueDescriptionFields = [];
      this.jiraIssueSheet = false;
    },

    async loadProcessLinks() {
      this.getComments();
      await this.getAttachments();
      if (
        this.isTenantArmgroup() ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12)
      ) {
        this.formCheckList = true;
      }
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = this.selectedProcess.commentsCount;
        }
      });
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = this.selectedProcess.attachmentCount;
        }
      });
      this.getProcessSignature();
      this.getMailTransactions();
    },

    updateDataValidationValues(value) {
      this.$emit("update:dataValidationValues", value);
    },

    userInitials(userDet) {
      let initials = "";
      if (userDet.firstName) {
        initials += userDet.firstName[0].toUpperCase();
      }
      if (userDet.lastName) {
        initials += userDet.lastName[0].toUpperCase();
      } else {
        initials += userDet.firstName[1];
      }
      return initials;
    },

    async showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        // this.attachmentSheet = true;
        this.showAttachmentSheet = true;
      } else if (tab === "COMMENTS") {
        this.fileNames = [];
        this.fileNames = this.attachmentList
          .filter((item) => {
            let extension = item.name.split(".").pop().toUpperCase();

            return extension.toLowerCase() === "pdf";
          })
          .map((item) => {
            return {
              id: this.$nano.id(),
              label: item.name,
              value: item.id,
            };
          });
        // this.commentsSheet = true;
        this.showCommentsSheet = true;
      } else if (tab === "HISTORY" && this.selectedProcess.processId) {
        this.$emit("history", this.selectedProcess.processId);
      } else if (tab === "PRINT") {
        if (this.selectedProcess.formData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/form/generatePdfPrint/${this.$store.state.session.tenantId}/${this.workflow.id}/${this.selectedProcess.processId}/${this.formId}/${this.selectedProcess.formData.formEntryId}/1`
          );
        } else if (this.selectedProcess.itemData) {
          window.open(
            `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedProcess.itemData.repositoryId}/${this.selectedProcess.itemData.itemId}/2`
          );
        }
        //window.print();
      } else if (tab === "TASK") {
        if (this.taskEntries.length) {
          this.taskSheet = true;
        } else {
          this.$emit("addTask");
        }
      } else if (tab === "VALIDATION") {
        this.$emit("validation", this.selectedProcess.processId);
      } else if (tab === "MAIL") {
        this.getMailTransactions();
        this.mailTransactionSheet = true;
      } else if (tab === "SUB_PROCESS") {
        this.subProcessSheet = true;
        await this.getSubWorkflow();
      } else if (tab === "FORM_ENTRY_HISTORY") {
        this.$emit("showFormEntryHistory");
      }
    },

    lastActionDuration(request) {
      const date =
        request.transaction_createdAt instanceof Date
          ? request.transaction_createdAt
          : new Date(request.transaction_createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    sublastActionDuration(request) {
      const date =
        request.raisedAt instanceof Date
          ? request.raisedAt
          : new Date(request.raisedAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    validateTypeValue(value) {
      if (value) {
        try {
          var jsonConvert = JSON.parse(value);
          if (typeof jsonConvert === "object") {
            return jsonConvert;
          }
        } catch (e) {
          return value;
        }
      }
      return value;
    },

    validateType(key) {
      let fieldType = "";
      this.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        let formField = panel.fields.find((field) => field.id === key);
        if (formField) {
          fieldType = formField.type;
        }
      });
      return fieldType;
    },
    validateValue(value) {
      let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return !urlPattern.test(value);
    },
    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    tableValue(value) {
      if (value) {
        // console.log(JSON.parse(value));
        return JSON.parse(value);
      }
      return [];
    },

    tableColumns(value, key) {
      // console.log(key);
      let field = this.formFields.find((column) => column.value === key);
      let tblColumns = [];
      // console.log(field);
      field.settings.specific.tableColumns.forEach((col) => {
        if (col.settings.general.visibility !== "DISABLE") {
          tblColumns.push(col);
        }
      });
      let columns = [];
      let _tblColumns = [];

      for (let key1 in this.tableValue(value)[0]) {
        columns.push(key1);
      }

      tblColumns.forEach((col) => {
        // console.log(columns.indexOf(col.id));
        if (columns.indexOf(col.id) > -1) {
          _tblColumns.push(col);
        }
      });

      return _tblColumns;
    },

    groupRequestList(group) {
      return this.workflowRequestList.filter((row) => row.key === group);
    },

    groupRequestListName(group) {
      const matchingRows = this.workflowRequestList.filter(
        (row) => row.key === group
      );
      if (
        matchingRows.length > 0 &&
        matchingRows.some((row) => row.visible === true)
      ) {
        return true;
      }
      return false;
    },

    groupSelected(group) {
      let groupList = this.workflowRequestList.filter(
        (row) => row.key === group
      );
      let count = 0;
      groupList.forEach((process) => {
        if (process.checked) {
          count++;
        }
      });
      return count > 1 ? true : false;
    },

    showRequestPreview(processId, workflowId) {
      this.hasSelectedProcess = processId;
      this.hasSelectedWorkflow = workflowId;
      //this.getModel(processId);
      this.$emit("show", processId, workflowId);
    },

    showSubRequestPreview(item) {
      item.id = item.subWorkFlowId;
      this.hasSelectedProcess = item.processId;
      this.hasSelectedWorkflow = item.subWorkFlowId;
      this.selectedSubWorkflow = item;
      this.subWorkflowId = item.subWorkFlowId;
      this.openSubProcessSheet = true;
      this.subProcessData = item;
    },

    getJIRALink(link) {
      let url = new URL(link.self);
      let origin = url.origin.replace(
        "https://jira-external-tools-integration.getgotools.net",
        "https://jira.ops.expertcity.com"
      );
      return `${origin}/browse/${link.key}`;
    },

    closeSubProcessSheet() {
      this.subProcessSheet = false;
    },

    async showRequest(processId, workflowId) {
      this.hasSelectedProcess = processId;
      this.hasSelectedWorkflow = workflowId;
      this.$emit("show", processId, workflowId, true);
    },

    getModel(processId) {
      this.formModel = {};
      this.itemModel = {};
      if (Object.keys(this.selectedProcess).length === 0) {
        this.workflowRequestList.forEach((process) => {
          if (
            process.processId == processId &&
            process.id == this.workflow.id
          ) {
            this.headerTitle = `${process.requestNo} - ${process.stage}`;
            if (process.formData) {
              let keys = Object.keys(process.formData.fields);
              let newValues = {};
              keys.forEach((key) => {
                newValues[key] = this.validateTypeValue(
                  process.formData.fields[key]
                );
              });
              this.formModel = newValues;
            } else if (process.itemData) {
              this.itemModel = process.itemData;
            }
          }
        });
      } else {
        let process = this.selectedProcess;
        this.headerTitle = `${process.requestNo} - ${process.stage}`;
        if (process.formData) {
          let keys = Object.keys(process.formData.fields);
          let newValues = {};
          keys.forEach((key) => {
            newValues[key] = this.validateTypeValue(
              process.formData.fields[key]
            );
          });
          this.formModel = newValues;
        } else if (process.itemData) {
          this.itemModel = process.itemData;
        }
      }
    },

    loadMore(e) {
      if (
        this.totalItems > this.itemsPerPage &&
        this.workflowRequestList.length >= this.currentPage * this.itemsPerPage
      ) {
        let currentScrollPosition = e.srcElement.scrollTop;
        if (currentScrollPosition > this.scrollPosition) {
          this.$emit("update:currentPage", this.currentPage + 1);
        }
        this.scrollPosition = currentScrollPosition;
      }
    },

    async openProcess(initiate = false) {
      this.btnAction = false;
      this.actions = [];
      this.form = {};
      this.formId = "";
      this.isRequestLocked = false;
      this.displayInternalPublic = false;
      this.getRepository();
      this.ocrResult = [];
      this.subWorkflowId = 0;
      this.attachmentList = [];
      this.commentsList = [];
      if (initiate) {
        this.tabs.find((tab) => {
          tab.count = 0;
        });
        this.$emit("update:selectedProcess", {});
        this.$emit("update:taskEntries", []);
        this.commentsList = [];
        this.attachmentList = [];
        this.initUserSignature = false;
        this.initiateProcess = true;
        this.formModel = {};
        this.signList = [];
        this.formEditAccess = false;
        this.formEditControls = [];
        this.readonlyControls = [];
        this.formVisibilityAccess = true;
        this.formSecureControls = [];
        let wflowJson = JSON.parse(this.workflow.flowJson);
        let startBlock = wflowJson.blocks.find(
          (block) => block.type === "START"
        );
        if (startBlock) {
          if (startBlock.settings.initiateMode === "MANUAL") {
            this.headerTitle = `${startBlock.settings.label}`;
            let rules = wflowJson.rules.find(
              (rule) => rule.fromBlockId === startBlock.id
            );
            if (rules) {
              this.actions.push(rules.proceedAction);
            }
          }
          if (startBlock.settings.formEditAccess) {
            if (startBlock.settings.formEditAccess === "") {
              this.formEditAccess = true;
              this.formEditControls = [];
            } else if (startBlock.settings.formEditAccess === "FULL") {
              this.formEditAccess = false;
              this.formEditControls = [];
            } else if (startBlock.settings.formEditAccess === "PARTIAL") {
              this.formEditAccess = false;
              this.formEditControls = startBlock.settings.formEditControls;
            }
          }
          if (startBlock.settings.formVisibilityAccess) {
            this.formVisibilityAccess = true;
            this.formSecureControls = [];
          } else {
            this.formVisibilityAccess = false;
            this.formSecureControls = startBlock.settings.formSecureControls;
          }
          this.initUserSignature = startBlock.settings.userSignature;
          if (startBlock.settings.fileSettings) {
            if (startBlock.settings.fileSettings.fileCheckList.length) {
              let documentCheckList =
                startBlock.settings.fileSettings.fileCheckList;
              documentCheckList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
              });
              this.docWarning = startBlock.settings.fileSettings.partial;
              // if (documentCheckList.length) {
              //   documentCheckList.forEach((row) => {
              //     if (row.required && !row.attach) {
              //       this.docWarning = true;
              //     }
              //   });
              // }
              this.$emit("update:checkList", documentCheckList);
            }
          } else {
            this.$emit("update:checkList", []);
          }

          if (
            startBlock.settings.initiateBy.indexOf("PORTAL") > -1 ||
            this.portalAction
          ) {
            this.displayInternalPublic = true;
          }
          if (startBlock.settings.workflowInitiator) {
            this.subWorkflowId = startBlock.settings.subWorkflowId;
          }
        }
      } else {
        // this.showHistoryStepper(
        //   this.selectedProcess.processId,
        //   this.selectedProcess.id
        // );
        if (
          this.selectedNode === "inbox" ||
          this.selectedNode === "common" ||
          this.selectedNode === "payment"
        ) {
          this.formEditAccess = false;
          let wflowJson = JSON.parse(this.workflow.flowJson);

          let actionBlock = wflowJson.blocks.find(
            (block) => block.id === this.selectedProcess.activityId
          );
          if (actionBlock) {
            if (actionBlock.settings.formEditAccess === "") {
              this.formEditAccess = true;
              this.formEditControls = [];
            } else if (actionBlock.settings.formEditAccess === "FULL") {
              this.formEditAccess = false;
              this.formEditControls = [];
            } else if (actionBlock.settings.formEditAccess === "PARTIAL") {
              this.formEditAccess = false;
              this.formEditControls = actionBlock.settings.formEditControls;
            }
            if (actionBlock.settings.formVisibilityAccess) {
              this.formVisibilityAccess = true;
              this.formSecureControls = [];
            } else {
              this.formVisibilityAccess = false;
              this.formSecureControls = actionBlock.settings.formSecureControls;
            }

            if (
              wflowJson.settings.general.processNumberPrefix.indexOf("[") > -1
            ) {
              let requestNumberFormat = JSON.parse(
                wflowJson.settings.general.processNumberPrefix
              );
              this.readonlyControls = [];
              let requestColumn = requestNumberFormat.filter(
                (row) => row.key === "formColumn"
              );
              if (requestColumn.length) {
                requestColumn.forEach((row) => {
                  this.readonlyControls.push(row.value);
                  if (this.formEditControls.indexOf(row.value) > -1) {
                    this.formEditControls.splice(
                      this.formEditControls.indexOf(row.value),
                      1
                    );
                  }
                });
              }
            }

            if (actionBlock.settings.fileSettings) {
              if (actionBlock.settings.fileSettings.fileCheckList.length) {
                let documentCheckList =
                  actionBlock.settings.fileSettings.fileCheckList;
                documentCheckList.forEach((row) => {
                  row.uid = this.$nano.id();
                  row.attach = false;
                });
                this.docWarning = actionBlock.settings.fileSettings.partial;
                // if (documentCheckList.length) {
                //   documentCheckList.forEach((row) => {
                //     if (row.required && !row.attach) {
                //       this.docWarning = true;
                //     }
                //   });
                // }
                this.$emit("update:checkList", documentCheckList);
              }
            } else {
              this.$emit("update:checkList", []);
            }

            if (actionBlock.settings.workflowInitiator) {
              this.subWorkflowId = actionBlock.settings.subWorkflowId;
            }

            this.displayInternalPublic = false;
            let startBlock = wflowJson.blocks.find(
              (block) => block.type === "START"
            );
            if (startBlock) {
              if (
                startBlock.settings.initiateBy.indexOf("PORTAL") > -1 ||
                this.portalAction
              ) {
                this.displayInternalPublic = true;
              }
            }
          }
          if (this.isCustomizedWorkflow) {
            if (
              this.selectedNode === "common" &&
              this.selectedProcess.activityId === "szS6B-q1x9acpB83rP6jT"
            ) {
              if (this.$store.state.session.groups) {
                let userGroupList = this.$store.state.session.groups;
                if (userGroupList.length) {
                  let assigner = userGroupList.find((group) => group.id === 3);
                  if (assigner) {
                    await this.checkTicketLock();
                  }
                }
              }
            } else {
              await this.checkTicketLock();
            }
          }
        } else {
          this.formEditAccess = true;
        }
        this.initiateProcess = false;
        this.getModel(this.hasSelectedProcess);
        this.actions = this.processActions;
        let origin = location.origin;
        if (
          this.isTenantArmgroup() ||
          (origin === "https://trial.ezofis.com" &&
            this.$store.state.session.tenantId === 12)
        ) {
          this.$emit("validation", this.hasSelectedProcess, false);
        }

        if (
          origin === "https://edmsuat.sobhaapps.com" &&
          this.workflow.id === 55
        ) {
          this.formEditAccess = true;
        }
        this.loadProcessLinks();
      }
      if (this.workflow.wFormId) {
        this.formId = String(this.workflow.wFormId);
        this.form = JSON.parse(this.wform.formJson);
        if (!initiate) {
          if (this.form.settings.general.layout === "CARD") {
            this.form.settings.general.layout = "TAB";
          }
        }
      }
      this.refreshAction = false;
      this.processSheet = true;
    },

    validateRequest(action) {
      this.btnAction = true;
      this.saveAction = action;
      if (this.itemModel.itemId) {
        this.processRequest();
      }
    },

    updateSelection(request) {
      this.workflowRequestList.forEach((process) => {
        if (process.processId === request.processId) {
          process.checked = !request.checked;
        }
      });

      this.$emit("update:workflowRequestList", this.workflowRequestList);
    },

    updateStarred(request) {
      this.workflowRequestList.forEach((process) => {
        if (process.processId === request.processId) {
          process.starred = !request.starred;
        }
      });
      this.$emit("update:workflowRequestList", this.workflowRequestList);
    },

    updateSelectionGroup(group) {
      this.groupKey.forEach((row) => {
        if (row.name === group.name) {
          row.checked = !group.checked;
          this.workflowRequestList.forEach((process) => {
            if (process.key === group.name) {
              process.checked = group.checked;
            }
          });
          this.$emit("update:workflowRequestList", this.workflowRequestList);
        }
      });
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    groupRequestAction(action, group) {
      if (group.length) {
        if (group.actionFields.length) {
          this.groupActionKey = group.name;
          this.groupActionFields = group.actionFields;
          this.groupActionModalButton = action;
          this.requestActionModal = true;
          this.formId = String(this.workflow.wFormId);
          this.form = JSON.parse(this.wform.formJson);
        } else if (action.name === "Forward" && this.internalForward.proceed) {
          this.groupActionKey = group.name;
          this.saveAction = action.name;
          this.processForwardGroupAction = true;
          this.processForwardModal = true;
        } else if (
          this.isTenantGOTO() &&
          action.name === "Assign" &&
          this.internalForward.proceed
        ) {
          this.groupActionKey = group.name;
          this.saveAction = action.name;
          this.processForwardGroupAction = true;
          this.processForwardModal = true;
        } else if (this.isTenantArmgroup() && action.name === "Assign") {
          this.saveAction = action.name;
          this.processForwardGroupAction = true;
          this.processForwardModal = true;
        } else {
          this.saveAction = action.name;
          this.groupProcessRequest();
        }
      } else if (this.isTenantArmgroup() && action === "Assign") {
        this.saveAction = action;
        this.processForwardGroupAction = true;
        this.processForwardModal = true;
      }
    },

    closeRequestActionModal() {
      this.requestActionModal = false;
      this.groupActionKey = "";
      this.groupActionFields = [];
      this.groupActionModalButton = {};
    },

    submitGroupAction() {
      this.saveAction = this.groupActionModalButton.name;
    },

    searchList() {
      if (this.search) {
        this.workflowRequestList.forEach((process) => {
          if (
            process.requestNo.toLowerCase().indexOf(this.search.toLowerCase()) >
            -1
          ) {
            process.visible = true;
          } else if (
            process.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          ) {
            process.visible = true;
          } else if (
            process.key.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          ) {
            process.visible = true;
          } else {
            process.visible = false;
          }
        });
      } else {
        this.workflowRequestList.forEach((process) => {
          process.visible = true;
        });
      }
    },

    searchRow(search) {
      if (search) {
        this.workflowRequestList.forEach((process) => {
          process.visible = false;
        });
        this.workflowRequestList.forEach((row) => {
          for (let cell in row) {
            let index = this.columns.findIndex((column) => {
              return column.name === cell;
            });
            if (index >= 0 && row[cell]) {
              if (
                String(row[cell]).toLowerCase().includes(search.toLowerCase())
              ) {
                row.visible = true;
                return;
              }
            }
            //if (cell === "key") {
            if (typeof row[cell] === "object") {
              if (
                JSON.stringify(row[cell])
                  .toLowerCase()
                  .includes(search.toLowerCase())
              ) {
                row.visible = true;
                return;
              }
            } else {
              if (
                String(row[cell]).toLowerCase().includes(search.toLowerCase())
              ) {
                row.visible = true;
                return;
              }
            }
            //}
          }
        });
      } else {
        this.workflowRequestList.forEach((process) => {
          process.visible = true;
        });
      }
    },

    getRequest() {
      if (this.currentPage === 1) {
        this.$emit("refresh");
      } else {
        this.$emit("update:currentPage", 1);
      }
    },

    getColumns() {
      this.formFields = [];
      let origin = location.origin;
      this.columns = [
        {
          id: "requestNo",
          name: "requestNo",
          label: "Request No",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          dataType: DataType.SHORT_TEXT,
        },
      ];
      if (this.isTenantGOTO()) {
        this.columns.push({
          id: "mlCondition",
          name: "mlCondition",
          label: "",
          type: "MLCONDITION",
          isVisible: true,
          dataType: DataType.SHORT_TEXT,
          isFilter: false,
        });
      }
      if (
        this.isTenantArmgroup() ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12)
      ) {
        this.columns.push({
          id: "mlCondition",
          name: "mlCondition",
          label: "",
          type: "MLCONDITION",
          isVisible: true,
          dataType: DataType.SHORT_TEXT,
          isFilter: false,
        });
      }
      let defaultColumns = [
        {
          id: "stage",
          name: "stage",
          label: "Stage",
          type: "NORMAL",
          isVisible: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: "actionUser",
          name: "actionUser",
          label: "Action User",
          type: "NORMAL",
          options: this.userList,
          isVisible: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: "raisedBy",
          name: "raisedBy",
          label: "Raised By",
          type: "NORMAL",
          options: this.userList,
          isVisible: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: "raisedAt",
          name: "raisedAt",
          label: "Raised On",
          type: "NORMAL",
          isVisible: true,
          isSortable: true,
          dataType: DataType.DATE,
        },
        {
          id: "lastAction",
          name: "lastAction",
          label: "Last Action",
          type: "NORMAL",
          isVisible: true,
          dataType: DataType.SHORT_TEXT,
        },
        {
          id: "lastActionBy",
          name: "lastActionBy",
          label: "Last Action By",
          type: "NORMAL",
          options: this.userList,
          isVisible: true,
          dataType: DataType.SINGLE_SELECT,
        },
        {
          id: "lastActionOn",
          name: "lastActionOn",
          label: "Last Action On",
          type: "NORMAL",
          isVisible: true,
          dataType: DataType.DATE,
        },
        {
          id: "duration",
          name: "duration",
          label: "Duration",
          type: "NORMAL",
          isVisible: true,
          dataType: DataType.SHORT_TEXT,
        },
      ];
      if (
        this.isTenantArmgroup() ||
        origin === "http://localhost:80801" ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12)
      ) {
        if (this.workflow.wFormId && this.wform.formJson) {
          let formJson = JSON.parse(this.wform.formJson);
          const panels = [...formJson.panels, ...formJson.secondaryPanels];

          if (!panels.length) {
            return;
          }
          let fields = [];
          for (const panel of panels) {
            fields.push(...panel.fields);
          }
          let initialsField = fields.find((row) => row.label === "Initials");
          if (initialsField) {
            this.columns.push({
              id: initialsField.id,
              name: initialsField.id,
              label: initialsField.label,
              type: "NORMAL",
              isVisible: true,
              dataType: initialsField.type,
              apiLoad: true,
              isFilter: true,
            });
          }
          let benefitClaimField = fields.find(
            (row) => row.label === "Benefit Claimed"
          );
          if (benefitClaimField) {
            this.columns.push({
              id: benefitClaimField.id,
              name: benefitClaimField.id,
              label: benefitClaimField.label,
              type: "NORMAL",
              isVisible: true,
              dataType: benefitClaimField.type,
              apiLoad: true,
              isFilter: true,
            });
          }
        }
        this.columns.push(
          {
            id: "stage",
            name: "stage",
            label: "Stage",
            type: "NORMAL",
            isVisible: true,
            dataType: DataType.SHORT_TEXT,
          },
          {
            id: "actionUser",
            name: "actionUser",
            label: "Action User",
            type: "NORMAL",
            options: this.userList,
            isVisible: true,
            dataType: DataType.SINGLE_SELECT,
          },

          {
            id: "lastAction",
            name: "lastAction",
            label: "Last Action",
            type: "NORMAL",
            isVisible: true,
            dataType: DataType.SHORT_TEXT,
          },
          {
            id: "lastActionBy",
            name: "lastActionBy",
            label: "Last Action By",
            type: "NORMAL",
            options: this.userList,
            isVisible: true,
            dataType: DataType.SINGLE_SELECT,
          },
          {
            id: "lastActionOn",
            name: "lastActionOn",
            label: "Last Action On",
            type: "NORMAL",
            isVisible: true,
            dataType: DataType.DATE,
          },
          {
            id: "duration",
            name: "duration",
            label: "Duration",
            type: "NORMAL",
            isVisible: true,
            dataType: DataType.SHORT_TEXT,
          },
          {
            id: "raisedBy",
            name: "raisedBy",
            label: "Raised By",
            type: "NORMAL",
            options: this.userList,
            isVisible: true,
            dataType: DataType.SINGLE_SELECT,
          },
          {
            id: "raisedAt",
            name: "raisedAt",
            label: "Raised On",
            type: "NORMAL",
            isVisible: true,
            dataType: DataType.DATE,
          }
        );
      } else {
        this.columns.push(...defaultColumns);
      }
      if (this.workflow.wFormId && this.wform.formJson) {
        let formJson = JSON.parse(this.wform.formJson);
        const panels = [...formJson.panels, ...formJson.secondaryPanels];

        if (!panels.length) {
          return;
        }
        let fields = [];
        for (const panel of panels) {
          fields.push(...panel.fields);
        }

        let wflowJson = JSON.parse(this.workflow.flowJson);
        let secureControls = [];
        const controls = wflowJson.blocks.reduce((acc, control) => {
          if (
            control.settings.formSecureControls &&
            control.settings.formSecureControls.length
          ) {
            if (typeof control.settings.formSecureControls[0] === "string") {
              control.settings.users.forEach((user) => {
                acc.push({ [user]: control.settings.formSecureControls });
              });
            } else {
              control.settings.formSecureControls.forEach((user) => {
                if (user.formFields.length) {
                  acc.push({ [user.userId]: user.formFields });
                }
              });
            }
          }
          return acc;
        }, []);
        controls.forEach((user) => {
          if (user[this.$store.state.session.id]) {
            secureControls.push(...user[this.$store.state.session.id]);
          }
        });

        fields.forEach((field) => {
          if (
            field.type !== "DIVIDER" &&
            field.type !== "LABEL" &&
            field.type !== "PARAGRAPH" &&
            (field.label || field.type === "TABLE") &&
            field.settings.general.visibility !== "DISABLE"
          ) {
            if (secureControls.length) {
              if (typeof secureControls[0] === "object") {
                let user = secureControls.findIndex(
                  (item) => item.userId === this.$store.state.session.id
                );
                if (user >= 0) {
                  let controls = secureControls[user].formFields;
                  let index = controls.findIndex((item) => item === field.id);
                  if (index === -1) {
                    if (!this.myInbox) {
                      this.columns.push({
                        id: field.id,
                        name: field.id,
                        label: field.label,
                        type: "NORMAL",
                        isVisible: true,
                        dataType: field.type,
                        apiLoad: true,
                        isFilter: true,
                      });
                    }

                    this.formFields.push({
                      id: this.$nano.id(),
                      label: field.label || field.type,
                      value: field.id,
                      type: field.type,
                      settings: field.settings,
                    });
                  }
                }
              } else {
                let index = secureControls.findIndex(
                  (item) => item === field.id
                );
                if (index === -1) {
                  if (!this.myInbox) {
                    this.columns.push({
                      id: field.id,
                      name: field.id,
                      label: field.label,
                      type: "NORMAL",
                      isVisible: true,
                      dataType: field.type,
                      apiLoad: true,
                      isFilter: true,
                    });
                  }

                  this.formFields.push({
                    id: this.$nano.id(),
                    label: field.label || field.type,
                    value: field.id,
                    type: field.type,
                    settings: field.settings,
                  });
                }
              }
            } else {
              if (!this.myInbox) {
                let index = this.columns.findIndex(
                  (item) => item.id === field.id
                );
                if (index === -1) {
                  this.columns.push({
                    id: field.id,
                    name: field.id,
                    label: field.label,
                    type: "NORMAL",
                    isVisible: true,
                    dataType: field.type,
                    apiLoad: true,
                    isFilter: true,
                  });
                }
              }

              this.formFields.push({
                id: this.$nano.id(),
                label: field.label || field.type,
                value: field.id,
                type: field.type,
                settings: field.settings,
              });
            }
          }
        });
      } else if (this.workflow.repositoryId && this.repository.fields) {
        this.repository.fields.forEach((field) => {
          if (field.name && field.dataType !== "TABLE") {
            if (!this.myInbox) {
              this.columns.push({
                id: field.id,
                name: field.name,
                label: field.name,
                type: "NORMAL",
                isVisible: true,
                dataType: field.dataType,
              });
            }
          }
        });
      }
    },

    async applyHideColumns() {
      this.hideColumns.forEach((field) => {
        const columnIdx = this.columns.findIndex(
          (column) => column.id === field.columnName
        );
        if (columnIdx > -1) {
          this.columns[columnIdx].isVisible = false;
          this.columns[columnIdx].hideId = field.id;
        }
      });
    },

    updateFilterBy(filterBy) {
      this.filterBy = filterBy;
      this.$emit("filter", filterBy);
    },

    updateFilterByColumns() {
      if (this.search) {
        if (this.filterSearchColumn.length) {
          if (
            this.filterSearchColumn[0].filters[0].dataType === "SINGLE_SELECT"
          ) {
            this.filterSearchColumn[0].filters[0].value = JSON.stringify([
              this.search,
            ]);
          } else {
            this.filterSearchColumn[0].filters[0].value = this.search;
          }
          this.$emit("filter", this.filterSearchColumn);
        }
      } else {
        if (this.filterSearchColumn.length === 0) {
          this.$emit("filter", this.filterSearchColumn);
        }
      }
    },

    clearFilterByColumns(reload) {
      this.selectedFilterColumn = "";
      this.filterSearchColumn = [];
      this.search = "";
      if (reload) {
        this.updateFilterByColumns();
      }
    },

    showSubHistory(subProcessId, mainWfProcessId) {
      this.$emit("subHistory", subProcessId, mainWfProcessId);
    },

    showHistory(processId) {
      this.$emit("history", processId);
      this.$emit("history", processId);
    },

    async openSubAttachments(request) {
      const mainWorkflow = this.workflowRequestList.find(
        (row) => row.processId === request.parentProcessID
      );
      let subWorkflow;
      if (mainWorkflow) {
        subWorkflow = mainWorkflow.subworkflowTransaction.find(
          (subRow) => subRow.processId === request.processId
        );
        if (subWorkflow) {
          subWorkflow.id = subWorkflow.subWorkFlowId;
          this.selectedProcess = subWorkflow;
          // this.$emit("update:workflow", subWorkflow);
          this.$emit("update:selectedProcess", subWorkflow);
          await this.getRepository();
          await this.getAttachments(
            subWorkflow.subWorkFlowId,
            subWorkflow.processId
          );
          this.showLinks("ATTACHMENTS");
        }
      }
    },
    async openAttachments(request) {
      let selectedWorkflow = this.workflowList.find(
        (workflow) => workflow.id == request.workflowId
      );
      this.$emit("update:workflow", selectedWorkflow);
      this.$emit("update:selectedProcess", request);
      await this.getRepository();
      await this.getAttachments(request.workflowId, request.processId);
      this.showLinks("ATTACHMENTS");
    },
    async openSubComments(request) {
      const mainWorkflow = this.workflowRequestList.find(
        (row) => row.processId === request.parentProcessID
      );
      let subWorkflow;
      if (mainWorkflow) {
        subWorkflow = mainWorkflow.subworkflowTransaction.find(
          (subRow) => subRow.processId === request.processId
        );
        if (subWorkflow) {
          subWorkflow.id = subWorkflow.subWorkFlowId;
          this.selectedProcess = subWorkflow;
          // this.$emit("update:workflow", subWorkflow);
          // this.$emit("update:selectedProcess", subWorkflow);
          await this.getComments(
            subWorkflow.subWorkFlowId,
            subWorkflow.processId
          );
          this.showLinks("COMMENTS");
        }
      }
    },

    async openComments(request) {
      let selectedWorkflow = this.workflowList.find(
        (workflow) => workflow.id == request.workflowId
      );
      this.$emit("update:workflow", selectedWorkflow);
      this.$emit("update:selectedProcess", request);
      await this.getComments(request.workflowId, request.processId);
      this.showLinks("COMMENTS");
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          let type = 1;
          if (
            this.repositoryDetails.fieldsType === "STATIC" &&
            this.workflow.initiatedBy === "FORM"
          ) {
            type = 2;
          }
          let domainURL = "";
          if (pdfSupport(ext)) {
            if (this.isTenantArmgroup()) {
              domainURL =
                "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
            } else if (origin === "https://trial.ezofis.com") {
              domainURL = "https://trial.ezofis.com/docsviewer/index.html";
            } else if (this.isTenantGOTO()) {
              domainURL =
                "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
            } else {
              domainURL = `${origin}/PDFViewer/web/viewer.html`;
            }
          } else if (origin === "http://52.172.32.88") {
            domainURL = "http://52.172.32.88/DocsViewer/index.html";
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}`;
          }

          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=${type}`;
        } else {
          let domainURL = "";
          let action = "";
          if (
            this.selectedNode === "inbox" ||
            this.selectedNode === "common" ||
            this.selectedNode === "payment"
          ) {
            action = "&action=all";
          }
          if (pdfSupport(ext)) {
            if (this.isTenantArmgroup()) {
              action = "";
              domainURL =
                "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
            } else if (
              origin === "https://trial.ezofis.com" &&
              !otherFilePDFSupport(ext)
            ) {
              localStorage.setItem("viewerClose", false);
              this.watchViewerClose = setInterval(
                () => this.checkViewerClose(),
                1000
              );
              domainURL = "https://trial.ezofis.com/docsviewer/index.html";
            } else if (this.isTenantGOTO()) {
              domainURL =
                "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
            } else {
              domainURL = `${origin}/PDFViewer/web/viewer.html`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            localStorage.setItem("viewerClose", false);
            this.watchViewerClose = setInterval(
              () => this.checkViewerClose(),
              1000
            );
            domainURL = "http://52.172.32.88/DocsViewer/index.html";
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}${action}`;
        }
      }
    },

    itemLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (
        this.selectedNode === "inbox" ||
        this.selectedNode === "common" ||
        this.selectedNode === "payment"
      ) {
        let domainURL = "",
          actions = "&action=all";
        if (this.isTenantArmgroup()) {
          domainURL = "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
        } else if (origin === "https://trial.ezofis.com") {
          domainURL = "https://trial.ezofis.com/docsviewer/index.html";
        } else if (this.isTenantGOTO()) {
          domainURL = "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
        } else if (origin === "http://52.172.32.88") {
          domainURL = `http://52.172.32.88/DocsViewer/index.html`;
        } else {
          domainURL = `${origin}/PDFViewer/web/viewer.html`;
        }
        if (this.workflow.initiatedBy === "DOCUMENT_SIGN") {
          this.documentSignProcess = true;
          localStorage.setItem("signItemId", 0);
          localStorage.setItem("viewerClose", false);
          this.watchViewerClose = setInterval(
            () => this.checkViewerClose(),
            1000
          );
          return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.itemModel.name}&signature=true&getSign=true&uniqId=${this.selectedProcess.raisedByUserId}`;
        }
        return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&wId=${this.workflow.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.itemModel.name}${actions}`;
      } else {
        let domainURL = "",
          actions = "&action=download,print";
        if (this.isTenantArmgroup()) {
          domainURL = "https://ag-appsvc04.azurewebsites.net/web/viewer.html";
          actions = "";
        } else if (origin === "https://trial.ezofis.com") {
          domainURL = "https://trial.ezofis.com/docsviewer/index.html";
        } else if (this.isTenantGOTO()) {
          domainURL = "https://ezdocsviewer.azurewebsites.net/web/viewer.html";
        } else if (origin === "http://52.172.32.88") {
          domainURL = `http://52.172.32.88/DocsViewer/index.html`;
        } else {
          domainURL = `${origin}/PDFViewer/web/viewer.html`;
        }
        return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.itemModel.repositoryId}&itemId=${this.itemModel.itemId}&type=2&filename=${this.itemModel.name}${actions}`;
      }
    },

    openFileByCmd(file) {
      const originalFile = file.embedFileList[0];
      const { fileName: name, itemid: id, ...rest } = originalFile;
      this.selectedFile = { name, id, ...rest };
      if (this.isTenantArmgroup()) {
        window.open(this.fileLink());
      } else {
        this.fileSheet = true;
      }
    },

    openFile(fileObj) {
      if (!fileObj.loading) {
        this.selectedFile = fileObj;
        if (this.isTenantArmgroup()) {
          window.open(this.fileLink());
        } else {
          this.fileSheet = true;
        }
      } else {
        this.$alert.info("Please wait to complete the uploading");
      }
    },

    checkViewerClose() {
      if (localStorage.getItem("viewerClose")) {
        if (localStorage.getItem("viewerClose") === "true") {
          this.fileSheet = false;
          localStorage.removeItem("viewerClose");
          clearInterval(this.watchViewerClose);
          if (this.workflow.initiatedBy === "DOCUMENT_SIGN") {
            this.getSignerSettings();
          }
        }
      }
    },

    signLink(id) {
      return `${process.env.VUE_APP_API_URL}/workflow/signView/${this.$store.state.session.tenantId}/${this.workflow.id}/${id}`;
    },

    fileSelectAll() {
      this.attachmentList = this.attachmentList.map((item) => {
        return {
          ...item,
          checked: this.selectAll,
        };
      });
    },

    getColor(label) {
      if (label === "Closed" || label === "High") {
        return "red";
      } else if (label === "In Progress" || label === "Medium") {
        return "warning";
      } else if (label === "Open" || label === "Low") {
        return "secondary";
      } else if (label === "ReOpen") {
        return "primary";
      } else {
        return "primary";
      }
    },

    checkJiraRequest() {
      var filterReq = this.workflowRequestList.filter(
        (process) => process.checked
      );
      if (filterReq.length) {
        let accountName = "",
          accountNameCount = 0,
          accountNameFieldId = "";
        let recommendation = "",
          recommendationCount = 0,
          recommentdationFieldId = "";

        accountNameFieldId = this.formFields.find(
          (field) => field.label === "Account Name"
        )?.value;

        recommentdationFieldId = this.formFields.find(
          (field) => field.label === "Recommendation"
        )?.value;

        filterReq.forEach((req) => {
          if (req.formData.fields[accountNameFieldId] !== accountName) {
            accountName = req.formData.fields[accountNameFieldId];
            accountNameCount += 1;
          }
          if (req.formData.fields[recommentdationFieldId] !== recommendation) {
            recommendation = req.formData.fields[recommentdationFieldId];
            recommendationCount += 1;
          }
        });
        if (accountNameCount > 1) {
          accountName = "";
        }
        if (recommendationCount > 1) {
          recommendation = "";
        }
        let prefix = "";
        if (this.workflow.id === 1) {
          prefix = "EC2 ";
          this.issueDescriptionFields = [
            "Resource ID",
            "Resource Name",
            "Availability Zone",
            "Instance Type - Current",
            "Recommendation",
            "Instance Type - Recommended",
            "Savings",
          ];
        } else if (this.workflow.id === 3) {
          prefix = "EBS ";
          this.issueDescriptionFields = [
            "Resource ID",
            "Resource Name",
            "Availability Zone",
            "Volume Type - Current",
            "Recommendation",
            "Volume Type - Recommended",
            "Savings",
          ];
        }
        this.issueName = `${prefix}instances from ${accountName} marked for ${recommendation}`;
        this.projectKey = "GISDCT";
        this.issueType = "Support-Request"; // "Story";
        this.jiraIssueSheet = true;
        this.issueDescriptionTable = true;
      } else {
        this.$alert.info("Select the inbox request");
      }
    },

    attachTemplate(uid) {
      this.checkListRowIndex = uid;
      this.$refs.attachments.click();
    },

    actionColor(action) {
      if (this.darkTheme) {
        if (!action) {
          return "#27abc580";
        }
        if (action.toLowerCase().indexOf("reply") !== -1) {
          return "#ca811680";
        } else if (action.toLowerCase().indexOf("forward") !== -1) {
          return "#d2bc0980";
        } else if (action.toLowerCase().indexOf("approve") !== -1) {
          return "#418f4380";
        } else if (action.toLowerCase().indexOf("reject") !== -1) {
          return "#c9141480";
        } else {
          return "#27abc580";
        }
      } else {
        if (!action) {
          return "#e1f5f9";
        }
        if (action.toLowerCase().indexOf("reply") !== -1) {
          return "#f9e6ca";
        } else if (action.toLowerCase().indexOf("forward") !== -1) {
          return "#fcf5bc";
        } else if (action.toLowerCase().indexOf("approve") !== -1) {
          return "#bfe2c0";
        } else if (action.toLowerCase().indexOf("reject") !== -1) {
          return "#f9c3c3";
        } else {
          return "#e1f5f9";
        }
      }
    },

    async copyText(text) {
      await copyToClipboard(text);
      this.$alert.success(`Text copied`);
    },

    // checkDocument() {
    //   this.docWarning = false;
    //   if (this.checkList.length) {
    //     this.checkList.forEach((row) => {
    //       if (row.required && !row.attach) {
    //         this.docWarning = true;
    //       }
    //     });
    //   }
    // },

    checkRequiredDocument() {
      if (this.saveAction === "Save" || this.saveAction === "Force Close") {
        this.proceedSubmission();
      } else {
        if (this.documentRequired && this.attachmentList.length === 0) {
          this.$alert.warning(
            "A minimum of one document is required for submission"
          );
          this.saveAction = "";
          this.workflowFormModel = {};
          return;
        }
        let docRequired = this.checkList.filter(
          (row) => row.required && !row.attach
        );

        if (docRequired.length) {
          if (this.docWarning) {
            this.alertModal = true;
          } else {
            this.$alert.warning("Mandatory documents required");
            this.saveAction = "";
            this.workflowFormModel = {};
          }
        } else {
          this.proceedSubmission();
        }
      }
    },

    proceedSubmission() {
      this.alertModal = false;
      if (this.initiateProcess) {
        this.initiateRequest();
      } else {
        this.processRequest();
      }
    },

    closeAlertModal() {
      this.alertModal = false;
      this.saveAction = "";
      this.workflowFormModel = {};
    },

    getJiraIssueInfo(data) {
      if (data.jiraIssueInfo.id) {
        return {
          "Ref# Id": data.jiraIssueInfo.id,
          Key: data.jiraIssueInfo.key,
          Link: data.jiraIssueInfo.self
            ? this.getJIRALink(data.jiraIssueInfo)
            : "",
          Assignee: data.jiraIssueInfo.assignee,
          "Issue Status": data.jiraIssueInfo.issueStatus,
        };
      }
    },

    getProcessData(item, excel = false) {
      const data = {};
      if (this.workflow.wFormId && this.wform.formJson) {
        if (item.formData) {
          for (let key of Object.keys(item.formData.fields)) {
            if (excel) {
              const label = this.getLabel(key);
              data[label] = item.formData.fields[key];
            } else {
              data[key] = item.formData.fields[key];
            }
          }
        }
      } else if (this.workflow.repositoryId) {
        if (item.itemData) {
          for (let key of Object.keys(item.itemData.items)) {
            data[key] = item.itemData.items[key];
          }
        }
      }
      return data;
    },

    getLabel(id) {
      let controlLabel = "";
      let formJson = JSON.parse(this.wform.formJson);
      const panels = [...formJson.panels, ...formJson.secondaryPanels];

      if (!panels.length) {
        return;
      }
      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      // let flowJson = JSON.parse(this.workflow.flowJson);
      // console.log(flowJson, this.selectedProcess.activityId);

      fields.forEach((field) => {
        const column = field.settings.specific.tableColumns.find(
          (column) => column.id === id
        );
        if (column) {
          controlLabel = column.label;
        } else {
          if (field.id === id) {
            controlLabel = field.label;
            return;
          }
        }
      });
      return controlLabel;
    },

    isVisible(key) {
      const columns = this.columns;
      const columnIdx = columns.findIndex((column) => column.id === key);

      if (columnIdx > -1) {
        return columns[columnIdx].isVisible;
      } else {
        return false;
      }
    },

    reupload(filename) {
      filename = filename.substr(0, filename.lastIndexOf(".")).split("_")[0];
      this.checkListRowIndex = this.checkList.find(
        (row) => row.name === filename
      )?.uid;
      this.$refs.attachments.click();
    },

    attachmentFilterCheckList(name) {
      return this.attachmentList.filter(
        (row) =>
          row.name.substring(0, row.name.lastIndexOf(".")) === name ||
          row.name.substring(0, row.name.lastIndexOf(".")).split("_")[0] ===
            name
      );
    },

    isCheckList(name) {
      let list = this.checkList.filter(
        (row) =>
          row.name === name.substring(0, name.lastIndexOf(".")) ||
          row.name === name.substring(0, name.lastIndexOf(".")).split("_")[0]
      );
      if (list.length) {
        return false;
      }
      return true;
    },

    showMailAttachments(mail) {
      this.mailAttachmentList = [];
      let files = mail.attachmentJson.split(",");
      files.forEach((file) => {
        let filename = file.split("\\").pop();
        this.mailAttachmentList.push({
          id: this.$nano.id(),
          mailId: mail.id,
          name: filename,
          createdAt: mail.createdAt,
        });
      });
      this.mailAttachmentSheet = true;
    },

    openAttachmentFile(file) {
      let link = `${process.env.VUE_APP_API_URL}/client/viewAttachment/${this.$store.state.session.tenantId}/${file.mailId}/${file.name}`;
      window.open(link, "_blank");
    },

    closeJiraConfirmModal() {
      this.jiraConfirmModal = false;
      this.$emit("refresh", this.workflow.id);
    },

    updateSelectedSubWorkflow(item) {
      this.subProcessData = item;
      this.selectedSubWorkflow = item;
      this.subWorkflowId = item.id;
      this.openSubProcessSheet = true;
    },

    // API Functions

    async initiateRequest() {
      let uploadedIdList = [];
      // if (this.attachmentList.length) {
      //   this.attachmentList.forEach((file) => {
      //     uploadedIdList.push(file.id);
      //   });
      // }

      let formUpload = [];

      this.formFields.forEach((field) => {
        if (field.type === "FILE_UPLOAD" && this.workflowModel[field.value]) {
          let ids = [];

          this.workflowModel[field.value].forEach((item) => {
            if (item.fileId) {
              ids.push(item.fileId);
            }
          });

          if (ids.length) {
            formUpload.push({
              jsonId: field.value,
              fileIds: ids,
              rowid: 0,
            });
          }

          delete this.workflowFormModel[field.value];
        } else if (field.type === "TABLE") {
          let tableFiles = this.attachmentList.filter(
            (file) => file.tableControl
          );
          console.log(tableFiles);
          tableFiles.forEach((file) => {
            formUpload.push({
              jsonId: file.fieldId,
              fileIds: [file.id],
              rowid: file.rowIndex,
            });
            console.log(
              this.workflowFormModel[file.tableId][file.rowIndex - 1][
                file.fieldId
              ]
            );
            this.workflowFormModel[file.tableId][file.rowIndex - 1][
              file.fieldId
            ] = [];
          });
        }
      });
      if (this.attachmentList.length) {
        this.attachmentList.forEach((file) => {
          const alreadyUploaded = formUpload.some((upload) =>
            upload.fileIds.includes(file.id)
          );
          if (!alreadyUploaded) {
            uploadedIdList.push(file.id);
          }
        });
      }

      let inputData = {
        workflowId: this.workflow.id,
        review: this.saveAction === "Save" ? "" : this.saveAction,
        comments: this.commentsList,
        formData: {
          formId: this.formId,
          fields: this.workflowFormModel,
          formUpload: formUpload,
        },
        fileIds: uploadedIdList,
        task: this.taskEntryId,
        hasFormPDF: this.generatePDF,
        prefix: this.requestPrefix,
        mlPrediction: this.mlPrediction,
      };

      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.processTransaction(inputData);
      this.$store.commit("hideLoadingBarSave");
      this.saveAction = "";
      this.workflowFormModel = {};
      this.alertModal = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.signImg) {
        await this.saveProcessSignature(payload);
      }

      if (inputData.review) {
        this.$alert.success(`${payload.requestNo} Request Initiated`);
      } else {
        this.$alert.success(
          `${payload.requestNo} Request Initiated and Moved to your inbox`
        );
      }

      if (this.subProcessId) {
        this.addSubWorkflowProcess(payload.processId);
      }

      this.closeProcessSheet();
      this.$emit("refresh", this.workflow.id);
    },

    async addSubWorkflowProcess(mainProcessId) {
      let input = {
        mainWorkflowId: this.workflow.id,
        subWorkflowId: this.subWorkflowId,
        mainProcessId: mainProcessId,
        subProcessId: this.subProcessId,
        subProcessStatus: 0,
        review: "",
      };
      const { error } = await workflow.addSubWorkflow(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.updateSubWorkflowProcess();
    },

    async updateSubWorkflowProcess() {
      const { error, payload } = await transaction.updateSubWorkflowProcess(
        this.workflow.id,
        this.$store.state.session.tenantId,
        this.subWorkflowId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);
    },

    async processRequest(forwardCheck = true, addOnCheck = true) {
      if (forwardCheck) {
        if (this.isTenantGOTO()) {
          if (this.saveAction === "Assign" && this.internalForward.proceed) {
            this.processForwardGroupAction = false;
            this.processForwardModal = true;
            return;
          }
        }
        if (this.saveAction === "Forward" && this.internalForward.proceed) {
          this.processForwardGroupAction = false;
          this.processForwardModal = true;
          return;
        }

        if (this.isTenantArmgroup()) {
          if (this.saveAction === "Assign") {
            this.processForwardGroupAction = false;
            this.processForwardModal = true;
            return;
          }
        }
      }

      if (addOnCheck) {
        let actionAddOn = this.processActionsAddOn.find(
          (row) => row.action === this.saveAction
        );
        if (actionAddOn) {
          if (Object.keys(actionAddOn).length) {
            this.actionRemarks = actionAddOn.remarks;
            this.actionPassword = actionAddOn.passwordAccess;
            this.actionConfirm = actionAddOn.confirm;
            if (this.actionPassword) {
              this.actionPasswordConfirm = true;
              return;
            }
            if (this.actionRemarks) {
              this.actionComments = true;
              this.advancedCommentsSheet = true;
              return;
            }
            if (this.actionConfirm) {
              this.actionConfirmAlert = true;
              return;
            }
          }
        }
      }

      let inputData = {};
      let origin = location.origin;

      if (this.userReply && this.saveAction.indexOf("Reply") > -1) {
        this.saveAction = "UserReply";
      } else if (this.toRequester && this.saveAction === "To Requester") {
        this.saveAction = "ToInitiator";
      }
      if (this.customReply && this.saveAction.indexOf("Reply") > -1) {
        this.saveAction = "Reply";
        this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
      }
      let formUpload = [];

      this.formFields.forEach((field) => {
        if (field.type === "FILE_UPLOAD" && this.workflowModel[field.value]) {
          let ids = [];
          this.workflowModel[field.value].forEach((item) => {
            if (item.fileId) {
              ids.push(item.fileId);
            }
          });
          if (ids.length) {
            formUpload.push({
              jsonId: field.value,
              fileIds: ids,
              rowid: 0,
            });
          }

          delete this.workflowFormModel[field.value];
        } else if (field.type === "TABLE") {
          let tableFiles = this.attachmentList.filter(
            (file) => file.tableControl
          );
          console.log(tableFiles);
          tableFiles.forEach((file) => {
            formUpload.push({
              jsonId: file.fieldId,
              fileIds: [file.id],
              rowid: file.rowIndex,
            });
            console.log(
              this.workflowFormModel[file.tableId][file.rowIndex - 1][
                file.fieldId
              ]
            );
            let hasFile = this.workflowFormModel[file.tableId][
              file.rowIndex - 1
            ][file.fieldId].findIndex((table) => {
              return table.name;
            });
            if (hasFile > -1) {
              this.workflowFormModel[file.tableId][file.rowIndex - 1][
                file.fieldId
              ].splice(hasFile, 1);
            }
            this.workflowFormModel[file.tableId][file.rowIndex - 1][
              file.fieldId
            ] = [];
          });
        }
      });
      if (this.formId) {
        if (this.isTenantGOTO() && this.saveAction === "Rollback") {
          if (this.workflow.id === 1) {
            this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
          }
        } else if (
          origin === "http://demo.ezofis.com" ||
          origin === "http://52.172.32.88"
        ) {
          if (this.workflow.id === 6) {
            this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
          }
        } else if (
          this.isTenantArmgroup() &&
          this.selectedProcess.activityId === "szS6B-q1x9acpB83rP6jT" &&
          this.saveAction === "Assign"
        ) {
          let assignToField = this.formFields.find(
            (field) => field.label === "Assigned To"
          );
          if (assignToField) {
            let assignUserEmail = this.userList.find(
              (user) => user.value === this.armgroupForwardUser
            );
            if (assignUserEmail) {
              this.workflowFormModel[assignToField.value] =
                assignUserEmail.label;
              let initialsField = this.formFields.find(
                (field) => field.label === "Initials"
              );
              this.workflowFormModel[initialsField.value] =
                this.userInitials(assignUserEmail);
            } else {
              this.$alert.warning("Select any one user to assign");
              return;
            }
            this.armgroupForwardUser = "";
          }
        }
        inputData = {
          workflowId: this.workflow.id,
          transactionId: this.selectedProcess.transactionId,
          review:
            this.saveAction === "Save" || this.saveAction === "CustomVerify"
              ? ""
              : this.saveAction,
          formData: {
            formId: this.formId,
            formEntryId: this.selectedProcess.formData.formEntryId,
            fields: this.saveOnly ? this.autoSaveModel : this.workflowFormModel,
            formUpload: formUpload,
          },
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      } else if (this.itemModel) {
        inputData = {
          workflowId: this.workflow.id,
          transactionId: this.selectedProcess.transactionId,
          review: this.saveAction === "Save" ? "" : this.saveAction,
          formData: {},
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      }
      this.saveAction = "";
      this.workflowFormModel = {};
      //console.log(inputData);
      if (!this.saveOnly) {
        this.$store.commit("showLoadingBarSave");
      }
      const { error } = await workflow.processTransaction(inputData);
      if (!this.saveOnly) {
        this.$store.commit("hideLoadingBarSave");
      }

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.signImg) {
        await this.saveProcessSignature(this.selectedProcess);
      }
      if (this.saveOnly) {
        this.saveOnly = false;
        //this.$alert.info(`${this.selectedProcess.requestNo} data saved, awaiting your action to submit`);
      } else {
        if (inputData.review === "") {
          this.$alert.info(
            `${this.selectedProcess.requestNo} data saved, awaiting your action to submit`
          );
          this.refreshAction = true;
        } else if (inputData.review.toLowerCase().indexOf("reject") > -1) {
          this.$alert.danger(
            `${this.selectedProcess.requestNo} Request Rejected Successfully`
          );
        } else {
          this.$alert.success(
            `${this.selectedProcess.requestNo} Request Processed`
          );
        }
        if (inputData.review) {
          this.forwardUsers = [];
          this.forwardGroups = [];
          this.$emit("update:customReply", false);
          this.$emit("update:userReply", false);
          this.$emit("update:toRequester", false);
          this.$emit("update:selectedProcess", {});
          this.closeProcessSheet();
          this.processForwardModal = false;
          this.$emit("refresh", this.workflow.id);
        }
      }
      if (this.fileControlDeleteFile.length) {
        this.fileControlDeleteFile.forEach((file) => {
          this.fileControlDeletedFile(file);
        });

        this.fileControlDeleteFile = [];
      }
    },

    async groupProcessRequest() {
      let transactionId = [];
      let mlPrediction = "";
      if (this.isTenantArmgroup()) {
        this.workflowRequestList.forEach((process) => {
          if (process.checked) {
            transactionId.push(process.transactionId);
          }
        });
        let assignToField = this.formFields.find(
          (field) => field.label === "Assigned To"
        );
        if (assignToField) {
          let assignUserEmail = this.userList.find(
            (user) => user.value === this.armgroupForwardUser
          );
          if (assignUserEmail) {
            this.workflowFormModel[assignToField.value] = assignUserEmail.label;
            let initialsField = this.formFields.find(
              (field) => field.label === "Initials"
            );
            this.workflowFormModel[initialsField.value] =
              this.userInitials(assignUserEmail);
          } else {
            this.$alert.warning("Select any one user to assign");
            return;
          }
          this.armgroupForwardUser = "";
        }
      } else {
        this.workflowRequestList.forEach((process) => {
          if (process.key === this.groupActionKey && process.checked) {
            transactionId.push(process.transactionId);
          }
        });
      }

      this.groupKey.forEach((group) => {
        if (group.checked) {
          transactionId = [];
          mlPrediction = group.name;
          return;
        }
      });
      let input = {
        workflowId: this.workflow.id,
        transactionId: transactionId,
        review: this.saveAction === "Save" ? "" : this.saveAction,
        fields: this.workflowFormModel,
        mlPrediction: mlPrediction,
        comments: this.commentText,
        userIds: this.forwardUsers,
        groupIds: this.forwardGroups,
        filterBy: this.filterBy,
      };
      //console.log(input);
      //this.$store.commit("showLoadingBar");
      const { error } = await workflow.groupRequestAction(input);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.isTenantGOTO()) {
        await this.insertJiraIssueComment();
      }
      this.saveAction = "";
      this.workflowFormModel = {};
      this.forwardUsers = [];
      this.forwardGroups = [];
      this.$alert.success(`Group Request Processed`);
      this.processForwardModal = false;
      this.closeRequestActionModal();
      this.$emit("refresh", this.workflow.id);
    },

    async getComments(workflowId = 0, processId = 0) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      if (!workflowId && !processId) {
        workflowId = this.workflow.id;
        processId = this.selectedProcess.processId;
      }
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getProcessComments(
        workflowId,
        processId
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.commentsList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
      }
    },

    async saveComment() {
      if (this.commentText) {
        if (this.attachComment) {
          if (!this.selectedFileForAttach) {
            this.$alert.warning("Select File To Attach");
            return;
          }
        }
        if (this.selectedProcess.processId) {
          if (
            this.isTenantGOTO() &&
            this.selectedProcess.jiraIssueInfo.id &&
            this.jiraCommentAction === 2
          ) {
            const { error, payload } = await workflow.insertProcessComment_Jira(
              this.selectedProcess.jiraIssueInfo.id,
              {
                comments: this.commentText,
              }
            );

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.commentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          } else {
            const { error, payload } = await workflow.insertProcessComment(
              this.workflow.id,
              this.selectedProcess.processId,
              this.selectedProcess.transactionId,
              {
                comments: this.commentText,
                showTo: this.commentAction,
                hasNotifytoInitiated: this.notifyInitiator,
                embedJson: JSON.stringify({
                  repositoryId: this.workflow.repositoryId,
                  itemIds: this.attachComment
                    ? [this.selectedFileForAttach]
                    : [],
                }),
              }
            );

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.commentText,
                this.selectedProcess.jiraIssueInfo.id,
                [payload]
              );
            }
          }
          this.commentText = "";
          this.clearAttach();
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.commentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
            hasNotifytoInitiated: this.notifyInitiator,
            fileIds: this.attachComment ? [this.selectedFileForAttach] : [],
            showTo: this.commentAction,
          });
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
          this.commentText = "";
          this.clearAttach();
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.attachComment) {
          if (!this.selectedFileForAttach) {
            this.$alert.warning("Select File To Attach");
            return;
          }
        }
        if (this.selectedProcess.processId) {
          if (
            this.isTenantGOTO() &&
            this.selectedProcess.jiraIssueInfo.id &&
            this.jiraCommentAction === 2
          ) {
            this.$store.commit("showLoadingBar");
            const { error, payload } = await workflow.insertProcessComment_Jira(
              this.selectedProcess.jiraIssueInfo.id,
              {
                comments: this.advancedCommentText,
              }
            );

            this.$store.commit("hideLoadingBar");

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.advancedCommentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          } else {
            const { error, payload } = await workflow.insertProcessComment(
              this.workflow.id,
              this.selectedProcess.processId,
              this.selectedProcess.transactionId,
              {
                comments: this.advancedCommentText,
                showTo: this.commentAction,
                hasNotifytoInitiated: this.notifyInitiator,
                embedJson: JSON.stringify({
                  repositoryId: this.workflow.repositoryId,
                  itemIds: this.attachComment
                    ? [this.selectedFileForAttach]
                    : [],
                }),
              }
            );

            if (error) {
              this.$alert.error(error);
              return;
            }
            if (this.selectedProcess.jiraIssueInfo.id) {
              this.sendCommentsToJira(
                this.advancedCommentText,
                this.selectedProcess.jiraIssueInfo.id,
                payload
              );
            }
          }
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.clearAttach();
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.advancedCommentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
            showTo: this.commentAction,
            fileIds: this.attachComment ? [this.selectedFileForAttach] : [],
          });
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
        }
        if (this.actionComments) {
          this.processRequest(false, false);
        }
      }
    },

    async uploadWflowAttachments(e) {
      this.fileUploadForControl = false;
      if (this.repositoryDetails.fieldsType === "STATIC") {
        this.selectedUploadFile = e;
        if (this.customCheckUpload()) {
          let documentNoField = this.formFields.find(
            (field) => field.label === "Document No"
          );
          if (documentNoField) {
            this.uploadFilename =
              this.workflowModel[documentNoField.value] + ".pdf";
          } else {
            this.uploadFilename = e.target.files[0].name;
          }
        } else {
          this.uploadFilename = e.target.files[0].name;
        }
        this.showMetaDataEditModal = true;
      } else {
        this.uploadAttachments(e);
      }
    },

    async uploadAttachments(e, fields) {
      if (this.selectedProcess.processId) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("workflowId", this.workflow.id);
        formData.append("repositoryId", this.workflow.repositoryId);
        formData.append("processId", this.selectedProcess.processId);
        formData.append("transactionId", this.selectedProcess.transactionId);
        if (fields) {
          formData.append("fields", fields);
        } else {
          formData.append("fields", "");
        }
        formData.append("file", file);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.checkListRowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/workflow/attachmentWithProcessId`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          const { status, data } = response;
          if (status === 200 && data) this.$alert.success(`Document attached`);
          else throw response;
          this.documentLoad = setInterval(() => {
            this.getAttachments();
            clearInterval(this.documentLoad);
          }, 15000);
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        this.$store.commit("hideLoadingBar");
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            if (row.uid === this.checkListRowIndex) {
              row.attach = true;
              return;
            }
          });
        }
        e.target.value = "";
        if (Object.keys(this.reuploadFile).length) {
          await this.attachmentDelete(this.reuploadFile);
          this.reuploadFile = {};
        }
        //this.attachmentLoad = setInterval(() => this.getAttachments(), 5000);
      } else {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("repositoryId", this.workflow.repositoryId);
        if (fields) {
          this.fileData = JSON.parse(fields);
          formData.append("fields", fields);
        }
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.checkListRowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.checkListRowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        } else {
          formData.append("filename", filename);
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          this.$store.commit("hideLoadingBar");
          const { status, data } = response;
          if (status !== 200) {
            throw "Invalid response";
          }
          let filesList = JSON.parse(data);
          this.$alert.success(`Document attached`);
          let fileName = this.repositoryField.reduce((max, curren) =>
            max.level > curren.level ? max : curren
          );
          let name = this.fileData.find((item) => item.name === fileName.name);

          let loading = false;
          if (ext.toLowerCase() !== "pdf") {
            loading = true;
            this.documentLoadList.push(Number(filesList.fileId));
            this.documentLoad = setInterval(() => {
              let file = this.attachmentList.find(
                (row) => this.documentLoadList.indexOf(row.id) > -1
              );
              if (file) {
                this.documentLoadList.splice(
                  this.documentLoadList.indexOf(file.id),
                  1
                );
                file.loading = false;
              }
              if (this.documentLoadList.length === 0) {
                clearInterval(this.documentLoad);
              }
            }, 10000);
          }

          this.attachmentList.push({
            id: Number(filesList.fileId),
            name: fields ? name.value + "." + ext : filename,
            createdAt: new Date().toISOString(),
            createdByEmail: this.$store.state.session.email,
            size: file.size,
            initiate: true,
            uid: this.checkListRowIndex,
            createdBy: this.$store.state.session.id,
            fileData: this.fileData,
            repositoryId: this.workflow.repositoryId,
            loading: loading,
          });
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
          if (this.checkList.length) {
            this.checkList.forEach((row) => {
              if (row.uid === this.checkListRowIndex) {
                row.attach = true;
                return;
              }
            });
          }
          e.target.value = "";
        } catch (error) {
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
      }
    },

    async getAttachments(workflowId = 0, processId = 0) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      let count = this.attachmentList.length;

      if (!workflowId && !processId) {
        workflowId = this.workflow.id;
        processId = this.selectedProcess.processId;
      }
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getAttachments(
        workflowId,
        processId
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        if (count !== payload.length) {
          this.attachmentList = payload.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
          //clearInterval(this.attachmentLoad);
        }
        this.tabs.find((tab) => {
          if (tab.value === "ATTACHMENTS") {
            tab.count = this.attachmentList.length;
          }
        });
      } else {
        this.attachmentList = [];
      }
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          let filterAttach = this.attachmentList.filter((file) => {
            let filename = file.name.substr(0, file.name.lastIndexOf("."));
            if (row.name === filename || row.name === filename.split("_")[0]) {
              return file;
            }
          });
          if (filterAttach.length) {
            row.attach = true;
          } else {
            row.attach = false;
          }
        });
        let origin = location.origin;
        if (
          this.isTenantArmgroup() ||
          (origin === "https://trial.ezofis.com" &&
            this.$store.state.session.tenantId === 12)
        ) {
          if (
            this.selectedProcess.attachmentCount !== this.attachmentList.length
          ) {
            this.formCheckList = true;
          }
        }
      }
    },

    async getMailTransactions() {
      this.mailTransactionList = [];
      this.tabs.find((tab) => {
        if (tab.value === "MAIL") {
          tab.count = 0;
        }
      });
      let filter = { filterBy: [] };
      const { error, payload } = await workflow.getMailTransactions(
        this.workflow.id,
        this.selectedProcess.processId,
        filter
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.mailTransactionList = payload;
        this.tabs.find((tab) => {
          if (tab.value === "MAIL") {
            tab.count = this.mailTransactionList.length;
          }
        });
      }
    },

    async resendMail(mailId) {
      if (mailId) {
        const { error } = await workflow.resendMail(mailId);
        if (error) {
          this.$alert.error(error);
          return;
        }
        this.$alert.success("Resend Successful!");
        this.getMailTransactions();
      } else {
        this.$alert.info("Unable to resend");
      }
    },

    async attachmentDelete(file) {
      if (!file.fileControl) {
        if (file.initiate) {
          if (this.repositoryDetails.fieldsType === "STATIC") {
            const { error } = await uploadAndIndex.deleteIndexedFiles([
              file.id,
            ]);
            if (error) {
              this.$alert.error(error);
              return;
            }
          } else {
            const { error } = await uploadAndIndex.deleteUploadedFiles([
              file.id,
            ]);
            if (error) {
              this.$alert.error(error);
              return;
            }
          }

          this.attachmentList = this.attachmentList.filter(
            (row) => row.id !== file.id
          );
          this.checkList.forEach((row) => {
            if (row.uid === file.uid) {
              row.attach = false;
            }
          });
        } else {
          const { error } = await repository.fileDelete(
            this.workflow.repositoryId,
            file.id
          );
          if (error) {
            this.$alert.error(error);
            return;
          }
          this.getAttachments();
        }
      } else {
        let field = this.workflowModel[file.fieldId];
        if (field.length) {
          let hasField = field.findIndex((item) => {
            return item.fileId === file.id;
          });
          console.log(hasField);
          if (hasField >= 0) {
            this.workflowModel[file.fieldId].splice(hasField, 1);
          }

          let hasValue = this.formUploadFiles.findIndex((item) => {
            return item.fileId === file.id && item.fileId === file.fileId;
          });
          console.log(hasValue);
          if (hasValue >= 0) {
            this.formUploadFiles.splice(hasField, 1);
          }
          if (file.control.length) {
            file.control.forEach((item) => {
              this.workflowModel[item] = "";
            });
          }
        }
        let hasFile = this.attachmentList.findIndex((file) => {
          return file.fileControl;
        });
        console.log(hasFile);
        if (hasFile >= 0) {
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length - 1;
            }
          });
          this.attachmentList.splice(hasFile, 1);
        }
      }
    },

    async saveProcessSignature(request) {
      let input = {
        signBinary: this.signImg.split(",")[1],
        fileType: "png",
      };
      const { error } = await workflow.saveProcessSignature(
        this.workflow.id,
        request.processId,
        request.transactionId,
        input
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.signImg = "";
    },

    async getProcessSignature() {
      this.signList = [];
      const { error, payload } = await workflow.getProcessSignature(
        this.workflow.id,
        this.selectedProcess.processId
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.signList = payload;
    },

    async getAppConnection() {
      this.jiraConnect = false;
      const { error, payload } = await integration.getJiraConnection({
        filterBy: [],
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      payload.forEach((row) => {
        if (row.cloudName === "jira") {
          this.jiraConnect = true;
          this.jiraAccounts.push(row);
          //this.getJiraUsers();
        }
      });
    },

    async createJiraIssue() {
      this.issueRef = {};
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }

      let transactionId = [];
      let mlPrediction = "";
      let descriptionTable = "";
      let totalSavings = 0;

      this.groupKey.forEach((group) => {
        if (group.checked) {
          transactionId = [];
          mlPrediction = group.name;
          return;
        }
      });
      if (mlPrediction === "") {
        this.workflowRequestList.forEach((process) => {
          if (process.checked) {
            transactionId.push(process.transactionId);
          }
        });

        if (this.issueDescriptionTable) {
          descriptionTable = "||Action Taken (Mandatory)";
          this.issueDescriptionFields.forEach((field) => {
            descriptionTable += "||" + field;
          });
          descriptionTable += "||\n";
          this.workflowRequestList.forEach((process) => {
            if (process.checked) {
              descriptionTable += "|| ";
              this.issueDescriptionFields.forEach((field) => {
                let fieldId = this.formFields.find(
                  (row) => row.label === field
                )?.value;
                if (field === "Savings") {
                  if (process.formData.fields[fieldId]) {
                    descriptionTable +=
                      "||" +
                      Number(process.formData.fields[fieldId]).toFixed(2);
                    totalSavings += Number(process.formData.fields[fieldId]);
                  } else {
                    descriptionTable += "|| ";
                  }
                } else {
                  if (process.formData.fields[fieldId]) {
                    descriptionTable += "||" + process.formData.fields[fieldId];
                  } else {
                    descriptionTable += "|| ";
                  }
                }
              });
              descriptionTable += "||\n";
            }
          });
          descriptionTable +=
            "Potential Savings: " + totalSavings.toFixed(2) + "\n";
        }
      }
      //customfield_12130: this.issueEpicValue,
      let input = {};
      if (this.jiraAccounts[0].email) {
        input = {
          fields_cloud: {
            fields: {
              summary: this.issueName,
              project: {
                key: this.projectKey,
              },
              issuetype: {
                name: this.issueType,
              },
              description: {
                content: [
                  {
                    content: [
                      {
                        text: this.issueDescription + descriptionTable,
                        type: "text",
                      },
                    ],
                    type: "paragraph",
                  },
                ],
                type: "doc",
                version: 1,
              },
            },
          },
          cloudIntegrationId: this.jiraAccounts[0].id,
        };
      } else {
        input = {
          fields_onpremise: {
            fields: {
              summary: this.issueName,
              project: {
                key: this.projectKey,
              },
              issuetype: {
                name: this.issueType,
              },
              description: this.issueDescription + descriptionTable,
              customfield_14635: {
                value: "No",
              },
              customfield_15136: {
                value: "Maintenance",
              },
            },
          },
          cloudIntegrationId: this.jiraAccounts[0].id,
          workflowId: this.workflow.id,
          transactionId: transactionId,
          mlPrediction: mlPrediction,
          assignee: this.issueAssignee ? { name: this.issueAssignee } : {},
          filterBy: this.filterBy,
        };
      }
      //console.log(input);
      this.$store.commit("showLoadingBar");
      const { error, payload } = await integration.createJiraIssue(input);
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.warning("error creating issue in jira");
        let msg = JSON.parse(error);
        console.log(msg);
        this.errorMessage = msg.errorMessages;
        // let msgContent = JSON.parse(msg.Message);
        // this.errorMessage = msgContent.errors;
        this.errorModal = true;
        return;
      }
      if (mlPrediction) {
        this.successModal = true;
      } else {
        if (payload) {
          this.issueRef = JSON.parse(payload);
          this.issueRef.self = this.getJIRALink(this.issueRef);
          this.$alert.success(`Issue Created in JIRA`);
          this.jiraConfirmModal = true;
        } else {
          this.$alert.warning("error creating issue in jira");
          return;
        }
      }
      this.closeJiraIssueSheet();
    },

    async insertJiraIssueComment() {
      let referenceIdFieldId = "",
        remarksFieldId = "";
      referenceIdFieldId = this.formFields.find(
        (field) => field.label === "Reference ID"
      )?.value;
      remarksFieldId = this.formFields.find(
        (field) => field.label === "Remarks"
      )?.value;
      if (
        this.workflowFormModel[referenceIdFieldId] &&
        this.workflowFormModel[remarksFieldId]
      ) {
        this.$store.commit("showLoadingBar");
        let input = {};
        if (this.jiraAccounts[0].email) {
          input = {
            cloudIntegrationId: this.jiraAccounts[0].id,
            url: this.workflowFormModel[referenceIdFieldId],
            data_cloud: {
              body: this.workflowFormModel[remarksFieldId],
            },
          };
        } else {
          input = {
            cloudIntegrationId: this.jiraAccounts[0].id,
            url: this.workflowFormModel[referenceIdFieldId],
            data_onpremise: {
              body: this.workflowFormModel[remarksFieldId],
            },
          };
        }

        const { error, payload } = await integration.insertJiraIssueComments(
          input
        );
        this.$store.commit("hideLoadingBar");
        if (error) {
          this.$alert.error(error);
          return;
        }
        console.log(payload);
      }
    },

    async getJiraUsers() {
      this.issueAssigneeUsers = [];
      const { error, payload } = await integration.getJiraUsers({
        cloudIntegrationId: this.jiraAccounts[0].id,
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        payload.forEach((user) => {
          this.issueAssigneeUsers.push({
            id: user.key,
            label: user.displayName,
            value: user.name,
          });
        });
      }
    },

    async getFileData(file, data) {
      //this.$store.commit("showLoadingBar");
      const { error, payload } = await repository.getFileData({
        repositoryId: file.repositoryId,
        itemId: file.id,
      });
      //this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      // if (file.fileData) {
      //   this.fileData = file.fileData;
      // } else {
      this.fileData = payload;
      //}
      if (data !== "file") this.fileDetailsSheet = true;
    },

    async sendCommentsToJira(commentsText, issueId, commentId) {
      this.$store.commit("showLoadingBar");
      if (this.jiraCommentAction === 1) {
        let resourceIDFieldId = this.formFields.find(
          (field) => field.label === "Resource ID"
        )?.value;
        commentsText =
          this.selectedProcess.formData.fields[resourceIDFieldId] +
          "," +
          commentsText;
      }
      commentsText = `${this.$store.state.session.firstName} ${this.$store.state.session.lastName}: ${commentsText}`;
      let input = {
        cloudIntegrationId: this.jiraAccounts[0].id,
        url: this.selectedProcess.jiraIssueInfo.self,
        data_onpremise: {
          body: commentsText,
        },
        issueId: issueId,
        ezCommentId: commentId,
      };

      const { error, payload } = await integration.insertJiraIssueComments(
        input
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error("error posting comments to jira");
        let msg = JSON.parse(error);
        this.errorMessage = msg.errorMessages;
        this.errorModal = true;
        return;
      }
      console.log(payload);
      this.jiraCommentAction = 1;
    },

    async validatePassword() {
      const areAllFieldsValid = await this.$refs.passwordForm.validate();

      if (!areAllFieldsValid) {
        return;
      }
      this.isLoading = true;
      const { error } = await auth.validatePassword({
        password: this.actionUserPassword,
      });
      this.isLoading = false;
      if (error) {
        console.log(error);
        this.$alert.warning(error);
        return;
      }
      this.actionUserPassword = "";
      this.actionPasswordConfirm = false;
      if (this.actionRemarks) {
        this.actionComments = true;
        this.advancedCommentsSheet = true;
      } else {
        this.actionRemarks = false;
        this.actionPassword = false;
        this.processRequest(false, false);
      }
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.workflow.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.repositoryDetails = payload;
      this.repositoryField = payload.fields;
    },

    saveMetaData(modal) {
      let data = [];
      this.repositoryField.forEach((field) => {
        data.push({
          name: field.name,
          value: modal[field.name],
          type: field.dataType,
        });
      });

      data.forEach((key) => {
        let hasName = this.formFields.find(
          (item) => lowerCase(item.label) === lowerCase(key.name)
        );
        if (hasName && key.value) {
          this.workflowModel[hasName.value] = key.value;
        }
      });

      if (this.QRFileUploaded) {
        this.metaDataModal = modal;
        this.addFileInAttachment();
        this.QRFileUploaded = false;
        return;
      }

      if (this.fileUploadForControl) {
        this.metaDataModal = modal;
        let input = this.fileUploadInput;
        this.uploadFilesFromForm(
          input.files,
          input.control,
          input.fieldId,
          JSON.stringify(data),
          input.aiSettings
        );
      } else {
        this.uploadAttachments(this.selectedUploadFile, JSON.stringify(data));
      }
    },

    processDocumentAction(action) {
      if (action === "Email_Sharing") {
        //
      } else if (action === "Print") {
        if (this.itemModel.name) {
          let ext = this.itemModel.name.split(".").pop();
          if (pdfSupport(ext)) {
            localStorage.setItem("filePrint", true);
            localStorage.setItem("filePrintId", this.itemModel.itemId);
          } else {
            this.$refs.myProcessFiles.contentWindow.print();
          }
        }
        this.processFilePrintSession();
      } else if (action === "Download") {
        let link = "";
        link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.itemModel.repositoryId}/${this.itemModel.itemId}/2`;
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async processFilePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.itemModel.repositoryId,
        itemId: this.itemModel.itemId,
        activityId: 4,
      });
      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    fileMenuAction(fileObj, action) {
      this.selectedFile = fileObj;
      this.documentAction(action, true);
    },

    documentAction(action, viewPrint) {
      if (action === "Email_Sharing") {
        //
      } else if (action === "Print") {
        if (this.selectedFile.name) {
          if (viewPrint) {
            let link = "";
            if (this.selectedFile.initiate) {
              let type = 1;
              if (
                this.repositoryDetails.fieldsType === "STATIC" &&
                this.workflow.initiatedBy === "FORM"
              ) {
                type = 2;
              }
              link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/1`;
            } else {
              link = `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
            }
            window.open(link, "_blank");
          } else {
            let ext = this.selectedFile.name.split(".").pop();
            if (pdfSupport(ext)) {
              localStorage.setItem("filePrint", true);
              localStorage.setItem("filePrintId", this.selectedFile.id);
            } else {
              this.$refs.myAttachmentFiles.contentWindow.print();
            }
          }
        }
        this.filePrintSession();
      } else if (action === "Download") {
        let link = "";

        if (this.selectedFile.initiate) {
          let type = 1;
          if (
            this.repositoryDetails.fieldsType === "STATIC" &&
            this.workflow.initiatedBy === "FORM"
          ) {
            type = 2;
          }
          link = `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}/2`;
        } else {
          link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        }
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async filePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.selectedFile.repositoryId,
        itemId: this.selectedFile.id,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async documentMerge() {
      let fileIds = [];
      this.attachmentList.forEach((item) => {
        let ext = item.name.split(".").pop();
        if (item.checked && ext.toLowerCase() === "pdf") {
          fileIds.push(item.id);
        }
      });
      let input = {
        ids: fileIds,
      };
      const { error } = await workflow.documentMerge(
        this.workflow.id,
        this.selectedProcess.processId,
        this.selectedProcess.transactionId,
        this.workflow.repositoryId,
        input
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success("Document merge process initiated");
    },

    async documentShare() {
      this.selectedFileList = [];
      this.attachmentList.forEach((item) => {
        if (item.checked) {
          this.selectedFileList.push({
            id: item.id,
            name: item.name,
          });
        }
      });
      this.emailSharingSheet = true;
    },

    async exportRequestData() {
      let excelData = [];
      //this.$store.commit("showLoadingBar");
      let id = 0;
      let name = "";
      if (this.workflow.id) {
        id = this.workflow.id;
        name = this.workflow.name;
      } else {
        id = this.dashboardId;
        name = this.dashboardName;
      }
      let filterBy = {
        filterBy: this.filterBy,
        itemsPerPage: 500,
        currentPage: 1,
        filterValue:
          this.selectedNode === "Completed" ? this.selectedCompletedNode : "",
      };
      //this.$store.commit("showLoadingBar");
      if (this.selectedNode === "inbox") {
        const { error, payload } = await workflow.getInboxList(id, filterBy);

        this.$store.commit("hideLoadingBarPage");

        const { meta, data } = payload;
        if (meta.totalItems && data.length) {
          excelData = data[0].value;
        }

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else if (this.selectedNode === "common") {
        const { error, payload } = await workflow.getCommonList(id, filterBy);

        this.$store.commit("hideLoadingBarPage");

        const { meta, data } = payload;
        if (meta.totalItems && data.length) {
          excelData = data[0].value;
        }

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else if (this.selectedNode === "sent") {
        const { error, payload } = await workflow.getSentList(id, filterBy);

        this.$store.commit("hideLoadingBarPage");

        const { meta, data } = payload;
        if (meta.totalItems && data.length) {
          excelData = data[0].value;
        }

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else if (this.selectedNode === "completed") {
        const { error, payload } = await workflow.getCompletedList(
          id,
          filterBy
        );

        this.$store.commit("hideLoadingBarPage");

        const { meta, data } = payload;
        if (meta.totalItems && data.length) {
          excelData = data[0].value;
        }

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else if (this.selectedNode === "payment") {
        const { error, payload } = await workflow.getPaymentList(id, filterBy);

        this.$store.commit("hideLoadingBarPage");

        const { meta, data } = payload;
        if (meta.totalItems && data.length) {
          excelData = data[0].value;
        }

        if (error) {
          this.$alert.error(error);
          return;
        }
      }
      if (excelData.length) {
        let exportRecords = [];
        excelData.forEach((item) => {
          exportRecords.push({
            "Request No": item.requestNo,
            ...this.getJiraIssueInfo(item),
            Stage: item.stage,
            "Action User": item.activityUserEmail,
            "Raised By": item.raisedBy,
            "Raised On": this.$day.format(item.raisedAt),
            "Last Action": item.lastAction,
            "Last Action By": item.transaction_createdByEmail,
            "Last Action On": this.$day.format(item.transaction_createdAt),
            Duration: this.lastActionDuration(item),
            "Attachments Count": item.attachmentCount,
            "Comments Count": item.commentsCount,
            ...this.getProcessData(item, true),
          });
        });
        exportData(exportRecords, name + "-" + this.selectedNode + " Report");
        this.$emit("exported");
      } else {
        //this.$alert.info("No data found for export");
      }
    },

    initiateSubWorkflow() {
      this.initiateSubWorkflowProcess = true;
      //this.closeProcessSheet();
    },

    async getSubWorkflow() {
      this.tabs.find((tab) => {
        if (tab.value === "SUB_PROCESS") {
          tab.count = 0;
        }
      });

      const { error, payload } = await workflow.getSubWorkflow(
        this.workflow.id,
        this.selectedProcess.processId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.length) {
        this.getSubProcessDetails(payload[0].subWorkflowId, payload);
      }
    },

    async getSubProcessDetails(wId, data) {
      this.$store.commit("showLoadingBar");
      const { error, payload } = await report.getWorkflowData(wId, {
        filterBy: [],
        itemsPerPage: 0,
        currentPage: 0,
        mode: "",
      });
      this.$store.commit("hideLoadingBar");
      this.subProcessTickets = [];
      if (error) {
        this.subProcessTickets = [];
        this.$alert.error(error);
        return;
      }

      if (payload.meta.totalItems) {
        let process = [];
        data.forEach((sub) => {
          let hasProcess = payload.data[0].value.find(
            (item) => item.processId === Number(sub.subProcessId)
          );
          if (hasProcess) {
            process.push(hasProcess);
          }
        });

        if (process.length) {
          this.subProcessTickets = process;
        }
        this.tabs.find((tab) => {
          if (tab.value === "SUB_PROCESS") {
            tab.count = process.length;
          }
        });
      } else {
        this.subProcessTickets = [];
      }
    },

    subProcessShowLinks(value, process) {
      this.subProcessLink = "";
      this.subProcessData = process;
      this.subProcessLink = value;
    },

    openSubProcess(process) {
      this.subProcessData = process;
      this.openSubProcessSheet = true;
    },

    // getAttachmentLength(count) {
    //   this.subTabs.find((tab) => {
    //     if (tab.value === "ATTACHMENTS") {
    //       tab.count = count;
    //     }
    //   });
    // },

    // getCommentsLength(count) {
    //   this.subTabs.find((tab) => {
    //     if (tab.value === "COMMENTS") {
    //       tab.count = count;
    //     }
    //   });
    // },

    closeSubProcess() {
      this.openSubProcessSheet = false;
      this.subProcessData = {};
      this.initiateSubWorkflowProcess = false;
      // this.subProcessTickets = [];
      this.getSubWorkflow();
    },

    async getSignerSettings() {
      this.forwardUsers = [];
      let itemId = this.itemModel.itemId.toString();
      if (localStorage.getItem("signItemId")) {
        if (localStorage.getItem("signItemId") !== "0") {
          itemId = localStorage.getItem("signItemId");
        }
      }
      localStorage.removeItem("signItemId");
      let inputData = [
        {
          groupCondition: "",
          filters: [
            {
              criteria: "processId",
              condition: "IS_EQUALS_TO",
              value: this.selectedProcess.processId.toString(),
            },
            {
              criteria: "repositoryId",
              condition: "IS_EQUALS_TO",
              value: this.itemModel.repositoryId.toString(),
            },
            {
              criteria: "itemId",
              condition: "IS_EQUALS_TO",
              value: itemId,
            },
          ],
        },
      ];
      try {
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/annotation/signSettingsList/${this.$store.state.session.tenantId}/${this.selectedProcess.id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          data: inputData,
        });

        const { status, data } = response;
        if (status !== 200) {
          throw "Invalid response";
        }
        let signerSettings = JSON.parse(data);
        let signerDet = JSON.parse(signerSettings.settingsJson);
        if (signerDet.length) {
          let signer = signerDet.find(
            (row) => row.signerId === Number(this.$store.state.session.id)
          );
          if (signer) {
            if (signer.signOrder) {
              let signOrder = signer.signOrder + 1;
              let nextSigner = signerDet.find(
                (row) => row.signOrder === signOrder
              );
              if (nextSigner) {
                if (nextSigner.signerType == "External") {
                  this.forwardUsers.push(nextSigner.externalSignerId);
                } else {
                  this.forwardUsers.push(nextSigner.signerId);
                }
              }
            }
          }
        }
        this.saveAction = this.processActions[0];
        this.processRequest(false, false);
      } catch (error) {
        console.error(error);
        this.$alert.error(`error getting signer details`);
      }
    },

    async uploadFilesFromForm(files, control, fieldId, fields, aiSettings) {
      this.formUploadFiles = [];
      const formData = new FormData();
      if (files.length) {
        //this.$store.commit("showLoadingBarSave");
        for (let file of files) {
          if (file.name && !file.error) {
            formData.append("repositoryId", this.workflow.repositoryId);
            formData.append("file", file);
            formData.append("filename", file.name);
            if (fields) {
              formData.append("fields", fields);
            } else {
              formData.append("fields", "");
            }

            if (!isEmpty(aiSettings)) {
              if (aiSettings.formControlValidate) {
                if (aiSettings.formControlValidate.optionsType === "MASTER") {
                  formData.append(
                    "masterFormId",
                    aiSettings.formControlValidate.masterFormId
                  );
                  formData.append(
                    "masterFormColumn",
                    aiSettings.formControlValidate.masterFormColumn
                  );
                }
                if (aiSettings.fileDataValidate.length) {
                  formData.append(
                    "isValidateFile",
                    aiSettings.fileDataValidate.includes("EXPIRY_DATE")
                  );
                }
              }
            }

            try {
              const response = await axios({
                url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
                method: "POST",
                data: formData,
                headers: {
                  Token: this.$store.state.identity.token,
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: function (progressEvent) {
                  let uploadedPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  // console.log(this.workflowModel, fieldId);
                  let hasFile = this.workflowModel[fieldId].findIndex(
                    (item) => {
                      return item.name === file.name;
                    }
                  );
                  // console.log(hasFile);
                  if (hasFile >= 0) {
                    this.$set(
                      this.workflowModel[fieldId][hasFile],
                      "uploadedPercentage",
                      0
                    );
                    if (uploadedPercentage > 50 && uploadedPercentage !== 100) {
                      this.workflowModel[fieldId][hasFile].uploadedPercentage =
                        uploadedPercentage;
                    } else {
                      this.workflowModel[fieldId][
                        hasFile
                      ].uploadedPercentage = 98;
                    }
                  }
                }.bind(this),
              });

              const { status, data } = response;
              if (status !== 200) {
                throw "Invalid response";
              }
              file.createdAt = this.$day.newDateTime();
              file.createdBy = this.$store.state.session.email;
              let filesList = JSON.parse(data);
              file.fileId = filesList.fileId;
              file.fieldId = fieldId;
              file.fileDataValidate = filesList.OCRfieldlist;
              this.attachmentList.push({
                id: Number(filesList.fileId),
                name: file.name,
                createdAt: new Date().toISOString(),
                createdByEmail: this.$store.state.session.email,
                size: file.size,
                initiate: true,
                formAttach: true,
                fileControl: true,
                fieldId: fieldId,
                control: control,
              });
              this.tabs.find((tab) => {
                if (tab.value === "ATTACHMENTS") {
                  tab.count = this.attachmentList.length;
                }
              });
            } catch (error) {
              console.error(error);
              this.$alert.error(`error attaching file`);
            }
          }
          let hasFile = this.workflowModel[fieldId].findIndex((item) => {
            return item.name === file.name;
          });

          // console.log(hasFile);

          if (hasFile >= 0) {
            this.workflowModel[fieldId][hasFile].uploadedPercentage = 99;
            this.workflowModel[fieldId][hasFile].uploadedPercentage = 100;
          }
        }

        let _files = Array.from(files);
        // console.log(_files);
        this.formUploadFiles = [];
        for (let file of _files) {
          // console.log(file.name);
          if (file.name) {
            const base64String = await fileToBase64(file);

            this.formUploadFiles.push({
              name: file.name,
              size: file.size,
              file: base64String,
              fileId: file.fileId,
              createdBy: file.createdBy,
              createdAt: file.createdAt,
              workflowId: this.selectedProcess.id,
              processId: this.selectedProcess.processId,
              fieldId: file.fieldId,
              fileDataValidate: file.fileDataValidate,
            });
          }
        }
        if (control) {
          if (control.length) {
            control.forEach((con) => {
              let index = this.readonlyControls.findIndex((item) => {
                return item === con;
              });

              if (index >= 0) {
                this.readonlyControls.splice(index, 1);
              }
            });
          }
        }

        this.$store.commit("hideLoadingBarSave");
      }
    },

    mandatoryControlsList(value) {
      this.mandatory = value;
    },

    deletedFiles(ids, files) {
      //this.fileControlDeleteFile = [];
      this.fileControlDeleteFile.push(ids);
      if (files) {
        if (files.controls) {
          if (files.controls.length) {
            files.controls.forEach((field) => {
              console.log(field);
              this.workflowModel[field] = "";
            });
          }
        }

        let hasFile = this.attachmentList.findIndex((file) => {
          return (
            file.name === files.file.name &&
            file.id === files.file.fileId &&
            file.fileControl
          );
        });
        this.ocrResult = [];
        if (hasFile >= 0) {
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length - 1;
            }
          });
          this.attachmentList.splice(hasFile, 1);
        }
      }
    },

    async fileControlDeletedFile(file) {
      this.$store.commit("showLoadingBar");
      let fileIds = [];
      file.forEach((item) => {
        if (item.id) fileIds.push(item.id);
      });
      let input = {
        ids: fileIds,
        formInfo: {
          formId: this.formId,
          formEntryId: this.selectedProcess.formData.formEntryId,
          jsonId: file[0].jsonId,
        },
      };
      const { error } = await repository.fileControlDeleteFile(
        this.workflow.repositoryId,
        input
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      // this.fileControlDeleteFile = [];
    },

    async checkFormValidation() {
      this.hasCheckFormValidate = true;
    },

    async refreshFormData() {
      const { error, payload } = await report.getWorkflowData(
        this.selectedProcess.id,
        {
          filterBy: [
            {
              filters: [
                {
                  criteria: "requestNo",
                  condition: "IS_EQUALS_TO",
                  value: this.selectedProcess.requestNo,
                },
              ],
            },
          ],
          itemsPerPage: 10,
          currentPage: 1,
          mode: "",
        }
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.data.length) {
        this.formModel = {};
        if (payload.data[0].value[0].formData) {
          let keys = Object.keys(payload.data[0].value[0].formData.fields);
          let newValues = {};
          keys.forEach((key) => {
            newValues[key] = this.validateTypeValue(
              payload.data[0].value[0].formData.fields[key]
            );
          });
          this.formModel = newValues;
        }
      }
    },

    async uploadFileForControl(files, control, fieldId, aiSettings) {
      this.fileUploadInput = {
        files: files,
        control: control,
        fieldId: fieldId,
        aiSettings: aiSettings,
      };
      if (this.repositoryDetails.fieldsType === "STATIC") {
        this.fileUploadForControl = false;

        if (this.customCheckUpload()) {
          let documentNoField = this.formFields.find(
            (field) => field.label === "Document No"
          );
          if (documentNoField) {
            this.uploadFilename =
              this.workflowModel[documentNoField.value] + ".pdf";
          } else {
            // this.uploadFilename = files.target.files[0].name;
          }
        } else {
          // this.uploadFilename = files.target.files[0].name;
        }
        let label = this.getLabel(fieldId);
        //this.uploadFilename = label;
        this.uploadFilename = files[0].name;

        //rename higher level name
        const highestLevelObject = this.repositoryField.reduce(
          (prev, curr) => (curr.level > prev.level ? curr : prev),
          this.repositoryField[0]
        );

        const highestLevelId = highestLevelObject.id;

        if (this.attachmentList.length) {
          let index = this.attachmentList.length - 1;
          await this.getFileData(this.attachmentList[index], "file");
        }

        const fileDataItem = this.fileData.find(
          (item) => highestLevelId == item.id
        );
        const newObjectToPush = {
          id: highestLevelObject.id,
          name: highestLevelObject.name,
          value: label,
          type: highestLevelObject.dataType,
        };
        if (!this.fileData.length) {
          this.fileData.push(newObjectToPush);
        } else {
          //fileDataItem.value = label;
          fileDataItem.value = files[0].name?.split(".")[0];
        }
        // console.log(this.attachmentList,"files, control, fieldId, aiSettings hasfiel")
        // console.log(label,"files, control, fieldId, aiSettings hasfiel")
        // console.log(this.fileData,"files, control, fieldId, aiSettings hasfiel")

        // check all mandatory filled
        const mandatoryItems = this.repositoryField
          .filter((item) => item.isMandatory === true)
          .map((item) => item.id);

        const allMandatoryFilled = mandatoryItems.every((mandatoryId) => {
          const fileItem = this.fileData.find(
            (item) => item.id === mandatoryId
          );
          return fileItem && fileItem.value !== "";
        });

        this.showMetaDataEditModal = !allMandatoryFilled;

        if (allMandatoryFilled) {
          const newObject = {};

          this.fileData.forEach((item) => {
            newObject[item.name] = item.value !== "" ? item.value : "";
          });

          this.fileUploadForControl = true;
          this.saveMetaData(newObject);
        } else {
          this.fileUploadForControl = true;
          this.showMetaDataEditModal = true;
        }

        // this.fileUploadForControl = true;
        // this.showMetaDataEditModal = true;
      } else {
        this.uploadFilesFromForm(files, control, fieldId, "", aiSettings);
      }
    },

    async getOCRResult(files, control, fieldId, aiSettings) {
      const formData = new FormData();
      let formFields = [];
      if (control && control.length) {
        control.forEach((con) => {
          this.readonlyControls.push(con);
        });
        control.forEach((field) => {
          let name = this.formFields.find((item) => item.value === field);
          if (name) {
            formFields.push(`${name.label}, ${name.type}`);
          }
        });
        if (files.length) {
          //this.$store.commit("showLoadingBarSave");
          for (let file of files) {
            if (file.name && !file.error) {
              formData.append("repositoryId", this.workflow.repositoryId);
              formData.append("file", file);
              formData.append("formFields", JSON.stringify(formFields));

              try {
                const response = await axios({
                  url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/uploadforStaticMetadata`,
                  method: "POST",
                  data: formData,
                  headers: {
                    Token: this.$store.state.identity.token,
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    let uploadedPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // console.log(this.workflowModel, fieldId);

                    let hasFile = this.workflowModel[fieldId].findIndex(
                      (item) => {
                        return item.name === file.name;
                      }
                    );
                    // console.log(hasFile);
                    if (hasFile >= 0) {
                      this.$set(
                        this.workflowModel[fieldId][hasFile],
                        "uploadedPercentage",
                        0
                      );
                      if (uploadedPercentage < 50) {
                        this.workflowModel[fieldId][
                          hasFile
                        ].uploadedPercentage = uploadedPercentage;
                      } else {
                        this.workflowModel[fieldId][
                          hasFile
                        ].uploadedPercentage = 49;
                      }
                    }
                  }.bind(this),
                });

                const { status, data } = response;
                if (status !== 200) {
                  throw "Invalid response";
                }

                // console.log(data);

                let list = decrypt(
                  data,
                  this.$store.state.identity.key,
                  this.$store.state.identity.iv
                );
                // console.log(list);

                let ocrList = JSON.parse(list);
                let ocr = JSON.parse(ocrList);
                let ocrFile = JSON.parse(ocr);

                if (Array.isArray(ocrFile)) {
                  ocrFile.forEach((hasFile) => {
                    let fieldId = this.formFields.find(
                      (item) => item.label === hasFile.name
                    );
                    if (fieldId) {
                      if (hasFile.value) {
                        this.workflowModel[fieldId.value] = hasFile.value;
                      }
                    }
                  });
                  this.ocrResult = ocrFile;
                }
              } catch (error) {
                console.error(error);
                this.$alert.error(`error on get ocr result`);
              }
            }
            let hasFile = this.workflowModel[fieldId].findIndex((item) => {
              return item.name === file.name;
            });
            // console.log(hasFile);
            if (hasFile >= 0) {
              //this.workflowModel[fieldId][hasFile].uploadedPercentage = 99;
              // this.workflowModel[fieldId][hasFile].uploadedPercentage = 100;
            }
          }

          this.$store.commit("hideLoadingBarSave");
          this.uploadFileForControl(files, control, fieldId, aiSettings);
        }
      } else {
        this.uploadFileForControl(files, control, fieldId, aiSettings);
      }
    },

    async checkTicketLock() {
      let input = {
        sortBy: { criteria: "", order: "DESC" },
        filterBy: [
          {
            filters: [
              {
                criteria: "requestNo",
                condition: "IS_EQUALS_TO",
                value: this.selectedProcess.requestNo,
                dataType: "SHORT_TEXT",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 10,
        currentPage: 1,
      };
      const { error, payload } = await workflow.getCommonList(
        this.selectedProcess.id,
        input
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);
      if (payload.meta.totalItems) {
        if (payload.data.length) {
          if (payload.data[0].value.length) {
            //this.selectedProcess = payload.data[0].value[0];
            this.$emit("update:selectedProcess", payload.data[0].value[0]);
            if (
              payload.data[0].value[0].ticketLockedBy ===
              this.$store.state.session.id
            ) {
              this.isRequestLocked = false;
            } else if (
              payload.data[0].value[0].ticketLockedBy &&
              payload.data[0].value[0].ticketLockedBy !== "0"
            ) {
              this.isRequestLocked = true;
            } else {
              await this.updateRequestReserved();
            }
          }
        }
      }
    },

    async checkTicketLock1() {
      const { error, payload } = await workflow.getProcess(
        this.selectedProcess.id,
        this.selectedProcess.processId,
        this.selectedProcess.transactionId
      );
      if (error) {
        this.$alert.error(error);
        return;
      }
      console.log(payload);
      if (payload) {
        if (payload.ticketLockedBy === this.$store.state.session.id) {
          this.isRequestLocked = false;
        } else if (payload.ticketLockedBy && payload.ticketLockedBy !== "0") {
          this.isRequestLocked = true;
        } else {
          await this.updateRequestReserved();
        }
      }
    },

    async updateRequestReserved() {
      let input = {
        workflowId: this.selectedProcess.id,
        processId: this.selectedProcess.processId,
        activityId: this.selectedProcess.activityId,
        isLock: true,
      };
      const { error } = await workflow.updateRequestReserved(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.selectedProcess.ticketLockedBy = this.$store.state.session.id;
    },
    async showHistoryStepper(processId, wId) {
      console.log("called");
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.processHistory(wId, processId);
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.length) {
        this.stageLevel = [];
        this.currentStep = 0;
        // console.log(payload);
        payload.forEach((row) => {
          if (row.actionStatus !== 2) {
            if (
              this.stageLevel.filter((stage) => stage.id === row.activityId)
                .length === 0
            ) {
              this.stageLevel.push({
                id: row.activityId,
                label: row.stage,
                status: row.status,
              });
              if (
                ((row.actionUser || row.subWorkflowHistory) &&
                  row.status &&
                  row.status !== "Forward") ||
                (row.actionUser === null && !row.subWorkflowHistory)
              ) {
                this.currentStep += 1;
              }
            } else if (row.status) {
              if (
                this.stageLevel.filter(
                  (stage) =>
                    stage.id === row.activityId && stage.status !== row.status
                ).length
              ) {
                this.currentStep += 1;
              } else {
                this.currentStep -= 1;
              }
            } else {
              let index = this.stageLevel.findIndex(
                (stage) => stage.id === row.activityId
              );
              if (index > -1) {
                this.stageLevel.splice(index, this.stageLevel.length - index);
                this.stageLevel.push({
                  id: row.activityId,
                  label: row.stage,
                  status: row.status,
                });
                if (
                  (row.actionUser && row.status && row.status !== "Forward") ||
                  row.actionUser === null
                ) {
                  this.currentStep = index + 1;
                } else {
                  this.currentStep = index;
                }
              }
            }
          }
        });
      }
    },

    refreshCustomForm() {
      this.closeProcessSheet();
      this.$emit("refresh", this.workflow.id);
    },

    attachmentCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = count;
        }
      });
    },

    commentsCount(count) {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = count;
        }
      });
    },

    async getOCRResultInTable(values) {
      console.log(values);
      let files = values.files.files;
      // let aiSettings = values.files.aiSettings;
      let fieldId = values.files.fieldId;
      let control = values.files.assignControls;
      let rowIndex = values.rowIndex;
      let tableId = values.tableId;
      // let fields = "";
      let formFields = [];
      if (control && control.length) {
        // control.forEach((con) => {
        //   this.readonlyControls.push(con);
        // });
        control.forEach((field) => {
          let name = this.formFields.find((item) => item.value === tableId);
          if (name) {
            let columns = name.settings.specific.tableColumns;
            let hasColumn = columns.find((item) => item.id === field);
            if (hasColumn) {
              formFields.push(`${hasColumn.label}, ${hasColumn.type}`);
            }
          }
        });
      }

      if (control && control.length) {
        if (files.length) {
          //this.$store.commit("showLoadingBarSave");
          for (let file of files) {
            const formData = new FormData();

            if (file.name && !file.error) {
              formData.append("repositoryId", this.workflow.repositoryId);
              formData.append("file", file);
              if (formFields.length) {
                formData.append("formFields", JSON.stringify(formFields));
              }

              let index = this.workflowModel[tableId][rowIndex][
                fieldId
              ].findIndex((item) => {
                return item.name === file.name;
              });
              console.log(index, "index");

              try {
                const response = await axios({
                  url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/uploadforStaticMetadata`,
                  method: "POST",
                  data: formData,
                  headers: {
                    Token: this.$store.state.identity.token,
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    let uploadedPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // console.log(this.workflowModel, fieldId);

                    if (uploadedPercentage !== 100) {
                      this.$set(
                        this.workflowModel[tableId][rowIndex][fieldId][index],
                        "uploadedPercentage",
                        uploadedPercentage
                      );
                    }
                  }.bind(this),
                });

                const { status, data } = response;
                if (status !== 200) {
                  throw "Invalid response";
                }

                let list = decrypt(
                  data,
                  this.$store.state.identity.key,
                  this.$store.state.identity.iv
                );

                console.log(list, "list");

                let ocrList = JSON.parse(list);
                let ocr = JSON.parse(ocrList);
                let ocrFile = JSON.parse(ocr);
                console.log(ocrFile, "ocrFile");

                if (Array.isArray(ocrFile)) {
                  ocrFile.forEach((hasFile) => {
                    let table = this.formFields.find(
                      (item) => item.value === tableId
                    );
                    if (table) {
                      let columns = table.settings.specific.tableColumns;
                      columns.forEach((col) => {
                        if (hasFile.name === col.label) {
                          if (hasFile.value) {
                            if (
                              col.id in this.workflowModel[tableId][rowIndex]
                            ) {
                              this.workflowModel[tableId][rowIndex][col.id] =
                                hasFile.value;
                            } else {
                              this.$set(
                                this.workflowModel[tableId][rowIndex],
                                col.id,
                                hasFile.value
                              );
                            }

                            console.log(
                              this.workflowModel[tableId][rowIndex],
                              "ocr1File"
                            );
                            // this.workflowModel[col.id] = hasFile.value;
                          }
                        }
                      });
                    }
                  });
                  // this.ocrResult = ocrFile;
                }
              } catch (error) {
                console.error(error);
                this.$alert.error(`error on get ocr result in table`);
              }
            }
          }

          this.$store.commit("hideLoadingBarSave");
          this.uploadFilesInTable(values);
        }
      } else {
        this.uploadFilesInTable(values);
      }
    },

    async uploadFilesInTable(values) {
      console.log(values);
      let files = values.files.files;
      let aiSettings = values.files.aiSettings;
      let fieldId = values.files.fieldId;
      let control = values.files.assignControls;
      let rowIndex = values.rowIndex;
      let tableId = values.tableId;
      let fields = "";
      // console.log(files, "files");

      // this.formUploadFiles = [];

      if (files.length) {
        //this.$store.commit("showLoadingBarSave");
        for (let file of files) {
          const formData = new FormData();

          if (file.name && !file.error) {
            formData.append("repositoryId", this.workflow.repositoryId);
            formData.append("file", file);
            formData.append("filename", file.name);
            if (fields) {
              formData.append("fields", fields);
            } else {
              formData.append("fields", "");
            }

            if (!isEmpty(aiSettings)) {
              if (aiSettings.formControlValidate) {
                if (aiSettings.formControlValidate.optionsType === "MASTER") {
                  formData.append(
                    "masterFormId",
                    aiSettings.formControlValidate.masterFormId
                  );

                  formData.append(
                    "masterFormColumn",
                    aiSettings.formControlValidate.masterFormColumn
                  );
                }

                if (aiSettings.fileDataValidate.length) {
                  formData.append(
                    "isValidateFile",
                    aiSettings.fileDataValidate.includes("EXPIRY_DATE")
                  );
                }
              }
            }

            let index = this.workflowModel[tableId][rowIndex][
              fieldId
            ].findIndex((item) => {
              return item.name === file.name;
            });
            console.log(index, "index");

            try {
              const response = await axios({
                url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
                method: "POST",
                data: formData,
                headers: {
                  Token: this.$store.state.identity.token,
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: function (progressEvent) {
                  let uploadedPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  // console.log(uploadedPercentage, fieldId);
                  if (uploadedPercentage !== 100) {
                    this.$set(
                      this.workflowModel[tableId][rowIndex][fieldId][index],
                      "uploadedPercentage",
                      uploadedPercentage
                    );
                  }
                }.bind(this),
              });

              const { status, data } = response;
              if (status !== 200) {
                throw "Invalid response";
              }
              console.log(data);
              this.$set(
                this.workflowModel[tableId][rowIndex][fieldId][index],
                "uploadedPercentage",
                100
              );

              let filesList = JSON.parse(data);
              file.fileId = filesList.fileId;
              file.fieldId = fieldId;
              file.fileDataValidate = filesList.OCRfieldlist;
              this.workflowModel[tableId][rowIndex][fieldId][index].fileId =
                Number(filesList.fileId);

              this.workflowModel[tableId][rowIndex][fieldId][index].createdAt =
                this.$day.newDateTime();
              this.workflowModel[tableId][rowIndex][fieldId][index].createdBy =
                this.$store.state.session.email;
              this.workflowModel[tableId][rowIndex][fieldId][
                index
              ].fileDataValidate = filesList.OCRfieldlist;

              if (filesList.OCRfieldlist) {
                let issues = JSON.parse(filesList.OCRfieldlist);
                let status = issues.prediction.replace(/[^a-zA-Z ]/g, "");
                let prediction = "";
                if (
                  status.toLowerCase() === "valid" ||
                  status.toLowerCase() === "not expired"
                ) {
                  prediction = `${issues.prediction}`;
                } else {
                  prediction = `${issues.prediction}`;
                }
                this.$set(
                  this.workflowModel[tableId][rowIndex][fieldId][index],
                  "prediction",
                  prediction
                );
              }

              this.workflowModel[tableId][rowIndex][fieldId][index].workflowId =
                this.selectedProcess.id;
              this.workflowModel[tableId][rowIndex][fieldId][index].processId =
                this.selectedProcess.processId;

              console.log(this.workflowModel[tableId][rowIndex][fieldId]);
              this.attachmentList.push({
                id: Number(filesList.fileId),
                name: file.name,
                createdAt: new Date().toISOString(),
                createdByEmail: this.$store.state.session.email,
                size: file.size,
                initiate: true,
                formAttach: true,
                fileControl: true,
                fieldId: fieldId,
                control: control,
                tableControl: true,
                tableId: tableId,
                rowIndex: rowIndex + 1,
              });
              this.tabs.find((tab) => {
                if (tab.value === "ATTACHMENTS") {
                  tab.count = this.attachmentList.length;
                }
              });
            } catch (error) {
              console.error(error);
              this.$alert.error(`error attaching file`);
            }
          }
          // let hasFile = this.workflowModel[fieldId].findIndex((item) => {
          //   return item.name === file.name;
          // });

          // // console.log(hasFile);

          // if (hasFile >= 0) {
          //   this.workflowModel[fieldId][hasFile].uploadedPercentage = 100;
          // }
        }

        // let _files = Array.from(files);
        // // console.log(_files);
        // this.formUploadFiles = [];
        // for (let file of _files) {
        //   // console.log(file.name);
        //   if (file.name) {
        //     const base64String = await fileToBase64(file);

        //     this.formUploadFiles.push({
        //       name: file.name,
        //       size: file.size,
        //       file: base64String,
        //       fileId: file.fileId,
        //       createdBy: file.createdBy,
        //       createdAt: file.createdAt,
        //       workflowId: this.selectedProcess.id,
        //       processId: this.selectedProcess.processId,
        //       fieldId: file.fieldId,
        //       fileDataValidate: file.fileDataValidate,
        //     });
        //   }
        // }

        // if (control) {
        //   if (control.length) {
        //     control.forEach((con) => {
        //       let index = this.readonlyControls.findIndex((item) => {
        //         return item === con;
        //       });

        //       // console.log(index);
        //       if (index >= 0) {
        //         this.readonlyControls.splice(index, 1);
        //       }
        //     });
        //   }
        // }

        this.$store.commit("hideLoadingBarSave");
      }
    },

    deletedFilesInTable(value) {
      console.log(value);
      value.fileIds.forEach((file) => {
        this.fileControlDeleteFile.push(file);
      });

      let initiateFiles = value.fileIds.filter((file) => file.initiate);

      initiateFiles.forEach((item) => {
        let hasFile = this.attachmentList.findIndex((file) => {
          return file.id === item.id && file.tableControl;
        });
        console.log(hasFile);
        if (hasFile >= 0) {
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length - 1;
            }
          });
          this.attachmentList.splice(hasFile, 1);
        }
      });
    },

    viewFile(file, item) {
      console.log(file, item);
      if (item) {
        file.createdBy = item.createdBy;
        file.createdAt = item.createdAt;
      }
      if (!file.name) {
        file.name = file.fileName;
      }
      if (!file.id) {
        if (file.itemId) {
          file.id = file.itemId;
        } else {
          this.$set(file, "id", file.fileId);
          console.log(file, item);
        }
      }
      if (file.initiate) {
        this.$set(file, "initiate", true);
      }
      this.openFile(file);
    },
  },
};
</script>

<style lang="scss" scoped>
#workflowRequestList {
  .card {
    border-radius: 6px;
    height: calc(100vh - 215px);
    background-color: var(--component-bg-color);
  }

  .cards {
    border-radius: 6px;
    height: calc(75vh - 215px);
    background-color: var(--component-bg-color);
  }

  .card1 {
    border-radius: 6px !important;
    height: calc(100vh - 255px) !important;
    width: 31% !important;
    z-index: 9999;
    background-color: var(--component-bg-color) !important;
    position: absolute;
  }

  #global-search {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 0px 12px;
    color: var(--icon-color);
    border: 1px solid $secondary;
    // background-color: var(--body-bg-color);

    .label {
      @extend .text-sm;
      flex: 1;
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
  }

  .plus {
    height: 20px !important;
    width: 20px !important;
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    background-color: #fff !important;
    margin: 0px !important;
    margin-right: 10px !important;
    // margin-bottom: 10px !important;
    font-size: 12px !important;
  }

  .label {
    color: var(--icon-color-inverted);
  }

  .border-bottom {
    height: 1px;
    border: 0;
    align-self: stretch;
    background-color: var(--divider-color);
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    height: 18px;
    margin-top: -20px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .pink-hover:hover {
    background: $pink-2 !important;
  }

  .gray-hover:hover {
    background: $gray-2 !important;
  }

  .group-by {
    //background-color: var(--deep-orange);
    padding: 4px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #e9a177;
    //color: white;

    .groupLabel {
      font-weight: 500;
    }
  }

  #action-btn {
    width: 28px !important;
    height: 28px !important;
  }

  #action-btn.small {
    width: 22px !important;
    height: 22px !important;
  }

  .custom-pagination {
    color: $secondary;
    background: var(--body-bg-color);
  }
}

.theme-light {
  #workflowRequestList {
    .list {
      background-color: #e1f5f9;
      border-radius: 6px;
      height: 100%;
      border: 1px solid #e1f5f9;
      cursor: pointer;
      font-size: 12px;

      &.active-list {
        background-color: $secondary-2 !important;
      }

      &:hover {
        background-color: $secondary-2 !important;
      }
    }
  }
}

.sub-workflow-details {
  background-color: #f0ddfc;
  padding: 10px;
  margin: 2px 0px 4px 10px;
  border-radius: 6px;
  height: 100%;
  border: 1px solid #f0ddfc;
  font-size: 12px;
  cursor: pointer;
}

.sub-workflow-details:hover {
  background-color: #e5b9ff;
}

.theme-dark {
  #workflowRequestList {
    .list {
      border-radius: 6px;
      height: 100%;
      border: 1px solid #00bcd4;
      cursor: pointer;
      font-size: 12px;
      background-color: var(--component-bg-color);
      &.active-list {
        background-color: $secondary-10 !important;
      }
      &:hover {
        background-color: $secondary-8 !important;
      }
    }
  }
}

#sheet {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  .border-height {
    height: calc(-104px + 100vh);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }

  #action-btn.linkActions {
    width: 38px !important;
    height: 38px !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }
}

#sheet .attachmentList,
#sheet .taskEntries,
#sheet .checkList,
#sheet .mailTransactionList,
#sheet .subProcess {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }

  .text-underline {
    text-decoration: underline;
  }
}

#sheet .mailTransactionList {
  #action-btn {
    width: 28px !important;
    height: 28px !important;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .fileFrame {
  min-height: calc(100vh - 120px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet {
  #approvalSignature {
    background-color: var(--component-bg-color);
    border-radius: 4px;

    .title {
      @extend .text-md;
      font-weight: 600;
      text-transform: capitalize;
    }

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
    .text {
      text-align: center;
    }

    .imgCenter {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      height: 100px;
    }
  }
}

#modal {
  #action-btn.copyActions {
    width: 24px !important;
    height: 24px !important;
  }
}

#sheet .jiraIssue {
  .field {
    margin: 16px 16px 16px 16px;
  }
}

#sheet .hint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  border: 2px dotted;
  border-radius: 10px;
  background-color: #e0ebfa26;
  padding: 5px;

  .icon {
    color: var(--body-text-color-inverted);
  }

  .title {
    @extend .title-2;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .description {
    color: var(--icon-color);
    text-align: center;
  }
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }

  #action-btn.comment-actions {
    height: 28px !important;
    width: 28px !important;
  }
}

#sheet .subProcess {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }
}

.req-no {
  font-weight: 500;
  text-transform: capitalize;
  display: flex;
  text-decoration: underline;
  color: var(--title-3-color);
  cursor: pointer;
}

.chip-warning {
  font-weight: 500;
  background-color: var(--component-bg-color-inferior);
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chip-success {
  font-weight: 500;
  background-color: #9bf3b0;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
<style>
/* #checkbox-field.check-group-by .option .icon {
  color: white !important;
} */
</style>
<style lang="scss">
#single-choice-field.actions .options-wrapper {
  padding-top: 8px !important;
}
#single-choice-field.actions.noborder .options-wrapper {
  border-top: 0px !important;
  padding-top: 0px !important;
}

.advance-cmd {
  margin-right: auto;
}

.file-icon {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
}

.text-area-action {
  border: 1px solid var(--border-color);
  #field-wrapper {
    border: none !important;
    .is-focused {
      border: none;
    }
  }
}

.attach-cmd {
  margin-left: 15px;
  #field-wrapper {
    height: 30px !important;
    .select {
      .value {
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .value:hover {
        max-width: none !important;
        overflow: visible;
        white-space: pre;
      }
    }
  }
}

.select-action {
  padding-top: 10px;

  align-items: center;
}

.action-container {
  border-top: 1px solid #cfd5dc;
}

.internal-public {
  display: flex;
  flex-direction: column-reverse;
  border-top: 1px solid #cfd5dc;
  padding-top: 10px;
}

.internal-public-margin {
  margin-top: 5px;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.loading {
  animation: spin 2s linear infinite;
}

// .q-message-name--received {
//   margin-left: 20px;
// }
// .q-message-name--sent {
//   margin-right: 23px;
// }

#sheet {
  #form-view {
    background-color: var(--body-bg-color);
    margin: auto;
    padding: 16px;
  }
}

#requestDetails {
  table {
    &.submittedData {
      background: $primary-1;
    }
    &.mainData {
      table-layout: fixed;
    }
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--divider-color);

    tr {
      height: 36px;

      th {
        color: $primary-11;
        text-align: left;
      }
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid var(--divider-color);
    }

    tr {
      td,
      th {
        border-bottom: 1px solid var(--divider-color);
      }
    }

    .tblHeader {
      color: $primary-11;
      font-weight: 500;
      &.small {
        width: 200px;
      }
    }
  }

  .ellipsis:hover {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .text-underline {
    text-decoration: underline;
  }
}
</style>
